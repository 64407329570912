import { SubmitHandler, useForm } from "react-hook-form"
import useLoginModel from "../useLogin"
import { BASEURL } from "../../../util"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Button, Input, Spinner } from "@material-tailwind/react"
import React, { useState } from "react"
import Logo from "../../../assets/logo.png"
import { set } from "lodash"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"

type LoginType = {
  otp: string
  new_password: string
}

type passwordRequest = {
  phone: string
}

export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<passwordRequest>()
  const {
    register: updateRegister,
    handleSubmit: handleUpdate,

    reset: resetUpdate,
    formState: { isSubmitting: isUpdating, errors: updatingErrors },
  } = useForm<LoginType>()

  // const {
  //   register:registerPhone,
  //   handleSubmit:submitPhone,
  //   formState: { isSubmitting:, errors },
  // } = useForm<LoginType>()

  const history = useHistory()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otpSent, setOtpSent] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const onSubmit: SubmitHandler<passwordRequest> = async (data) => {
    // console.log(data)
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      }

      const req = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/request-reset-password`,
        options
      )

      const res = await req.json()
      // console.log(res)
      // data: { user_id: 'd77ad04c-e248-4abe-bb67-6bf3f14bd59c' }
      if (req.ok) {
        if (res?.status === 200) {
          const { data } = res
          console.log(data)
          localStorage.setItem("user_id", data?.user_id)
          toast.success(res?.message ?? "Otp has been sent to phone")
          setOtpSent(true)
          reset()
        } else {
          toast.error(res?.data ?? "An error occured")
        }
      } else {
        toast.error(res?.data ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }
  const onSubmitUpdate: SubmitHandler<LoginType> = async (data) => {
    // console.log(data)
    // console.log(localStorage.getItem("user_id"))
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          ...data,
          user_id: localStorage.getItem("user_id"),
        }),
      }

      const req = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/update-password`,
        options
      )

      const res = await req.json()
      // console.log(res)

      if (req.ok) {
        if (res?.status === 200) {
          const { data } = res
          // console.log(data)

          toast.success(res?.message ?? "Password reset successfully")
          reset()
          localStorage.removeItem("user_id")
          setTimeout(() => {
            history.push("/")
            //()
          }, 1500)
        } else {
          toast.error(res?.data ?? "An error occured")
        }
      } else {
        toast.error(res?.data ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <main className="auth flex items-center justify-center h-screen bg-white">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="mb-10 flex items-center justify-center">
            <img src={Logo} alt="logo" className="w-36 object-cover" />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {!otpSent && (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="mt-2">
                <Input
                  crossOrigin={""}
                  id="phone"
                  error={!!errors.phone}
                  type="number"
                  maxLength={6}
                  label="Enter phone number linked to account"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <span className="text-left mt-2 text-xs text-brand-primary">
                    Phone number is required
                  </span>
                )}
              </div>

              <Button
                variant="gradient"
                size="lg"
                type="submit"
                style={{
                  background: "#ED7C31",
                }}
                className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
              >
                {isSubmitting ? (
                  <Spinner className="w-5 h-5 text-white" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          )}

          {otpSent && (
            <form onSubmit={handleUpdate(onSubmitUpdate)} className="space-y-6">
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Enter OTP code
                </label>
                <div className="mt-2">
                  <Input
                    crossOrigin={""}
                    id="otp"
                    error={!!updatingErrors.otp}
                    type="number"
                    maxLength={6}
                    label="OTP code"
                    size="lg"
                    className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                    {...updateRegister("otp", { required: true })}
                  />
                  {updatingErrors.otp && (
                    <span className="text-left mt-2 text-xs text-brand-primary">
                      OTP is required
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="new_password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  New Password
                </label>
                <div className="mt-2 relative">
                  <Input
                    crossOrigin={""}
                    size="lg"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    error={!!updatingErrors.new_password}
                    label="Enter a password"
                    className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                    {...updateRegister("new_password", { required: true })}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 py-2 text-sm font-medium text-gray-600 focus:outline-none"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                  {updatingErrors.new_password && (
                    <span className="text-left mt-2 text-xs text-brand-primary">
                      New password is required
                    </span>
                  )}
                </div>
              </div>

              <div>
                <Button
                  variant="gradient"
                  size="lg"
                  type="submit"
                  style={{
                    background: "#ED7C31",
                  }}
                  className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
                >
                  {isUpdating ? (
                    <Spinner className="w-5 h-5 text-white" />
                  ) : (
                    "Change password"
                  )}
                </Button>
              </div>
            </form>
          )}

          <p className="mt-5 text-center text-sm text-gray-500">
            Take me back to?{" "}
            <a
              href="/"
              className="font-semibold leading-6 text-brand-primary hover:text-brand-primary"
            >
              Login
            </a>
          </p>
        </div>
      </div>
    </main>
  )
}
