import { Button, DialogFooter, Typography } from "@material-tailwind/react"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { RootState } from "../../../store/store"
import {
  ADD_COMPANY_ADDITIONAL_RATE,
  ADD_COMPANY_DISCOUNT,
} from "../../../GraphQl/mutations"
import { useMutation } from "@apollo/client"

const UpdateRateAndAdditionalBenefits = ({
  onClose,
  onConfirm,
  Id,
  type,
  value,
}: {
  onClose?: () => void
  onConfirm: () => void
  Id?: string | null
  type?: string
  value: number | null
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<{ body: string }>()

  const { companyId, token } = useSelector((state: RootState) => state.auth)

  console.log(Id)
  console.log(type)

  const [insertNewData, { loading: RequestLoading }] = useMutation(
    type === "Rate" ? ADD_COMPANY_DISCOUNT : ADD_COMPANY_ADDITIONAL_RATE
  )

  // console.log(Id)
  // console.log(type)
  // console.log(Id)

  const onSubmit: SubmitHandler<{ body: string }> = async (data) => {
    const output = await trigger(["body"], {
      shouldFocus: true,
    })

    if (!output) return

    const check = Number(data.body)
    // console.log(isNaN(check))
    if (isNaN(check)) {
      return toast.warning(`${type} must be a number`)
    }
    console.log(Id, data.body)
    try {
      const res = await insertNewData(
        type === "Rate"
          ? {
              variables: {
                dis: Id,
                com: companyId,
                rate: check,
              },
            }
          : {
              variables: {
                id: Id,
                com: companyId,
                limit: check,
              },
            }
      )
      console.log(res)
      toast.success(`${type} updated successfully`)
      reset()
      onConfirm()
      onClose!()
    } catch (error) {
      toast.error(`Error updating ${type}`)
      reset()
    }
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=" overflow-auto h-full w-full"
    >
      <div className=" px-7 flex flex-col gap-y-10">
        <div className=" flex flex-col gap-y-2 ">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            {type}
          </Typography>
          <input
            defaultValue={value as number}
            id="body"
            {...register("body", { required: true })}
            className={` px-3 border border-black/20 py-4 resize-none rounded-md focus:border-black text-black focus:outline-none ${
              errors.body && "focus:border-red-400"
            }`}
          ></input>
        </div>
      </div>

      <DialogFooter className="px-10">
        <Button variant="text" color="red" className="mr-1" onClick={onClose}>
          <span>Cancel</span>
        </Button>

        <Button
          variant="gradient"
          type="submit"
          disabled={isSubmitting}
          color="green"
          className="flex items-center justify-center "
        >
          <span>{isSubmitting ? "updating..." : "Update"}</span>
        </Button>
      </DialogFooter>
    </form>
  )
}

export default UpdateRateAndAdditionalBenefits
