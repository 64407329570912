import { IconButton } from "@material-tailwind/react"
import React, { useState, useEffect } from "react"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"

const GeneralPagination = ({
  color = "white",
  totalItems,
  itemsPerPage,
  currentPage: propCurrentPage,
  onPageChange,
}: {
  color?: string
  totalItems: number
  itemsPerPage: number
  currentPage: number
  onPageChange: (page: number) => void
}) => {
  const [currentPage, setCurrentPage] = useState(propCurrentPage)

  useEffect(() => {
    setCurrentPage(propCurrentPage)
  }, [propCurrentPage])

  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePrev = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      setCurrentPage(newPage)
      onPageChange(newPage)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1
      setCurrentPage(newPage)
      onPageChange(newPage)
    }
  }

  const startItem = (currentPage - 1) * itemsPerPage + 1
  const endItem = Math.min(currentPage * itemsPerPage, totalItems)

  return (
    <div className={`my-10 flex items-center justify-center text-${color}`}>
      <IconButton
        size="sm"
        variant="filled"
        color="white"
        onClick={handlePrev}
        disabled={currentPage === 1}
      >
        <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      {/* <button onClick={handlePrev} disabled={currentPage === 1}>
        Previous
      </button> */}
      <span style={{ margin: "0 15px" }}>
        Page {currentPage} of {totalPages} ({startItem}-{endItem} of{" "}
        {totalItems})
      </span>
      <IconButton
        size="sm"
        variant="filled"
        color="white"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      {/* <button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </button> */}
    </div>
  )
}

export default GeneralPagination
