import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { BiArrowBack } from "react-icons/bi"
import {
  ChannelTypes,
  CreateNotificationType,
  TargetTypes,
} from "../../../types/notification"
import { channel } from "diagnostics_channel"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { BASEURL } from "../../../util"

const CreateNotification = () => {
  const history = useHistory()
  const [token, setToken] = useState()
  let storageToken = localStorage.getItem("token")
  const channels: ChannelTypes[] = [
    ChannelTypes.sms,
    ChannelTypes.email,
    ChannelTypes.inapp,
  ]
  const targets: TargetTypes[] = [
    TargetTypes.brands,
    TargetTypes.admins,
    TargetTypes.individual,
    TargetTypes.promoters,
  ]

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateNotificationType>()

  const watchTarget = watch("target")
  const watchChannel = watch("channel")

  const navigateBack = () => {
    history.push("/dashboard/notification")
    //()
  }

  const onSubmit: SubmitHandler<CreateNotificationType> = async (data) => {
    try {
      let jsonValues = JSON.stringify({
        message: data?.message,
        title: data?.title,
        channel: data?.channel,
        target: data?.target,
        status: data?.status,
        other_user: {
          phone: data?.other_user?.phone,
        },
      })
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/notifications/create-notification`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: jsonValues,
        }
      )
      const req = await res.json()

      if (res.ok) {
        toast.success(req.message)
        reset()
      } else {
        toast.info(req.message)
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    if (storageToken) {
    }
  }, [])

  return (
    <>
      <CustomHeader title="Create notification" />
      <IconButton onClick={navigateBack}>
        <BiArrowBack size={20} />
      </IconButton>

      <div className="my-5 bg-white rounded-lg shadow-sm p-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-6">
            {/*  */}
            <div className="col-span-1">
              <div className="my-3">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-3 font-bold text-sm capitalize"
                >
                  channel
                </Typography>
                <select
                  id="channels"
                  className="h-11 bg-gray-50 capitalize border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  {...register("channel", { required: true })}
                >
                  <option selected>Select channel</option>
                  {channels?.map((channel, index) => (
                    <option
                      label={channel}
                      value={channel}
                      key={`${channel}-${index}`}
                    >
                      {channel}
                    </option>
                  ))}
                </select>
                {errors?.channel && (
                  <span className="text-xs text-brand-primary">
                    Channel is required
                  </span>
                )}
              </div>
              <div className="my-3">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-3 font-bold text-sm capitalize"
                >
                  target
                </Typography>
                <select
                  id="channels"
                  className="h-11 bg-gray-50 capitalize border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  {...register("target", { required: true })}
                >
                  <option selected>Select target</option>
                  {targets?.map((target, index) => (
                    <option
                      label={target}
                      value={target}
                      key={`${target}-${index}`}
                    >
                      {target}
                    </option>
                  ))}
                </select>
                {errors?.target && (
                  <span className="text-xs text-brand-primary">
                    Target is required
                  </span>
                )}
              </div>
            </div>
            {/*  */}
            <div className="lg:col-span-2">
              {watchChannel === ChannelTypes.sms ? (
                <>
                  <div className="grid gap-5 grid-cols-1 lg:grid-cols-2 mb-2">
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-bold text-sm capitalize"
                      >
                        title
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.title}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("title", { required: true })}
                      />
                    </div>
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-bold text-sm capitalize"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.other_user?.phone}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("other_user.phone", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="mb-3 font-bold text-sm capitalize"
                    >
                      Message
                    </Typography>
                    <Textarea
                      error={!!errors.message}
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("message", { required: true })}
                    />
                  </div>
                </>
              ) : watchChannel === ChannelTypes.email ? (
                <>
                  {watchTarget === TargetTypes.individual && (
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-medium"
                      >
                        Recipient email address
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.recipient}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("recipient", { required: true })}
                      />
                    </div>
                  )}
                  <div
                    className={`grid gap-5 grid-cols-1 ${
                      watchTarget === TargetTypes.individual && "lg:grid-cols-2"
                    } mb-2`}
                  >
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-bold text-sm capitalize"
                      >
                        title
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.title}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("title", { required: true })}
                      />
                    </div>
                    {watchTarget === TargetTypes.individual && (
                      <div className="my-2">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="mb-3 font-bold text-sm capitalize"
                        >
                          Phone number
                        </Typography>
                        <Input
                          crossOrigin="anonymous"
                          error={!!errors.other_user?.phone}
                          size="lg"
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          {...register("other_user.phone", { required: true })}
                        />
                      </div>
                    )}
                  </div>
                  <div className="my-2">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="mb-3 font-bold text-sm capitalize"
                    >
                      Subject
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      error={!!errors.subject}
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("subject", { required: true })}
                    />
                  </div>
                  <div className="my-2">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="mb-3 font-bold text-sm capitalize"
                    >
                      Message
                    </Typography>
                    <Textarea
                      error={!!errors.message}
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("message", { required: true })}
                    />
                  </div>
                </>
              ) : watchChannel === ChannelTypes.inapp ? (
                <>
                  <div className="grid gap-5 grid-cols-1 lg:grid-cols-2 mb-2">
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-bold text-sm capitalize"
                      >
                        title
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.title}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("title", { required: true })}
                      />
                    </div>
                    <div className="my-2">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-3 font-bold text-sm capitalize"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.other_user?.phone}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("other_user.phone", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="mb-3 font-bold text-sm capitalize"
                    >
                      Message
                    </Typography>
                    <Textarea
                      error={!!errors.message}
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("message", { required: true })}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {channels?.includes(watchChannel) &&
            targets?.includes(watchTarget) && (
              <div className="mt-4 flex items-end justify-end">
                <Button
                  type="submit"
                  color="red"
                  className="flex items-center justify-center text-center"
                >
                  {isSubmitting ? <Spinner /> : "Submit"}
                </Button>
              </div>
            )}
        </form>
      </div>
    </>
  )
}

export default CreateNotification
