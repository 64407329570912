import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import {
  GET_BUSINESS_CLASS,
  GET_COVER_TYPES,
  GET_LIST_OF_DISCOUNT,
  GET_SUB_CLASS,
  GETBUSINESSCLASSES,
  GETCOVERTYPES,
  GETDISCOUNTRATESOFDISCOUNT,
  GETSUBCLASSES,
  PERIL_COVERAGE_RATE,
} from "../../../GraphQl/queries"
import { toast } from "react-toastify"
import {
  ADDDISCOUNTRATE,
  DELETEDISCOUNTRATE,
  UPDATE_PERIL_RATES,
} from "../../../GraphQl/mutations"
import { SubClassType } from "../business-class/manage"
import { CoverTypes } from "../../../types/query"
import { BusinessClassType } from "../../../types/class"
import { UserType } from "../../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { DriverModal } from "../../../components/modal/DriverModal"
import ViewCoverTypes from "../discounts/viewCoverTypes"
import { getColorCode } from "../../../colorCodes"
import GeneralPagination from "../../../components/paginations/generalpagination"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type FormType = {
  class: string
  minrate: string
  maxrate: string
  defaultrate: string
  intermediary: string
  description: string
}

type PerilCoverageType = {
  default_rate: string
  id:string
  max_rate: string
  min_rate: string
  status: string
  coverage: {
    peril: {
      id: string
      name: string
      __typename: "perils"
    }
    __typename: "coverage"
  }
  __typename: "coverage_rate"
}

type RateType = {
  id: string
  minRate: string
  maxRate: string
  defaultRate: string
}

const ManagePeril = () => {
  const getStoragePeril = localStorage.getItem("coverage")
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [selectedPeril, setSelectedPeril] = useState<{
    id: string
    perilName: string
    description: string
  }>()
  const [selectedItem, setSelectedItem] = useState<
    | {
      id: string
      name: string
      rate: number
      description?: string | undefined
    }[]
    | []
  >([])
  const [editingItem, setEditingItem] = useState<RateType | null>(null)
  const [isEditting, setIsEditting] = useState(false)

  const [open, setOpen] = useState(false)

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>()

  const handleEdit = (item: RateType) => {
    // console.log(item)
    setValue("maxrate", item.maxRate)
    setValue("minrate", item.minRate)
    setValue("defaultrate", item.defaultRate)
    setEditingItem(item)
    setIsEditting(true)
  }

  // const watchClass = watch("class")
  // const watchSubClass = watch("subclass")

  // const { data, loading, refetch } = useQuery<{
  //   view_discount_rates: DiscountRateType[]
  // }>(GETDISCOUNTRATESOFDISCOUNT, {
  //   variables: {
  //     discount_id: selectedDiscount?.id,
  //   },
  // })

  const { data, loading, refetch } = useQuery<{
    coverage_rate: PerilCoverageType[]
  }>(PERIL_COVERAGE_RATE, {
    variables: {
      id: selectedPeril?.id,
    },
  })

  // console.log(data)

  // console.log(classes)

  // console.log(subclasses)

  // console.log(coverTypeData)

  const [DeleteDiscountRate] = useMutation(DELETEDISCOUNTRATE, {
    onCompleted: (data) => {
      toast.success("Discount rate deleted successfully")
      refetch()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting discount rate"),
  })

  const [UpdateRates] = useMutation(UPDATE_PERIL_RATES, {
    onCompleted: (data) => {
      toast.success("Rates Updated Successfully")
      setEditingItem(null)
      reset()
      refetch()
    },
    onError: (error) => toast.error(error?.message ?? "Error updating Rates"),
  })

  const filterData = (filter: PerilCoverageType) => {
    const isTextMatch =
      filter?.coverage?.peril?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.default_rate
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.max_rate?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.min_rate?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = data?.coverage_rate?.filter(filterData)
  // const paginatedData = totalItems?.slice(startIndex, endIndex)

  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  // Filtered and paginated data for the current page

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      console.log(data)
      console.log(editingItem?.id)
      if (editingItem) {
        UpdateRates({
          variables: {
            def_rate: data?.defaultrate,
            min_rate: data?.minrate,
            max_rate: data?.maxrate,
            peril_id: editingItem?.id,
          },
        })
        return
      }
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/add-rate-to-coverage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            default_rate: data?.defaultrate,
            max_rate: data?.maxrate,
            min_rate: data?.minrate,
            status: "active",
            company_coverage_id: selectedPeril?.id,
          }),
        }
      )

      if (!res.ok) {
        const data1 = await res.json()
        toast.error("Error creating rates")
        return
      }
      const data1 = await res.json()
      if (!data1?.success) {
        toast.error(data1?.message)
        return
      }
      reset()
      toast.success("Rates added successfuly")
      refetch()
    } catch (error) {
      toast.error("Error creating rates")

    }
 
  }

  const deleteDiscountRate = (id: string) => {
    DeleteDiscountRate({
      variables: {
        id,
      },
    })
    try {
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting discount rate")
    }
  }

  useEffect(() => {
    if (getStoragePeril) {
      setSelectedPeril(JSON.parse(getStoragePeril))
    }
  }, [])


  const [colors, setColors] = useState<string[]>()


  //     setColors(data.colors)
  //   }
  // }, [])

  const userData = stateUser


  useEffect(() => {
    if (userData) {


      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Peril rate" />
      <DriverModal
        title={<p className=" text-gray-800 text-[1.2rem]">Rates</p>}
        open={open}
        size={"lg"}
        focus="message"
        onClose={() => setOpen(false)}
        children={
          <ViewCoverTypes cover={selectedItem} onClose={() => setOpen(false)} />
        }
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Peril Name", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedPeril?.perilName}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedPeril?.description}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              {editingItem ? "Update Peril Rate" : "Create Peril Rate"}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Minimum rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("minrate", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Maximum rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("maxrate", { required: true })}
                    />
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Default rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("defaultrate", { required: true })}
                    />
                  </div>


                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                {isSubmitting ? (
                  <Spinner className=" text-white p-2" />
                ) : (
                  <BiPlus size={20} />
                )}
                {editingItem ? "Update Peril Rate" : "Create Peril Rate"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">List of perils</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "Peril",
                      "default rate",
                      "min rate",
                      "max rate",
                      "status",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.coverage?.peril?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.coverage?.peril?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.default_rate}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.min_rate}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.max_rate}%
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.status}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                handleEdit({
                                  id: item?.id,
                                  minRate: item?.min_rate,
                                  maxRate: item?.max_rate,
                                  defaultRate: item?.default_rate,
                                })
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Edit
                            </Button>
                            {/* <IconButton
                              onClick={() => deleteCommission(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton> */}
                          </div>
                        </td>

                        {/* <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                setOpen(true)
                                setSelectedItem(item?.covertypes === null ? []: item?.covertypes )
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-blue-400 w-fit px-5"
                            >
                              View Rates
                            </Button>
                            <IconButton
                              onClick={() => {}}
                              color="green"
                              size="md"
                              variant="filled"
                            >
                              <BiEdit className=" text-[1.05rem]" />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteDiscountRate(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loading && (
              <div className="my-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && paginatedData?.length! !== 0 && !loading && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />
              <WhitePaginateComponent
                totalPages={totalItems?.length!}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default ManagePeril
