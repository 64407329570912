import { Button, Typography, Tooltip, Spinner } from "@material-tailwind/react"
import React, { useState } from "react"
import {
  AMOUNT_PAYABLE_EVERYDAY,
  REPLENISHMENT_RECEIPTS_PAY_CASH,
  REPLENISHMENT_RECEIPTS_PAY_CHEQUE,
  REPLENISHMENT_RECEIPTS_COLLECTION,
} from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import {} from "recharts"


interface Content {
  type?: "cash" | "cheque" | string
  id: string
  staff: string
  name: string
  payee_name: string
  payee_code: string
}

interface TableHeaderProps {
  content: Content | undefined
}

interface Policy {
  __typename: "policy"
  policy_number: string
  debit_number: string
}

interface CashPayment {
  __typename: "cash_payment"
  amount: number
  reference_number: string
  date_replenished: string
  policy: Policy
}
type Receipts = {
  policy_number: string
  debit_number: string
  amount: number
  payment_type: string
  date_replenished: string
}

const ReplenishmentRecueiptsViewMore = ({
  content,
}: {
  content:
    | {
        id: string
        staff: string
        type: string
        name: string
        payee_name: string
        payee_code: string
      }
    | undefined
}) => {
  const { loading: loadingCash, data: replenishment_reciepts_cash } = useQuery<{
    cash_payment: CashPayment[]
  }>(REPLENISHMENT_RECEIPTS_PAY_CASH, {
    variables: {
      ref_number: content?.id,
    },
  })

  console.log(replenishment_reciepts_cash)

  const totalAmount = replenishment_reciepts_cash?.cash_payment?.reduce(
    (accu, arr) => {
      return accu + arr.amount
    },
    0
  )

  const { loading: loadingCheque, data: replenishment_reciepts_cheque } =
    useQuery(REPLENISHMENT_RECEIPTS_PAY_CHEQUE, {
      variables: {
        ref_number: content?.id,
      },
    })
  console.log(replenishment_reciepts_cheque)

  const totalAmountCheque =
    replenishment_reciepts_cheque?.cheque_payment?.reduce(
      (accu: any, arr: any) => {
        return accu + arr.amount
      },
      0
    )

  const {
    loading: loadingCollection,
    data: replenishment_reciepts_collection,
  } = useQuery(REPLENISHMENT_RECEIPTS_COLLECTION, {
    variables: {
      ref_number: content?.id,
    },
  })
  console.log(replenishment_reciepts_collection)

  const totalAmountCollection =
    replenishment_reciepts_collection?.collection_request.reduce(
      (accu: any, arr: any) => {
        return accu + arr.amount
      },
      0
    )

  const handlePrint = () => {
    if (
      replenishment_reciepts_cash?.cash_payment?.length === 0 &&
      replenishment_reciepts_cheque?.cheque_payment === 0
    ) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
    <html>
      <head>
        <title>${content?.id}</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
          }
          section {
            margin-top: 10px;
          }
          h1 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
          }
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 10px;
          }
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <h1>Replenishment Receipts: ${content?.id}</h1>
        <h2>Name: ${content?.payee_name}</h2>
        <h2>ID: ${content?.payee_code}</h2>
        <table>
          <thead>
            <tr>
              <th>Policy Number</th>
              <th>Debit Number</th>
              <th>Amount(GHS)</th>
              <th>Payment Type</th>
              <th>Date Replenished</th>
              <th>Replenished By</th>
            </tr>
          </thead>
          <tbody>
            ${replenishment_reciepts_cash?.cash_payment
              ?.map(
                (item) => `
              <tr>
                <td>${item?.policy?.policy_number}</td>
                <td>${item?.policy.debit_number}</td>
                <td>${((item?.["amount"] as any) ?? 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${content?.type}</td>
                <td>${moment(item.date_replenished).format("LLL")}</td>
                <td>${content?.name}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Reference Number</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${content?.id}</td>
              <td>GHS${(totalAmount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  `

    // Write the content to the new window and print it
    printWindow!.document.write(printContent)
    printWindow!.document.close()
    printWindow!.print()
  }
  const handlePrintCheque = () => {
    if (replenishment_reciepts_cheque?.cheque_payment?.length === 0) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
    <html>
      <head>
        <title>${content?.id}</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
          }
          section {
            margin-top: 10px;
          }
          h1 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
          }
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 10px;
          }
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <h1>Replenishment Receipts: ${content?.id}</h1>
        <h2>Name: ${content?.payee_name}</h2>
        <h2>ID: ${content?.payee_code}</h2>
        <table>
          <thead>
            <tr>
              <th>Policy Number</th>
              <th>Debit Number</th>
              <th>Amount(GHS)</th>
              <th>Payment Type</th>
              <th>Date Replenished</th>
              <th>Replenished By</th>
            </tr>
          </thead>
          <tbody>
            ${replenishment_reciepts_cheque?.cheque_payment
              ?.map(
                (item: any) => `
              <tr>
                <td>${item?.policy?.policy_number}</td>
                <td>${item?.policy.debit_number}</td>
                <td>${((item?.["amount"] as any) ?? 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${content?.type}</td>
                <td>${moment(item.date_replenished).format("LLL")}</td>
                <td>${content?.name}</td>
              </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Reference Number</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${content?.id}</td>
              <td>GHS${(totalAmountCheque ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  `

    // Write the content to the new window and print it
    printWindow!.document.write(printContent)
    printWindow!.document.close()
    printWindow!.print()
  }

  const handlePrintCollection = () => {
    if (replenishment_reciepts_collection.length === 0) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
    <html>
      <head>
        <title>${content?.id}</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
          }
          section {
            margin-top: 10px;
          }
          h1 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
          }
          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 10px;
          }
          th {
            background-color: #f2f2f2;
            font-weight: bold;
          }
        </style>
      </head>
      <body>
        <h1>Replenishment Receipts: ${content?.id}</h1>
        <h2>Name: ${content?.payee_name}</h2>
        <h2>ID: ${content?.payee_code}</h2>
        <table>
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Amount(GHS)</th>
              <th>Payment Type</th>
              <th>Date Replenished</th>
              <th>Replenished By</th>
            </tr>
          </thead>
          <tbody>
          ${replenishment_reciepts_collection?.collection_request
            ?.map(
              (item: any) => `
              <tr>
                <td>${item?.driver?.full_name}</td>
                <td>${((item?.["amount"] as any) ?? 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${content?.type}</td>
                <td>${moment(item.date_replenished).format("LLL")}</td>
                <td>${content?.name}</td>
              </tr>
            `
            )
            .join("")}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Reference Number</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${content?.id}</td>
              <td>GHS${(totalAmountCollection ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</td>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  `

    // Write the content to the new window and print it
    printWindow!.document.write(printContent);
    printWindow!.document.close();
    printWindow!.print();
  };


 



  return (
    <div>
      <div className=" flex gap-x-2 mb-5 justify-between items-center">
        {/* <div>
          <h1>Name:</h1>
          <h1 className="text-[1.4rem] text-black">{name}</h1>
        </div> */}

        <div className="flex shrink-0 flex-col gap-x-5 sm:flex-row font-bold text-[1.5rem]">
          <h1>{content?.payee_name}</h1>
          <h1>{content?.payee_code}</h1>
          <h1></h1>
        </div>

        <Button
          onClick={() => {
            if (
              replenishment_reciepts_cash?.cash_payment?.length === 0 &&
              replenishment_reciepts_cheque?.cheque_payment?.length === 0
            ) {
              handlePrintCollection()
            } else if( replenishment_reciepts_cheque?.cheque_payment?.length === 0 &&
              replenishment_reciepts_collection?.collection_request?.length ===
                0 ) {
              handlePrint()
            }else if(replenishment_reciepts_cash?.cash_payment?.length === 0 &&
              replenishment_reciepts_collection?.collection_request?.length ===
                0){
              handlePrintCheque()
          }else{
            handlePrint()
          }
        }}
          variant="filled"
          color="green"
          className="font-normal text-[1.2rem] px-9 py-1 capitalize"
        >
          print
        </Button>
      </div>
      {(loadingCash || loadingCheque || loadingCollection) && (
        <div className="mt-10 flex items-center justify-center">
          <Spinner className="w-10 h-10" />
        </div>
      )}
      <table className="w-full bg-white table-auto border">
        <TableHeader content={content} />
        <tbody>
          {replenishment_reciepts_cash?.cash_payment &&
            replenishment_reciepts_cash.cash_payment.map((item, index) => (
              <tr
                key={index}
                className="hover:bg-gray-0 transition-all hover:cursor-pointer"
              >
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.policy?.policy_number}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.policy?.debit_number}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    GHS{" "}
                    {Number.isNaN(item?.amount)
                      ? "0.00"
                      : (item?.amount).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {content?.type}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {moment(item?.date_replenished).format("LLL")}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {content?.name}
                  </Typography>
                </td>
              </tr>
            ))}

          {replenishment_reciepts_cheque?.cheque_payment &&
            replenishment_reciepts_cheque?.cheque_payment?.map(
              (item: any, index: any) => (
                <tr
                  key={index}
                  className="hover:bg-gray-0 transition-all hover:cursor-pointer"
                >
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {item?.policy?.policy_number}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {item?.policy?.debit_number}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      GHS{" "}
                      {Number.isNaN(item?.amount)
                        ? "0.00"
                        : (item?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {content?.type}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {moment(item?.date_replenished).format("LLL")}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {content?.name}
                    </Typography>
                  </td>
                </tr>
              )
            )}

          {replenishment_reciepts_collection?.collection_request &&
            replenishment_reciepts_collection?.collection_request?.map(
              (item: any, index: any) => (
                <tr
                  key={index}
                  className="hover:bg-gray-0 transition-all hover:cursor-pointer"
                >
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {item?.driver?.full_name}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      GHS{" "}
                      {Number.isNaN(item?.amount)
                        ? "0.00"
                        : (item?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {item?.payment_mode}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {moment(item?.date_replenished).format("LLL")}
                    </Typography>
                  </td>
                  <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {item.replenished_by_name}
                    </Typography>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      <div className=" flex itens-center  justify-between gap-x-3 mt-6">
        <div>
          Reference Number:{" "}
          <span className=" font-bold text-[1.3rem]">{content?.id}</span>
        </div>
        {replenishment_reciepts_cash?.cash_payment?.length === 0 &&
        replenishment_reciepts_cheque?.cheque_payment?.length === 0 ? (
          <div>
            Total Amount: GHS{" "}
            <span className=" font-bold text-[1.3rem]">
              {(totalAmountCollection ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : replenishment_reciepts_cash?.cash_payment?.length === 0 &&
          replenishment_reciepts_collection?.collection_request?.length ===
            0 ? (
          <div>
            Total Amount: GHS{" "}
            <span className=" font-bold text-[1.3rem]">
              {(totalAmountCheque ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : replenishment_reciepts_cheque?.cheque_payment?.length === 0 &&
          replenishment_reciepts_collection?.collection_request?.length ===
            0 ? (
          <div>
            Total Amount: GHS{" "}
            <span className=" font-bold text-[1.3rem]">
              {(totalAmount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ) : (
          <div>
            Total Amount: GHS{" "}
            <span className=" font-bold text-[1.3rem]">
              {(totalAmount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
const TableHeader: React.FC<TableHeaderProps> = ({ content }) => {
  const getHeaders = () => {
    if (content?.type === "cash" || content?.type === "cheque") {
      return [
        "policy number",
        "debit number",
        "Amount",
        "Payment type",
        "date replenished",
        "Replenished by",
      ]
    } else {
      return [
        "driver name",
        "amount",
        "payment type",
        "date replenished",
        "replenished by",
      ]
    }
  }

  return (
    <thead className="text-center">
      <tr>
        {getHeaders().map((head) => (
          <th
            key={head}
            className="border-y border-r border-blue-gray-100 text-black bg-gray-100/50 p-4 text-center"
          >
            <Typography
              color="black"
              className="font-normal text-sm text-black leading-none opacity-70 capitalize"
            >
              {head}
            </Typography>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default ReplenishmentRecueiptsViewMore
