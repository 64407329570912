import { Button, DialogFooter, Typography } from "@material-tailwind/react"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { RootState } from "../../store/store"

const DisApproveRequest = ({
  onClose,
  onConfirm,
  driver,
}: {
  onClose?: () => void
  onConfirm:()=>void
  driver?: string
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<{ body: string }>()

  const {
  
    token,
  } = useSelector((state: RootState) => state.auth)

  const onSubmit: SubmitHandler<{ body: string }> = async (data) => {
    const output = await trigger(["body"], {
      shouldFocus: true,
    })

    if (!output) return
    console.log( driver, data.body,)
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/reject-withdrawal-request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        withdrawal_id: driver,
        reason: data.body,
      }),
    })
    console.log(res)

    if (!res.ok) {
      toast.error("Error disapproving withdrawal request")
      reset()
      onClose!()
      return
    }
    toast.success("Withdrawal request disapproved successfully")
    reset()
    onConfirm()
    onClose!()
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=" overflow-auto h-full w-full"
    >
      <div className=" px-7 flex flex-col gap-y-10">
        <div className=" flex flex-col gap-y-2 ">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Reason
          </Typography>
          <textarea
            rows={5}
            id="body"
            {...register("body", { required: true })}
            className={` px-3 border border-black/20 py-1 resize-none rounded-md focus:border-black text-black/70 focus:outline-none ${
              errors.body && "focus:border-red-400"
            }`}
          ></textarea>
        </div>
      </div>

      <DialogFooter className="px-10">
        <Button variant="text" color="red" className="mr-1" onClick={onClose}>
          <span>Cancel</span>
        </Button>

        <Button
          variant="gradient"
          type="submit"
          disabled={isSubmitting}
          color="green"
          className="flex items-center justify-center "
        >
          <span>{isSubmitting ? "sending..." : "Send"}</span>
        </Button>
      </DialogFooter>
    </form>
  )
}

export default DisApproveRequest
