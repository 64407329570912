import { Button, Input, Spinner, Typography } from "@material-tailwind/react"
import { useForm, SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { BASEURL } from "../../../util"
import Logo from "../../../assets/logo.png"
import { GET_ROLE_PERMISSIONS } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { useState } from "react"
import { useDispatch, UseDispatch } from "react-redux"
import { setAuthState } from "../../../store/authSlice"

type LoginType = {
  email: string
  password: string
  usertype: string
}

export default function LoginEmail() {
  const history = useHistory()
  const dispatch = useDispatch()
  const options = [
    { value: "company_administrator", label: "company administrator" },
    { value: "staff", label: "staff" },
  ]

  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>()

  const usertype = watch("usertype")

  // const onSubmit: SubmitHandler<LoginType> = async (data) => {
  //   if (data?.usertype === "Select account type") {
  //     return toast.error("Select a usertype")
  //   }
  //   const usertype = data?.usertype
  //   try {
  //     let options = {
  //       method: "POST",
  //       headers: { "content-type": "application/json" },
  //       body: JSON.stringify(data),
  //     }

  //     // const req = await fetch(
  //     //   `${process.env.REACT_APP_BASE_URL}/company-login`,
  //     //   options
  //     // )
  //     const req = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/company-login-send-otp`,
  //       options
  //     )

  //     const res = await req.json()

  //     if (req.ok) {

  //       history.push("/continue-login")
  //       toast.success(res?.message)
  //       localStorage.setItem("usertype", usertype)
  //       localStorage.setItem("usser_id", res?.data)
  //     } else {
  //       toast.error("An error occured")
  //     }
  //   } catch (error: any) {
  //     toast.error(error?.message)
  //   }
  // }

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    if (data?.usertype === "Select account type") {
      return toast.error("Select a usertype")
    }
    const usertype = data?.usertype
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      }

      const req = await fetch(
        `${process.env.REACT_APP_BASE_URL}/company-login-send-otp`,
        options
      )

      const res = await req.json()

      if (req.ok) {
        if (res?.error === 401 || res?.error === 404) {
          toast.error(res?.data)
        } else {
          history.push("/continue-login")
          toast.success(res?.message)

          dispatch(
            setAuthState({
              usertype: usertype,
              userId: res?.data,
              roleId: null,
              companyId: null,
              token: null,
              expiresAt: null,
              showI2I: false,
              user: null,
            })
          )
        }
      } else {
        toast.error("An error occurred")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <main className="w-full h-screen bg-black grid grid-cols-1 lg:grid-cols-2">
      <div className="h-full bg-white flex flex-col px-5 md:px-0 lg:px-0  lg:items-center md:lg:items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="mb-10 flex items-center justify-center">
            <img src={Logo} alt="logo" className="w-36 object-cover" />
          </div>
          <p className="mb-4 text-grey-700 font-bold">Login to your account</p>

          <select
            {...register("usertype", { required: true })}
            className="bg-gray-100 capitalize flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
            placeholder="Select account type"
          >
            <option className="">Select account type</option>
            {options?.map((item) => (
              <option label={item.label} value={item?.value} key={item?.value}>
                {item.label}
              </option>
            ))}
          </select>
          {errors.usertype && (
            <span className="text-left mt-2 text-xs text-brand-primary">
              Account type required
            </span>
          )}

          <div className="my-5" />
          {usertype === "company_administrator" ? (
            <>
              <Input
                crossOrigin={""}
                id="email"
                error={!!errors.email}
                type="email"
                label="Email"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-left mt-2 text-xs text-brand-primary">
                  Email is required
                </span>
              )}
            </>
          ) : (
            <>
              <Input
                crossOrigin={""}
                id="Username"
                error={!!errors.email}
                type="Username"
                label="Username"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-left mt-2 text-xs text-brand-primary">
                  Username is required
                </span>
              )}
            </>
          )}

          <div className="my-6" />
          <div className="relative">
            <Input
              crossOrigin={""}
              size="lg"
              id="password"
              type={showPassword ? "text" : "password"}
              error={!!errors.password}
              label="Enter a password"
              className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
              {...register("password", { required: true })}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 py-2 text-sm font-medium text-gray-600 focus:outline-none"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
            {errors.password && (
              <span className="text-left mt-2 text-xs text-brand-primary">
                Password is required
              </span>
            )}
          </div>

          <Button
            variant="gradient"
            size="lg"
            type="submit"
            style={{
              background: "#ED7C31",
            }}
            className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
          >
            {isSubmitting ? (
              <Spinner className="w-5 h-5 text-white" />
            ) : (
              "Sign In"
            )}
          </Button>
          <p className="text-sm leading-relaxed text-grey-900 my-2 text-center">
            Forgot password?{" "}
            {/* <a href="request-otp" className="font-bold text-red-500">
              Reset here
            </a> */}
            <a href="change-password" className="font-bold text-red-500">
              Reset here
            </a>
          </p>
        </form>
      </div>
      <div className="hidden lg:flex h-full flex-col items-center justify-center text-center">
        <img
          alt="dashboardImage"
          className="w-96"
          src="https://www.cyfe.com/wp-content/uploads/2019/10/Sales-Dashboards.png.webp"
        />
        <Typography className="mt-10 w-52 text-md font-semibold capitalize text-white">
          Your number one insurance company portal
        </Typography>
      </div>
    </main>
  )
}
