import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { useHistory } from "react-router-dom"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { FaUsers } from "react-icons/fa6"
import { SubmitHandler, useForm } from "react-hook-form"
import { createIntermediaryType } from "../../../types/intermediart"
import { toast } from "react-toastify"
import {
  BASEURL,
  DEFAULTPRIMARYCOLOR,
  cardNumberPattern,
  id_types,
  intermediary_types,
} from "../../../util"
import { useQuery } from "@apollo/client"
import { GETALLBANKS, GET_CLIENT_TYPES } from "../../../GraphQl/queries"
import { UserType } from "../../../types/user"

const Intermediary = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()

  const {
    data: client_types,
    loading: loadingClient,
    refetch,
  } = useQuery<{
    client_types: {
      id: string
      name: string
      description: string
      created_at: string
      created_by: string
    }[]
  }>(GET_CLIENT_TYPES)

  const {
    data: banks,
    loading: loadingBanks,
    refetch: refechBanks,
  } = useQuery<{
    bank: {
      code: string
      id: string
      name: string
    }[]
  }>(GETALLBANKS)

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<createIntermediaryType>()

  // const navigate = () => {
  //   history.push(`/dashboard/setup/create-intermediary`);
  //   //();
  // };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const onSubmit: SubmitHandler<createIntermediaryType> = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/setup/add-intemediary`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        }
      )
      const req = await response.json()
      // console.log("req", req);
      if (response.ok && !req?.error) {
        toast.success("Intermediary created successfully")
        reset()
      } else {
        toast.error(req?.data ?? "Something went wrong?")
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating intermediary")
    }
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)

      setColors(data.colors)
    }
  }, [])

  return (
    <>
      <CustomHeader title="Intermediaries" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className="capitalize rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-base text-white">
              New Intermediary
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      name
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("name", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      client type
                    </Typography>
                    <select
                      {...register("client_type", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {client_types?.client_types?.map((client_type) => (
                        <option
                          label={client_type?.name}
                          value={client_type?.id}
                        >
                          {client_type?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      email
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("email", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      type="email"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      address
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("address", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      Phone
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("phone", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      intermediary type code
                    </Typography>
                    <select
                      {...register("intermediary_type_code", {
                        required: true,
                      })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {intermediary_types?.map((intermediary_type) => (
                        <option
                          label={intermediary_type?.name}
                          value={intermediary_type?.code}
                        >
                          {intermediary_type?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      ⁠TIN number
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("tin_number", { required: true })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      NIC registration
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("nic_registration_number", {
                        required: true,
                      })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 capitalize font-semibold text-[14px] w-1/3"
                    >
                      Branch code
                    </Typography>
                    <Input
                      value={undefined}
                      {...register("branch_code", {
                        required: true,
                      })}
                      crossOrigin="anonymous"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Typography className="text-md text-gray-600">
              Contact Person
            </Typography>
            <hr className="my-1" />
            <div className="mt-3">
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Name
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  {...register("contact_person.name", { required: true })}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  phone
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  {...register("contact_person.phone", { required: true })}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Address
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  {...register("contact_person.address", { required: true })}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Email
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  {...register("contact_person.email", { required: true })}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  National id type
                </Typography>
                <select
                  {...register("contact_person.national_id_type", {
                    required: true,
                  })}
                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {id_types?.map((item) => (
                    <option value={item?.name} label={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  National ID/Ghana card
                </Typography>
                <Input
                  value={undefined}
                  crossOrigin="anonymous"
                  size="md"
                  {...register("contact_person.card_number", {
                    required: "Card number is required",
                    // pattern: {
                    //   value: cardNumberPattern,
                    //   message: "Invalid card number format",
                    // },
                  })}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
                {errors.contact_person?.card_number && (
                  <p className="text-xs text-red-500">
                    {errors.contact_person.card_number.message}
                  </p>
                )}
              </div>
            </div>
            <Typography className="text-md text-gray-600">
              Debit details
            </Typography>
            <hr className="my-1" />
            <div className="mt-3">
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Momo network
                </Typography>
                <div className="w-full flex flex-col gap-2 my-1">
                  <select
                    {...register("momo_details.network", { required: true })}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option label="MTN" value={"MTN"}>
                      MTN
                    </option>
                    <option label="AirtelTigo" value={"AirtelTigo"}>
                      AirtelTigo
                    </option>
                    <option label="Vodafone" value={"Vodafone"}>
                      Vodafone
                    </option>
                  </select>
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Mobile money mumber"
                    size="md"
                    {...register("momo_details.number", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Bank transfer
                </Typography>
                <div className="w-full flex flex-col my-1 gap-2">
                  <select
                    {...register("bank_details.bank_name", { required: true })}
                    placeholder="Bank name"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected defaultChecked>
                      {loadingBanks ? "Loading..." : "Select Bank"}
                    </option>
                    {banks?.bank?.map((item) => (
                      <option
                        key={item?.code}
                        value={item?.name}
                        label={`${item?.name}`}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Account number"
                    size="md"
                    {...register("bank_details.acct_number", {
                      required: true,
                    })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  <Input
                    value={undefined}
                    crossOrigin="anonymous"
                    placeholder="Branch"
                    size="md"
                    {...register("bank_details.branch", { required: true })}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                size="sm"
                type="submit"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="flex flex-row items-center font-normal"
              >
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <span className="flex flex-row items-center">
                    <BiPlus size={20} />
                    Save
                  </span>
                )}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">
              List of Intermediaries
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    // value={searchText}
                    // onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                    value={undefined}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "Intermediary type",
                      "Address",
                      "Phone",
                      "Email",
                      "⁠TIN number",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[{}, {}, {}, {}, {}, {}, {}]?.map(
                    (item: any, index: number) => {
                      const isLast = index === [{}]?.length - 1
                      const classes = isLast
                        ? "px-4 py-2  text-left"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                      return (
                        <tr
                          key={item?.name + index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              Broker
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              Accra
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              +233 444 444 4444
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              doe@gmail.com
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs "
                            >
                              GT24
                            </Typography>
                          </td>

                          <td className={classes}>
                            <IconButton color="blue" size="sm">
                              <BiEdit />
                            </IconButton>
                            <span className="mx-2"></span>
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </CardBody>

            {true && (
              <div className="my-4 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={prevPage}
                    // disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{1}</strong> of{" "}
                    <strong className="text-gray-900">
                      {/* {Math.ceil(
                        (data?.admins?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )} */}
                      1
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    // onClick={nextPage}
                    // disabled={
                    //   activePage ===
                    //   Math.ceil(
                    //     (data?.admins?.filter(filterData).length || 0) /
                    //       PAGE_SIZE
                    //   )
                    // }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default Intermediary
