import React, { useState, useEffect } from 'react';
import { CustomHeader } from '../../../components/headers/CustomHeader';

interface Branch {
  id: string;
  name: string;
}

interface Workgroup {
  id: string;
  name: string;
}

const UserAccountOverview = ({ user }: { user: { name: string, branches: Branch[] } }) => {
  const [selectedBranch, setSelectedBranch] = useState<string | null>(null);
  const [workgroups, setWorkgroups] = useState<Workgroup[]>([]);

  // Simulated data fetch for workgroups/divisions based on selected branch
  useEffect(() => {
    if (selectedBranch) {
      // Fetch the workgroups/divisions for the selected branch
      fetchWorkgroups(selectedBranch);
    }
  }, [selectedBranch]);

  const fetchWorkgroups = async (branchId: string) => {
    // Replace with real API call
    const response = await fetch(`/api/workgroups?branch=${branchId}`);
    const data = await response.json();
    setWorkgroups(data);
  };

  return (
    <div className="flex justify-between items-center">
      {/* Display the logged-in user's name */}
      <CustomHeader title={`Overview - Welcome, ${user.name}`} />

      {/* <div className="flex items-center space-x-4">
        <select
          value={selectedBranch || ''}
          onChange={(e) => setSelectedBranch(e.target.value)}
          className="border rounded px-4 py-2 text-xs"
        >
          <option value="" disabled>Select Branch</option>
          {user.branches.map((branch) => (
            <option  key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
        </select>
        <select className="border rounded px-4 py-2 text-xs" disabled={!selectedBranch}>
          <option value="" disabled>Select Workgroup/Division</option>
          {workgroups.map((workgroup) => (
            <option key={workgroup.id} value={workgroup.id}>
              {workgroup.name}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

export default UserAccountOverview;
