import { useState, useEffect } from "react"

export function useDebounceSearch<T>(value: T, delay = 2000): T {
  const [debounceSearchText, setDebounceSearchText] = useState(value)
  useEffect(() => {
    const tt = setTimeout(() => {
      setDebounceSearchText(value)
    }, delay)
    return () => clearTimeout(tt)
  }, [value, delay])

  return debounceSearchText
}
