import React from "react";
import { AdminType } from "../../types/admin";
import { Typography } from "@material-tailwind/react";

type ResponseType = {
  status: string;
  amount: number;
  created_at: string;
  created_by: string;
  reference_number: string;
  id: string;
  otp_code: string;
  driver: {
    account_status: string;
    address: string;
    balance: number;
    bank_details: {
      branch: string;
      bank_name: string;
      account_number: string;
    };
    created_at: string;
    consent: boolean;
    dob: string;
    email: string;
    full_name: string;
    gender: string;
    id: string;
    id_card_type: string;
    id_number: string;
    momo_wallet_details: {
      number: string;
      network: string;
    };
    phone_number_1: string;
    phone_number_2: string;
  };
  staff_details: {
    id: string;
    phone_number_one: string;
    name: string;
  };
};

const WithdrawalDetail = ({
  content,
}: {
  content: ResponseType | undefined;
}) => {
  return (
    <div className="overflow-scroll h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>
      </div>
      <Typography variant="h5" className="text-lg mt-10 mb-3" color="blue-gray">
        Driver details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Name
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.full_name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Amount
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.amount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Balance
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.driver?.balance ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              gender
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.gender}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.phone_number_1}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver?.phone_number_2}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.phone_number_2}
            </Typography>
          </div>
        </div>
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Email
            </Typography>
            <Typography
              as={"a"}
              href={`mailto:${content?.driver?.email}`}
              className="text-sm font-semibold  mt-2"
              color="blue-gray"
            >
              {content?.driver?.email}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Address
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.address}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              ID card type
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.id_card_type}
            </Typography>
          </div>
        </div>
        <div className="h-20" />
      </div>
    </div>
  );
};

export default WithdrawalDetail;
