import { Typography } from "@material-tailwind/react"
import moment from "moment"
import React from "react"
type requestType = {
  registration_number: string
  amount: number
  cover_type: string
  customer_detail: {
    first_name: string
    last_name: string
    phone_number: string
  }
  business_class: {
    name: string
  }
}

type uncreatedPolicyType = {
  policy_number: string
  id: string
  request: requestType
  company_staff: {
    name: string
    id: string
    phone_number: string
  }
  insurer_company: string
  agent_details: {
    first_name: string
    last_name: string
    phone_number: string
  }
  created_date: string
  user_type: string
}

// {
//   __typename: 'policy',
//   id: '691ea85f-c734-4576-aa0a-c1a7b5543cc2',
//   policy_number: 'GP-10-1-24',
//   request: {
//     __typename: 'request',
//     registration_number: 'Gr 123-20',
//     amount: '818',
//     business_class: { __typename: 'business_classes', name: 'Motor Insurance' },
//     cover_type: 'Third Party',
//     customer_detail: {
//       __typename: 'customers',
//       first_name: 'Philipa',
//       last_name: 'Danso',
//       phone_number: '233559585338'
//     },
//     created_date: '2024-06-21T13:31:30.008287',
//     company_staff: {
//       __typename: 'company_staff',
//       id: 'bc243122-eede-4ab3-ad03-7c1bec2a1f2c',
//       name: ' Paul Dwamena',
//       phone_number: '0508305891'
//     },
//     agent_details: null,
//     insurer_company: '61c2b015-ebef-40af-8eec-8b5160eb5575',
//     user_type: 'staff'
//   }
// }
const ResolvePolicyStickerDetails = ({
  content,
}: {
  content: uncreatedPolicyType | null
}) => {
 
  return (
    <div className="overflow-scroll scrolls h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>

        <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Policy Number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.policy_number}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Registration Number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.request?.registration_number}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Cover type
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.request?.cover_type}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Amount
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.request?.amount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Customer details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Name:{" "}
              {`${content?.request?.customer_detail?.first_name} ${content?.request?.customer_detail?.last_name}`}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Phone Number: {content?.request?.customer_detail?.phone_number}
            </Typography>
          </div>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            User type
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.user_type}
          </Typography>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Staff details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Name: {`${content?.company_staff?.name}`}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Phone Number: {content?.company_staff?.phone_number}
            </Typography>
          </div>
        </div>

        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Date Created
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {moment(content?.created_date).format("LLL")}
          </Typography>
        </div>
      </div>
      <div className="h-20" />
    </div>
  )
}

export default ResolvePolicyStickerDetails
