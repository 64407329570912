import React from "react";
import { ActivationType } from "../../types/campaign";
import { Typography } from "@material-tailwind/react";
import moment from "moment";
import { IntermediariesBrokerType } from "../../types/query";

const BrokerDetail = ({
  content,
}: {
  content: IntermediariesBrokerType | undefined;
}) => {
  return (
    <div className="overflow-scroll h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>

        <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Full name
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.name}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Email
            </Typography>
            <Typography
              className="text-sm font-semibold text-black"
              color="blue-gray"
            >
              {content?.email}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Interaction type
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.intermediary_type}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Branch code
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.branch_code}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Tin number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.tin_number}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Nic registration number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.nic_registration_number}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Bank details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Bank: {content?.bank_details?.bank_name}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Branch: {content?.bank_details?.branch}
            </Typography>
          </div>

          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Momo details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.momo_details?.network}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Phone number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.phone_number}
            </Typography>
          </div>

          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Active status
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.active_status}
            </Typography>
          </div>
        </div>
      </div>

      <div className="h-20" />
    </div>
  );
};

export default BrokerDetail;
