import { Button, Typography, Tooltip } from "@material-tailwind/react"
import React from "react"
import { AMOUNT_PAYABLE_EVERYDAY } from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import {} from "recharts"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { calculateAgeing } from "../../util"

type PaymnetSchedule = {
  amount_payable: number
  balance_after: number
  status: string
  scheduled_date: string
  ageing: string
  arrears: number
  actual_amount_paid: number
}

const driiverr: PaymnetSchedule[] = [
  {
    amount_payable: 100,
    balance_after: 200,
    status: "pending",
    scheduled_date: "2024-08-18T00:00:00",
    ageing: "1",
    arrears: 100,
    actual_amount_paid: 200,
  },
  {
    amount_payable: 100,
    balance_after: 200,
    status: "pending",
    scheduled_date: "2024-08-18T00:00:00",
    ageing: "2",
    arrears: 100,
    actual_amount_paid: 200,
  },
  {
    amount_payable: 100,
    balance_after: 200,
    status: "pending",
    scheduled_date: "2024-08-18T00:00:00",
    ageing: "3",
    arrears: 100,
    actual_amount_paid: 200,
  },
  {
    amount_payable: 100,
    balance_after: 200,
    status: "pending",
    scheduled_date: "2024-08-18T00:00:00",
    ageing: "4",
    arrears: 100,
    actual_amount_paid: 200,
  },
  {
    amount_payable: 100,
    balance_after: 200,
    status: "pending",
    scheduled_date: "2024-08-18T00:00:00",
    ageing: "5",
    arrears: 100,
    actual_amount_paid: 200,
  },
]

const ScheduledDriverPaymentDetails = ({
  policy_Id,
  name,
  drivercode,
}: {
  policy_Id: string
  name: string
  drivercode: string
}) => {
  const { loading, data: schedulPaymnets } = useQuery<{
    driver_payments_schedule: PaymnetSchedule[]
  }>(AMOUNT_PAYABLE_EVERYDAY, {
    variables: {
      policy_id: policy_Id,
    },
  })

  console.log(schedulPaymnets)

  const handleDownload = () => {
    // Check if there is data to download
    if (
      schedulPaymnets?.driver_payments_schedule?.length === 0 ||
      !schedulPaymnets
    ) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    const firstItem = driiverr[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = schedulPaymnets?.driver_payments_schedule?.map(
      (item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      }
    )
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"scheduled-payments"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  return (
    <div>
      <div className=" flex gap-x-2 mb-5 justify-between items-center">
        <div>
          <h1 className="text-[1.4rem] text-black">
            {name} - {drivercode}
          </h1>
        </div>

        <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
          <Tooltip content="Export as CSV">
            <Button
              onClick={handleDownload}
              variant="filled"
              className="capitalize text-sm font-normal flex flex-wrap gap-2"
              size="sm"
            >
              <FiDownloadCloud size={20} />
              <span>Download csv</span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <table className="w-full bg-white table-auto border">
        <thead className=" text-center">
          <tr>
            {[
              "Scheduled Date",
              "Scheduled Amount (GHS)",
              "Amount Paid (GHS)",
              "Arrears (GHS)",
              "Premium Balance",
              "Status",
              "Ageing",
            ].map((head: string) => (
              <th
                key={head}
                className=" border-y border-r border-blue-gray-100 text-black bg-gray-100/50 p-4  text-center"
              >
                <Typography
                  color="black"
                  className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {schedulPaymnets?.driver_payments_schedule?.map((item, index) => {
            return (
              <tr
                key={index}
                className="hover:bg-gray-0 transition-all hover:cursor-pointer"
              >
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {moment(item?.scheduled_date).format("LLL")}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r  border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.amount_payable}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.actual_amount_paid}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.arrears}
                  </Typography>
                </td>

                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.balance_after}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.status}
                  </Typography>
                </td>

                <td className="px-2 py-3 border-b-2 border-r border-blue-gray-50 text-center">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.status === "paid"
                      ? 0
                      : calculateAgeing(item?.scheduled_date)}
                  </Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ScheduledDriverPaymentDetails
