import React from "react";
import { ActivationType } from "../../types/campaign";
import { Typography } from "@material-tailwind/react";
import moment from "moment";
import { IntermediariesAgentType } from "../../types/query";
import { StaffResponseType } from "../../pages/dashboard/staffs";
import { GETUSERCOMMISSIONS } from "../../GraphQl/queries";
import { useQuery } from "@apollo/client";

const StaffDetail = ({
  content,
}: {
  content: StaffResponseType | undefined;
}) => {
  const { data, loading } = useQuery<{
    user_commission_setup: {
      commision: {
        id: string;
        name: string;
      };
      settlement_mode: string;
      id: string;
      created_at: string;
    }[];
  }>(GETUSERCOMMISSIONS, {
    variables: {
      id: content?.id,
    },
  });

  return (
    <div className="overflow-scroll scrolls h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>

        <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Full name
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.name}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              ID number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.id_number}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Cash limit
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.cash_limit}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Tin number
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.tin_number}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Bank details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Name: {content?.bank_details?.bank_name}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Branch: {content?.bank_details?.branch}
            </Typography>
          </div>

          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Momo details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.momo_details?.network}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Phone number
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.phone_number_one}`}
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Number 1: {content?.phone_number_one}
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.phone_number_two}`}
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Number 2: {content?.phone_number_two}
            </Typography>
          </div>

          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Branch
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {content?.branch?.name}
            </Typography>
          </div>
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Date Created
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              {moment(content?.created_at).format("LLL")}
            </Typography>
          </div>
        </div>
      </div>

      {data?.user_commission_setup &&
        data?.user_commission_setup?.length > 0 && (
          <>
            <Typography
              variant="h5"
              className="text-lg mt-5 mb-3"
              color="blue-gray"
            >
              Commission details
            </Typography>
            <div className="border border-gray-200 rounded-md py-2 px-4">
              {data?.user_commission_setup?.map((commission, index) => (
                <div key={index} className="mt-4 border-b border-gray-500 pb-2">
                  <div>
                    <Typography
                      className="text-sm text-brand-gray "
                      color="blue-gray"
                    >
                      Commission type
                    </Typography>
                    <Typography
                      className="text-sm font-semibold  capitalize text-black"
                      color="blue-gray"
                    >
                      {commission?.commision?.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className="text-sm text-brand-gray  capitalize mt-3"
                      color="blue-gray"
                    >
                      Settlement mode
                    </Typography>
                    <Typography
                      className="text-sm font-semibold  capitalize text-black"
                      color="blue-gray"
                    >
                      {commission?.settlement_mode}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

      <Typography variant="h5" className="text-lg mt-5 mb-3" color="blue-gray">
        Guarantor details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Name
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.guarantor_details?.name}
          </Typography>
        </div>
        <div>
          <Typography
            className="text-sm text-brand-gray  capitalize mt-3"
            color="blue-gray"
          >
            Phone
          </Typography>
          <Typography
            as={"a"}
            href={`tel:${content?.guarantor_details?.phone}`}
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.guarantor_details?.phone}
          </Typography>
        </div>
        <div>
          <Typography
            className="text-sm text-brand-gray mt-3"
            color="blue-gray"
          >
            Address
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.guarantor_details?.address}
          </Typography>
        </div>
      </div>

      <div className="h-20" />
    </div>
  );
};

export default StaffDetail;
