export const Subscriptions_Table_Head = [
  "reference number",
  "policy number",
  "cover type",
  "amount",
  "policy amount",
  // "subscription_amount",
  "business class",
  "created at"
];

export const Table_Headers = [
  "Driver's  ID",
  "Driver Name",
  "Address",
  "Phone Number",
  "Email",
  "ID Type",
  "ID Number",
  "Momo Details",
  "Bank Details",
  "Date Created",
  "Staff/Agent",
  "Total Collection",
  "Total Amount Withdrawn",
  "Total Amount used",
  "premium balance",
  "Account Balance",
  "Arrears",
  "",
];

export const Tax_HEADERS = [
  "tax",
  "tax amount",
  "rate",
  "commision amount",
  "commission rate (%)",
  "reference amount",
  "status",
  "created At",
];

export const Commissions_HEADERS = [
  "payee name",
  "payee type",
  "payee number",
  "payee details",
  "payment stage",
  "rate",
  "reference amount",
  "transaction name",
  "transaction category",
  "account details",
  "status",
  "created at"
];
export const Policy_HEADERS = [
  "policy number",
  "certificate number",
  "amount",
  "payment channel",
  "Agent/Staff",
  "business class",
  "cover type",
  "created at"
];
export const Customers_HEADERS = [
  "name",
  "email",
  "phone number",
  "customer type",
  "createdAt",
];

// Beneficiaries and their percentages
