import React, { useEffect, useState } from "react"
import { CustomDeleteModal } from "../../../components"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Typography,
  Spinner,
  Tooltip,
  IconButton,
} from "@material-tailwind/react"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { UserType } from "../../../types/user"
import { GETALLNEWPOLICIES } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { getColorCode } from "../../../colorCodes"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

interface Policyy {
  policy_number: string
  policy_stage: string
  certificate_number: string
  request: {
    payment_channel: string
    vehicle_reg_no: string
    client_details: {
      first_name: string
      last_name: string
      phone_number: string
      __typename: "customers"
    }
    vehicle_details_metadata: string
    end_date: string
    start_date: string
    user_type: string
    sub_agent: null | any // Replace 'any' with a more specific type if known
    company_staff: {
      id: string
      phone_number: string
      name: string
      __typename: "company_staff"
    }
    amount: string
    cover_type: string
    benefit_added_details: {
      extra_pa: number
      extra_tppdl: number
      loss_of_use: number
      excess_buy_back: boolean
      umbrella_tppdl_limit: number
    }
    amended_rated_details: {
      fleet_discount: number
      other_discount: number
      own_damage_rate: number
      no_claim_discount: number
    }
    vehicle_details: {
      color: string
      body_type: string
      vehicle_model: string
      vehicle_value: string
      chassis_number: string
      make_of_vehicle: string
      number_of_seats: number
      accessories_value: string
      registration_number: string
      year_of_manufacture: string
    }
    __typename: "request"
  }
  debit_number: string
  reciept_number: string
  status: string
  __typename: "policy"
}

type BusinessClass = {
  name: string
  id: string
}

type CompanyStaff = {
  id: string
  name: string
  phone_number_one: string
}

type SubAagent = {
  id: string
  first_name: string
  last_name: string
  phone_number: string
}

type RiskClass = {
  id: string
  name: string
}

type VehicleDetailsType = {
  color: string
  body_type: string // Assuming this is an identifier for the body type
  vehicle_model: string // Assuming this is an identifier for the vehicle model
  vehicle_value: number
  chassis_number: string
  make_of_vehicle: string // Assuming this is an identifier for the make of the vehicle
  number_of_seats: number
  accessories_value: number
  registration_number: string
  year_of_manufacture: string
}

type PolicyRequest = {
  amended_rated_details: string
  amount: number
  benefit_added_details: string
  businessClassByBusinessClass: BusinessClass
  cover_type: string
  created_at: string
  currency: string
  company_staff: CompanyStaff
  duration: number
  end_date: string
  id: string
  is_paid: boolean
  payment_channel: string
  request_stage: string
  registration_number_text: string
  start_date: string
  user_type: string
  vehicle_details: VehicleDetailsType
  sub_agent: SubAagent
  risk_class: RiskClass
}

type Policy = {
  certificate_number: string
  created_at: string
  debit_number: string
  id: string
  policy_number: string
  policy_stage: string
  reciept_number: string
  status: string
  request: PolicyRequest
}

const NewPolicyView = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const handleOpen = () => setOpen(!open)
  const PAGE_SIZE = 10

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }
  // console.log(startDate)

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }
  // console.log(endDate)

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  function filterPoliciesByDateRange(
    policies: Policyy[],
    fromDate: any,
    toDate: any
  ) {
    // Convert fromDate and toDate to Date objects if they're strings
    const from = fromDate instanceof Date ? fromDate : new Date(fromDate)
    const to = toDate instanceof Date ? toDate : new Date(toDate)

    return policies?.filter((policy: Policyy) => {
      if (!fromDate || !toDate) {
        return true // No filter applied
      }
    
      const startDate = new Date(policy?.request?.start_date)
      const endDate = new Date(policy?.request?.end_date)

      // Check if the policy overlaps with the given date range
      return (
        (fromDate === null || startDate <= from) &&
        (toDate === null || endDate <= to)
      )
    })
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  const { data, loading, refetch } = useQuery<{
    policy: Policyy[]
  }>(GETALLNEWPOLICIES, {
    variables: {
      stage: "new",
      id:companyId
    },
  })

  // console.log(data)

  const filterData = (filter: Policyy) => {
    const date1 = moment(filter?.request?.start_date).format("LL")
    const date2 = moment(filter?.request?.end_date).format("LL")
    const isTextMatch =
      filter?.policy_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.policy_stage
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.reciept_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.amended_rated_details
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.certificate_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.company_staff?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.company_staff?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.cover_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.status
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.vehicle_reg_no
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      date1?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      date2?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.last_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.first_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||

      (`${filter?.request?.sub_agent?.first_name || ''} ${filter?.request?.sub_agent?.last_name || ''}`)
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
        filter?.request?.client_details?.first_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
        filter?.request?.client_details?.last_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
        filter?.request?.client_details?.phone_number
        ?.toString()
        ?.replace(/^233/, '0')  // Replace '233' at the start with '0'
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
        (`${filter?.request?.client_details?.first_name || ''} ${filter?.request?.client_details?.last_name || ''}`)
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.user_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.payment_channel
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())

    return isTextMatch
  }
  //------------------
  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter

    if(searchText || startDate || endDate){
      const type = totalItems?.map((item: Policyy) => ({
        "Policy Number": item?.policy_number,
        "Certificate Number": item?.certificate_number,
        "Vehicle Reg. No.": item?.request.vehicle_reg_no,
        "Client Details": item?.request.client_details
          ? `${item?.request.client_details?.first_name} ${item?.request.client_details?.last_name}`
          : "",
        "Vehicle Details": item?.request.vehicle_details_metadata.split(",")[0],
        "Cover Details": item?.request.cover_type,
        "Financial Details": `GHS ${parseFloat(
          (item?.request?.amount as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        "Intermediary (Agent/Broker)": item?.request.company_staff
          ? item?.request.company_staff?.name
          : `${item?.request.sub_agent?.first_name} ${item?.request.sub_agent?.last_name}`,
        "Policy Start Date": moment(item?.request.start_date).format("LL"),
        "Policy End Date": moment(item?.request.end_date).format("LL"),
        Status: item?.status,
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"New Policies"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }else{
      const type = data?.policy?.map((item: Policyy) => ({
        "Policy Number": item?.policy_number,
        "Certificate Number": item?.certificate_number,
        "Vehicle Reg. No.": item?.request.vehicle_reg_no,
        "Client Details": item?.request.client_details
          ? `${item?.request.client_details?.first_name} ${item?.request.client_details?.last_name}`
          : "",
        "Vehicle Details": item?.request.vehicle_details_metadata.split(",")[0],
        "Cover Details": item?.request.cover_type,
        "Financial Details": `GHS ${parseFloat(
          (item?.request?.amount as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        "Intermediary (Agent/Broker)": item?.request.company_staff
          ? item?.request.company_staff?.name
          : `${item?.request.sub_agent?.first_name} ${item?.request.sub_agent?.last_name}`,
        "Policy Start Date": moment(item?.request.start_date).format("LL"),
        "Policy End Date": moment(item?.request.end_date).format("LL"),
        Status: item?.status,
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"New Policies"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }

   
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  const filteredPolicies = data?.policy?.filter(filterData)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = filterPoliciesByDateRange(
    filteredPolicies!,
    startDate,
    endDate
  )

  // const paginatedData = totalItems?.slice(startIndex, endIndex)
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)
  // const paginatedData = data?.policy
  //   ?.filter(filterData)
  //   ?.slice(startIndex, endIndex)

  // console.log(paginatedData)

  //-----------------

  // const paginatedData = data?.policy
  //   ?.filter(filterData)
  //   ?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (data?.policy?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1)
  //   }
  // }

  return (
    <>
      {/* <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={() => {}}
        loading={loading}
        title="Delete selected admin"
      /> */}
      <CustomHeader title="New Policies" />

      <div className="mt-10 w-full">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4 font-bold leading-none opacity-70 capitalize"
            >
              Total policies{" "}
              {data?.policy && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.policy?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-2 bg-white p-3">
            <div className="flex shrink-0 flex-col  justify-end gap-2 sm:flex-row ml-7">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
            <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
              <div className="w-full">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>

              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className=" bg-white w-full min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "policy no.",
                      "certificate no.",
                      "vehicle reg. no.",
                      "Channel",
                      "client details",
                      "Vehicle details",
                      "cover details",
                      "Financial details",
                      "intermediary (Agent/Broker)",
                      "policy start date",
                      "policy end date",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedRow(item)
                          // setModalOpen(true);
                        }}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.policy_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.certificate_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request.vehicle_reg_no}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request.payment_channel}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Name:{" "}
                              <span className="font-normal">
                                {item?.request?.client_details
                                  ? `${item?.request?.client_details?.first_name} ${item?.request?.client_details?.last_name}`
                                  : ""}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone No.:{" "}
                              <span className="font-normal">
                                {item?.request?.client_details?.phone_number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Vehicle Make:{" "}
                              <span className="font-normal">
                                {
                                  item?.request?.vehicle_details_metadata
                                    ?.split(",")[1]
                                    .split(" ")[2]
                                }
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Vehicle Model:{" "}
                              <span className="font-normal">
                                {
                                  item?.request?.vehicle_details_metadata
                                    ?.split(",")[0]
                                    .split(" ")[1]
                                }
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Yr. of Manufacture:{" "}
                              <span className="font-normal">
                                {
                                  item?.request?.vehicle_details
                                    ?.year_of_manufacture
                                }
                              </span>
                            </Typography>

                            {/* <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Chasis:{" "}
                          <span className="font-normal">
                            {item?.request?.vehicle_details?.chassis_number
                              ? item?.request?.vehicle_details?.chassis_number
                              : "N/A"}
                          </span>
                        </Typography> */}
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Color:{" "}
                              <span className="font-normal">
                                {item?.request?.vehicle_details?.color}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Cover Type:{" "}
                              <span className="font-normal">
                                {item?.request?.cover_type}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize"
                            >
                              Vehicle & Accessories:{" "}
                              <span className="font-normal">
                                GHS{" "}
                                {parseFloat(
                                  (
                                    ((item?.request?.vehicle_details
                                      ?.vehicle_value as any) ?? 0) +
                                    ((item?.request?.vehicle_details
                                      ?.accessories_value as any) ?? 0)
                                  ).toString()
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize"
                            >
                              TPPD:{" "}
                              <span className="font-normal">
                                GHS{" "}
                                {parseFloat(
                                  (
                                    ((item?.request?.benefit_added_details
                                      ?.extra_tppdl as any) ?? 0) + 6000
                                  ).toString()
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize"
                            >
                              P.A Benefits:{" "}
                              <span className="font-normal">
                                GHS{" "}
                                {parseFloat(
                                  (
                                    ((item?.request?.benefit_added_details
                                      ?.extra_pa as any) ?? 0) + 7000
                                  ).toString()
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Premium Paid:{" "}
                              <span className="font-normal">
                                GHS{" "}
                                {parseFloat(
                                  (item?.request?.amount as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Debit No.:{" "}
                              <span className="font-normal">
                                {item?.debit_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Receipt No.:{" "}
                              <span className="font-normal">
                                {item?.reciept_number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.request?.company_staff
                                  ? `${item?.request?.company_staff?.name}`
                                  : `${item?.request?.sub_agent?.first_name} ${item?.request?.sub_agent?.last_name}`}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.request?.company_staff
                                  ? item?.request?.company_staff?.phone_number
                                  : item?.request?.sub_agent?.phone_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              User type:{" "}
                              <span className="font-normal">
                                {item?.request?.company_staff
                                  ? "Company Staff"
                                  : "Sub Agent"}
                              </span>
                            </Typography>
                          </div>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {moment(item?.request?.start_date).format("LL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.request?.end_date).format("LL")}
                          </Typography>
                        </td>

                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.status}
                      </Typography>
                    </td> */}

                        {/* <td className={classes}>
                      <Tooltip content="Edit Admin">
                        <Button
                          color="green"
                          size="sm"
                          className="text-xs font-normal capitalize"
                          onClick={() => {
                            setSelectedRow(item);
                            // setModalOpen(true);
                          }}
                          variant="filled"
                        >
                          View More
                        </Button>
                      </Tooltip>
                    </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>

          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !loading && (
            <div className="my-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && paginatedData?.length! !== 0 && !loading && (
          // <GeneralPagination
          //   totalItems={totalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />
          <PerPageAndPagination
          total={totalItems?.length!}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={itemsPerPage}
          setPerPage={setItemsPerPage}
        />
     
        )}
      </div>
    </>
  )
}

export default NewPolicyView
