import React, { useState, useEffect, ChangeEvent } from "react"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"

import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { PolicyDetails, Document } from "./policy_detaildummy"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import { UserType } from "../../../types/user"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { useQuery } from "@apollo/client"
import { POLICYSTICKERDETAILS } from "../../../GraphQl/queries"
import { BASEURL } from "../../../util"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { DriverModal } from "../../../components/modal/DriverModal"
import ErrorLog from "../../../components/error-logs"

const PolicyDetail = () => {
  const [requestLoading, setRequestLoading] = useState(false)
  const [formData, setFormData] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState<any | null>(null)


  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)



  const { data, loading: loadingPolicies } = useQuery<{
    policy: PolicyDetails[];
    documents: Document[];
  }>(POLICYSTICKERDETAILS, {
    variables: {
      id: localStorage.getItem("selected-policy-id"),
    },
  })

  // Update formData when data is fetched
  useEffect(() => {
    if (data) {
      // Extract the policy metadata
      if (data.policy.length > 0) {
        const { policy_sticker_creation_metadata } = data.policy[0];
        setFormData({ policy_sticker_creation_metadata });
      }

      // Extract and set error logs if documents exist
      if (data.documents.length > 0) {
        const { error_logs } = data.documents[0];
        setErrors(error_logs);
      }
    }
  }, [data]);



  const handleCreateSticker = async () => {
    try {
      setLoading(true)
      const payload = {
        policy_id: localStorage.getItem("selected-policy-id"),
        company_id: companyId,
        payload: formData?.policy_sticker_creation_metadata,
      }



      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create_sticker`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      )

      const req = await response.json()

      if (response.ok) {
        toast.success("Sticker generated successfully")
      } else {
        toast.error(req?.msg)
      }
      setLoading(false)
    } catch (error) {
      toast.error("Error deleting admin")
      setLoading(false)
    }
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)

      setColors(data.colors)
    }
  }, [])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    section: "customer" | "intermediary" | null = null
  ) => {
    const { name, value } = e.target

    setFormData((prevData: any) => {
      const newData = { ...prevData }
      const motorPolicy =
        newData?.policy_sticker_creation_metadata?.motor_policy

      if (section) {
        motorPolicy![section] = {
          ...motorPolicy[section],
          [name]: value,
        }
      } else {
        motorPolicy[name] = value
      }

      return newData
    })
  }
  return (
    <>
      <DriverModal
        title="Policy Error Logs"
        open={showModal}
        size={"xs"}
        focus="message"
        onClose={() => setShowModal(false)}
        children={
          errors && (
            <ErrorLog
              errors={errors.errors}
              message={errors.message}
              onClose={() => setShowModal(false)}
            />
          )
        }
      />

      <div className="flex w-full justify-between items-center">
        <CustomHeader title="Manage Policy Information" />
        <Button
          onClick={() => setShowModal(true)}
          size="sm"
          className="capitalize flex items-center flex-row items-centerfont-normal"
          style={{
            background:
              colors?.length! > 1
                ? `${colors![0]?.toLowerCase()}`
                : `${DEFAULTPRIMARYCOLOR}`,
          }}
        >
          {loading ? <Spinner className="w-4 h-4" /> : "Check error logs"}
        </Button>

      </div>
      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-y-scroll w-full  lg:w-3/4">
          <div className="w-full flex flex-col space-y-4">
            <div className="p-3 bg-white rounded-b-xl ">
              <Typography className="text-lg font-bold">
                Customer Information{" "}
              </Typography>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  type="text"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  // labelProps={{
                  //     className: "before:content-none after:content-none pl-2",
                  // }}

                  name="first_name"
                  label="First Name"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.first_name
                  }
                  onChange={(e) => handleChange(e, "customer")}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Last Name"
                  name="last_name"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.last_name
                  }
                  onChange={(e) => handleChange(e, "customer")}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Other Name"
                  name="other_names"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.other_names
                  }
                  onChange={(e) => handleChange(e, "customer")}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Email"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.email
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="email"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Phone Number"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.phone
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="phone"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Digital address"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.digital_address
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="digital_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Postal Address"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.postal_address
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="postal_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Residential address"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.residential_address
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="residential_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Tin number"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.tin
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="tin"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Occupation"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.occupation
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="occupation"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="National ID"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.national_id
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="national_id"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Dob"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.customer?.date_of_birth
                  }
                  onChange={(e) => handleChange(e, "customer")}
                  name="date_of_birth"
                />
              </div>
            </div>

            <div className="p-3 bg-white rounded-xl">
              <Typography className="text-lg font-bold">
                Vehicle Information{" "}
              </Typography>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  name="vehicle_registration"
                  label="Vehicle Registration Number"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.vehicle_registration
                  }
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Color"
                  name="color"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.color
                  }
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Vehicle Make"
                  name="Vehicle Make"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.make
                  }
                  onChange={(e) => handleChange(e)}
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Vehicle Model"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.model
                  }
                  onChange={(e) => handleChange(e)}
                  name="vehicle model"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Body type"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.body_type_code
                  }
                  onChange={(e) => handleChange(e)}
                  name="phone"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Manufacture Year"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.year_of_manufacture
                  }
                  onChange={(e) => handleChange(e)}
                  name="digital_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Chassis Number"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.chasis_number
                  }
                  onChange={(e) => handleChange(e)}
                  name="postal_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Number of seats"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.seats
                  }
                  onChange={(e) => handleChange(e)}
                  name="residential_address"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Cubic Capacity"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.cubic_capacity
                  }
                  onChange={(e) => handleChange(e)}
                  name="tin"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Mileage"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.mileage
                      ? formData?.policy_sticker_creation_metadata?.motor_policy
                        ?.mileage
                      : ""
                  }
                  onChange={(e) => handleChange(e)}
                  name="occupation"
                />
                <Input
                  crossOrigin="anonymous"
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Fleet"
                  defaultValue={
                    (formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.fleet as string) || ""
                  }
                  onChange={(e) => handleChange(e)}
                  name="fleet"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="p-3 bg-white rounded-xl">
            <Typography className="text-lg font-bold">
              Intermediary Information{" "}
            </Typography>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                name="nic_registration_number"
                label="NIC Registration Number"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.nic_registration_number
                }
                onChange={(e) => handleChange(e, "intermediary")}
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="First Name"
                name="first_name"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.first_name
                }
                onChange={(e) => handleChange(e, "intermediary")}
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Last Name"
                name="last_name"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.last_name
                }
                onChange={(e) => handleChange(e, "intermediary")}
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Email"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.email
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="email"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Phone Number"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.phone
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="phone"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Reference"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.reference
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="reference"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="From Api?"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.from_api + ""
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="from_api"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Api Creator Id"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.api_creator_id
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="api_creator_id"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Intermediary Company id"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.intermediary_company_id
                    ? formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.intermediary?.intermediary_company_id
                    : ""
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="intermediary_company_id"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Uploaded"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.uploaded + ""
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="uploaded"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Intermediary Type Code"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.intermediary_type_code
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="intermediary_type_code"
              />
              <Input
                crossOrigin="anonymous"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                label="Branch Code"
                defaultValue={
                  formData?.policy_sticker_creation_metadata?.motor_policy
                    ?.intermediary?.branch_code
                }
                onChange={(e) => handleChange(e, "intermediary")}
                name="branch_code"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="p-3 bg-white rounded-b-xl">
              <Typography className="text-lg font-bold">
                Quotation Information{" "}
              </Typography>
              <div className="grid grid-cols-3 gap-4 mt-4">
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Currency Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.currency_code
                  }
                  onChange={handleChange}
                  name="currency_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Exchange Rate"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.exchange_rate
                  }
                  onChange={handleChange}
                  name="exchange_rate"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Schedule Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.schedule_code
                  }
                  onChange={handleChange}
                  name="schedule_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Cover Type Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.cover_type_code
                  }
                  onChange={handleChange}
                  name="cover_type_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Computation Type Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.computation_type_code
                  }
                  onChange={handleChange}
                  name="computation_type_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Days"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.days
                  }
                  onChange={handleChange}
                  name="days"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Inception Date"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.inception_date
                  }
                  onChange={handleChange}
                  name="inception_date"
                />

                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Expiry Date"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.expiry_date
                  }
                  onChange={handleChange}
                  name="expiry_date"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Sum Insured"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.sum_insured
                  }
                  onChange={handleChange}
                  name="sum_insured"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Sum Insured Rate"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.sum_insured_rate
                  }
                  onChange={handleChange}
                  name="sum_insured_rate"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Excess Rate"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.excess_rate
                  }
                  onChange={handleChange}
                  name="excess_rate"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Excess Type Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.excess_type_code
                  }
                  onChange={handleChange}
                  name="excess_type_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Total Premium"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.total_premium
                  }
                  onChange={handleChange}
                  name="total_premium"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="TPPD Limit"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.tppd_limit
                  }
                  onChange={handleChange}
                  name="tppd_limit"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="TPPD Rate"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.tppd_rate
                  }
                  onChange={handleChange}
                  name="tppd_rate"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Extra TPPD Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.extra_tppd_charge
                  }
                  onChange={handleChange}
                  name="extra_tppd_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Basic Premium"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.basic_premium
                  }
                  onChange={handleChange}
                  name="basic_premium"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Adjusted Premium"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.adjusted_premium
                  }
                  onChange={handleChange}
                  name="adjusted_premium"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Sticker Fee"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.sticker_fee
                  }
                  onChange={handleChange}
                  name="sticker_fee"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Personal Accident Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.personal_accident_charge
                  }
                  onChange={handleChange}
                  name="personal_accident_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Brown Card Fee"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.brown_card_fee
                  }
                  onChange={handleChange}
                  name="brown_card_fee"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Extra Seats Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.extra_seats_charge
                  }
                  onChange={handleChange}
                  name="extra_seats_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Additional Peril Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.additional_peril_charge
                  }
                  onChange={handleChange}
                  name="additional_peril_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Sub Total Premium"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.sub_total_premium
                  }
                  onChange={handleChange}
                  name="sub_total_premium"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Legacy Policy Number"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.legacy_policy_number
                  }
                  onChange={handleChange}
                  name="legacy_policy_number"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Branch Code"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.branch_code
                  }
                  onChange={handleChange}
                  name="branch_code"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Excess Amount"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.excess_amount
                  }
                  onChange={handleChange}
                  name="excess_amount"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="ECOWAS Peril Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.ecowas_peril_charge
                  }
                  onChange={handleChange}
                  name="ecowas_peril_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Total Discounts"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.total_discounts
                  }
                  onChange={handleChange}
                  name="total_discounts"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Co-insure Rate"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.co_insure_rate
                  }
                  onChange={handleChange}
                  name="co_insure_rate"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Co-insure Amount"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.co_insure_amount
                  }
                  onChange={handleChange}
                  name="co_insure_amount"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Excess Charge"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.excess_charge
                  }
                  onChange={handleChange}
                  name="excess_charge"
                />
                <Input
                  size="lg"
                  className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                  label="Transaction Date"
                  defaultValue={
                    formData?.policy_sticker_creation_metadata?.motor_policy
                      ?.transaction_date
                  }
                  onChange={handleChange}
                  name="transaction_date"
                />
              </div>
            </div>
            <div className="mt-5 mb-4 flex items-end justify-end">
              <Button
                onClick={() => handleCreateSticker()}
                size="sm"
                className="capitalize flex items-center flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                <BiPlus size={20} />
                {loading ? <Spinner className="w-4 h-4" /> : "Generate Sticker"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PolicyDetail
