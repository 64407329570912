import React from "react"
import { AdminType } from "../../types/admin"
import { Typography } from "@material-tailwind/react"
import moment from "moment"

type DRIVER_REQUEST_TYPE = {
  __typename: "driver_withdrawal_request"
  id: string
  reference_number: string
  amount: number
  driver: {
    __typename: "drivers"
    momo_wallet_details: { number: string; network: string }
    phone_number: string
    full_name: string
    driver_code: string
    address: string
    balance: number
    id: string
    driver_station: {
      __typename: "driver_stations"
      id: string
      name: string
    }
  }
  company_staff: {
    __typename: "company_staff"
    id: string
    name: string
    phone_number_one: string
  }
  created_at: string
  driver_approval: boolean
  sub_agent: null | {
    __typename: "sub_agents"
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  driver_current_balance: number
}

const WithdrawalRequestDetail = ({
  content,
}: {
  content: DRIVER_REQUEST_TYPE | undefined
}) => {
  return (
    <div className="overflow-scroll h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>
      </div>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          Reference Number
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.reference_number}
        </Typography>
      </div>
      <div>
        <Typography className="text-sm text-brand-gray mt-4" color="blue-gray">
          Amount
        </Typography>
        <Typography
          className="text-xs font-semibold  mt-2 capitalize text-black"
          color="blue-gray"
        >
          GHS
          {(content?.amount ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </div>
      <div>
        <Typography className="text-sm text-brand-gray mt-4" color="blue-gray">
          Current Balance
        </Typography>
        <Typography
          className="text-xs font-semibold  mt-2 capitalize text-black"
          color="blue-gray"
        >
           GHS
          {(content?.driver_current_balance ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </div>
      <Typography variant="h5" className="text-lg mt-10 mb-3" color="blue-gray">
        Driver details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Driver ID
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.id}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Name
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.full_name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Balance
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.driver?.balance ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>

          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone Number
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver?.phone_number}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.phone_number}
            </Typography>
          </div>
        </div>
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Address
            </Typography>
            <Typography
              as={"a"}
              className="text-sm font-semibold  mt-2"
              color="blue-gray"
            >
              {content?.driver?.address}
            </Typography>
          </div>

          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              station
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.driver_station?.name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >Date</Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {moment(content?.created_at).format("LLL")}
            </Typography>
          </div>
        </div>
        <div className="h-20" />
      </div>
    </div>
  )
}

export default WithdrawalRequestDetail
