import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_SECRET_KEY as string


const encryptData = (data: string): string => {
    if (!secretKey){
        throw new Error('Secret key is not defined')
    }
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };
  
  const decryptData = (cipherText: string): string => {
    if (!secretKey){
        throw new Error('Secret key is not defined')
    }
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

interface AuthState {
    usertype: string | null;
    userId: string | null;
    roleId: string | null;
    companyId: string | null;
    token: string | null;
    expiresAt: string | null;
    showI2I: boolean;
    user: any | null;
}

const initialState: AuthState = {
    usertype: null,
    userId: null,
    roleId: null,
    companyId: null,
    token: null,
    expiresAt: null,
    showI2I: false,
    user: null,
};

const saveStateToLocalStorage = (state: AuthState) => {
    const encryptedState = encryptData(JSON.stringify(state));
    localStorage.setItem('authState', encryptedState);
  };
  
  const loadStateFromLocalStorage = (): AuthState => {
    const encryptedState = localStorage.getItem('authState');
    if (encryptedState) {
      try {
        const decryptedState = decryptData(encryptedState);
       
        return JSON.parse(decryptedState);
      } catch (error) {
        console.error("Error decrypting state from local storage:", error);
        localStorage.removeItem('authState'); // Clear corrupted data
      }
    }
    return initialState;
  };

const authSlice = createSlice({
    name: 'auth',
    initialState: loadStateFromLocalStorage(),
    reducers: {
        setAuthState: (state, action: PayloadAction<AuthState>) => {
            state.usertype = action.payload.usertype;
            state.userId = action.payload.userId;
            state.roleId = action.payload.roleId;
            state.companyId = action.payload.companyId;
            state.token = action.payload.token;
            state.expiresAt = action.payload.expiresAt;
            state.showI2I = action.payload.showI2I;
            state.user = action.payload.user;
            saveStateToLocalStorage(state);
        },
        clearAuthState: (state) => {
            state.usertype = null;
            state.userId = null;
            state.roleId = null;
            state.companyId = null;
            state.token = null;
            state.expiresAt = null;
            state.showI2I = false;
            state.user = null;
            saveStateToLocalStorage(state);
          },

    }
})

export const { setAuthState, clearAuthState } = authSlice.actions;
export default authSlice.reducer;