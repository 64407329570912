import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useRef, useState } from "react"
import {
  COVER_TYPES_PERILS,
  GET_PERILS,
  GET_PERILS_RATES,
  GETALLEXCLUSIONS,
  GETALLPERILS,
  GETALLPERILSNEXCLUSIONSOFCOVERTYPE,
} from "../../../GraphQl/queries"
import { useMutation, useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi"
import {
  ADDPERILOREXCLUSIONTOCOVERTYPE,
  CREATECOVER_TYPE_PERIL_RATES,
} from "../../../GraphQl/mutations"
import { toast } from "react-toastify"
import { ExclusionType, PerilType } from "../../../types/query"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { IoMdCheckbox } from "react-icons/io"
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md"
import { useHistory } from "react-router-dom"
import GeneralPagination from "../../../components/paginations/generalpagination"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type Perils = {
  id: string
  name: string
  status: string | null
  __typename: string
}
type PerilsNew = {
  id: string
  name: string
  status: string | null
  __typename: string
  rate: number
}

type CoverageType = {
  covertype: {
    id: string
    name: string
    __typename: "covertypes"
  }
  peril: {
    id: string
    name: string
    description: string
    __typename: "perils"
  }
  created_at: string
  id: string
  __typename: "coverage"
}

type BusiData = {
  id: string
  busClass: string
  busClassName: string
  sub_classId: string
  sub_class_name:string
  cover: string
  description: string
}
interface InputRefs {
  [key: string]: HTMLInputElement | null
}

const data11 = [
  {
    name: "Test",
    description: "Test Description",
  },
  {
    name: "Test two",
    description: "Test Description",
  },
]
const ManagePremiumRtaes = () => {
  const [id, setId] = useState<string | null>("")
  const storedDataString = localStorage.getItem("prates")!
  const BisData = JSON.parse(storedDataString) as BusiData | null | undefined
  const [searchPerilText, setSearchPerilText] = useState("")
  const [searchExclusionText, setSearchExclusionText] = useState("")
  const [selectedPeril, setSelectedPeril] = useState<string | null>("")
  const [selectedExclusion, setSelectedExclusion] = useState<string | null>("")
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  // const [selectedID, setSlelectedID] = useState<
  //   { class_id: string; id: string; name: string; description: string }[] | []
  // >([])
  const history = useHistory()
  const [selectedID, setSlelectedID] = useState<string[]>([])

  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)


  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const {
    data: perilData,
    loading: loadingPeril,
    refetch: refreshPeril,
  } = useQuery<{
    perils: PerilType[]
  }>(GETALLPERILS)
  const {
    data: coverPeril,
    loading: loadingCover,
    refetch: refetchPeril,
  } = useQuery<{
    coverage: CoverageType[]
  }>(COVER_TYPES_PERILS, {
    variables: {
      cov_id: BisData?.id,
      com_id: companyId,
    },
  })

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const handlePerilSearch = (e: any) => {
    setSearchPerilText(e.target.value)
  }


  function filterUnusedPerils(coverage:CoverageType[], perils:PerilType[]) {
    const coveredPerilIds = coverage?.map(item => item?.peril?.id);
    const unusedPerils = perils?.filter(peril => !coveredPerilIds?.includes(peril?.id)); 
    return unusedPerils;
  }

  const unusedPerils = filterUnusedPerils(coverPeril?.coverage!, perilData?.perils!);


  const filterPeril = (filter: PerilType) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(searchPerilText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchPerilText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchPerilText?.toLowerCase())
    return isTextMatch
  }
  const filterData = (filter: CoverageType) => {
    const isTextMatch =
      filter?.peril?.description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.peril?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }



  const handleExclusionSearch = (e: any) => {
    setSearchExclusionText(e.target.value)
  }
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = coverPeril?.coverage?.filter(filterData)
  // const paginatedData = totalItems?.slice(startIndex, endIndex)

  const paginatedPeril = unusedPerils?.filter(filterPeril)

  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedPeril) {
      const allRows = paginatedPeril!.map((item) => item.id)
      setSlelectedID(allRows)
    } else {
      setSlelectedID([])
    }
  }
  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSlelectedID((prevId) => [...prevId, id])
    } else {
      setSlelectedID((prevId) => prevId.filter((p) => p !== id))
    }
  }


  const handleSubmit = async () => {
    const payload = JSON.stringify(
      selectedID.map((item) => {
        return {
          peril_id: item,
          covertype_id: BisData?.id,
        }
      })
    )
    // console.log(payload)
    setLoadingSubmit(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/add-perils-to-coverage?company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: payload,
        }
      )

      if (!res.ok) {
        const data1 = await res.json()
        console.log(data1)
        toast.error("Error Adding Peril")
        return
      }
      const data1 = await res.json()
      console.log(data1)
      toast.success("Peril added successfully")
      refreshPeril()
      refetchPeril()
    } catch (error) {
      toast.error("Error adding peril")
    } finally {
      setLoadingSubmit(false)
    }
  }

  // useEffect(() => {
  //   if (getStorageCover) {
  //     setCovertype(JSON.parse(getStorageCover));
  //     refetch();
  //   }
  // }, []);

  // console.log(updatedPerils)
  return (
    <>
      <CustomHeader title="Manage Perils" />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Class", "Subclass", "Type of Cover", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {BisData?.busClassName}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {BisData?.sub_class_name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {BisData?.cover}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {BisData?.description}
                  </Typography>
                </td>
                {/* Add other columns or data you want from the first item */}
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-xl">Perils</Typography>
            </div>
            <div className="p-3 bg-white ">
              <div className="w-full mr-3 flex justify-center items-center ">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchPerilText}
                  onChange={handlePerilSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Perils", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head === "Perils" ? (
                          <div className=" flex items-center gap-x-6">
                            {" "}
                            <input
                              type="checkbox"
                              // checked={
                              //   selectedID.length ===
                              //   paginatedPeril?.length
                              // }
                              onClick={(e) => e.stopPropagation()}
                              className=" h-[0.9rem] align-middle w-[0.9rem] cursor-pointer border border-red-400 "
                              onChange={(e) => handleSelectAll(e)}
                            />
                            {head}
                          </div>
                        ) : (
                          head
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedPeril?.map((item) => (
                  <tr
                    onClick={() => {
                      if (selectedPeril === item?.id) {
                        setSelectedPeril(null)
                      } else {
                        setSelectedPeril(item?.id)
                      }
                    }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 `}
                  >
                    {/* <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left"></td> */}
                    <td className="px-4 py-3  border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        <div className=" flex items-center gap-x-6">
                          <input
                            type="checkbox"
                            onClick={(e) => e.stopPropagation()}
                            className=" h-[0.9rem] align-middle w-[0.9rem] cursor-pointer border border-red-400 "
                            onChange={(e) => {
                              // handleRowCheckboxChange(e, {
                              //   class_id: item?.class_id,
                              //   id: item?.id,
                              //   name: item?.name,
                              //   description: item?.description,
                              // })
                              handleRowCheckboxChange(e, item?.id)
                            }}
                            checked={
                              selectedID?.length > 0 &&
                              selectedID.includes(item?.id)
                            }
                          />
                          {item?.name}
                        </div>
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.description}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div className="capitalize bg-white rounded-b-xl mb-5">
            <div className="p-3 ">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchExclusionText}
                  onChange={handleExclusionSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Exclusions", "Description"].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedExclusion?.map((item) => (
                  <tr
                    onClick={() => {
                      if (selectedExclusion === item?.id) {
                        setSelectedExclusion(null)
                      } else {
                        setSelectedExclusion(item?.id)
                      }
                    }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 ${
                      selectedExclusion === item?.id
                        ? "bg-red-200 hover:bg-red-200"
                        : "bg-white"
                    }`}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.description}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingExclusion && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div> */}

          {!loadingPeril && (
            <div className="mt-5 flex items-end justify-end">
              <Button
                onClick={handleSubmit}
                size="sm"
                className="capitalize flex flex-row items-center bg-red-400 font-normal"
              >
                <BiPlus size={30} />
                {loadingSubmit ? "Adding..." : "Add Peril"}
              </Button>
            </div>
          )}
          {loadingPeril && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-xl">List of Perils under {BisData?.cover}</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {["no", "Peril", "Description", "", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-center"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.peril?.name} `
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.peril?.description}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                // setSelectedId(item?.discount?.id)
                                // setOpen(true)
                                // setRateValue(item?.rate)
                                localStorage.setItem(
                                  "coverage",
                                  JSON.stringify({
                                    id: item?.id,
                                    perilName: item?.peril?.name,
                                    description: item?.peril?.description,
                                  })
                                )
                                history.push("/dashboard/setup/manage-perils")
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage Peril rates
                            </Button>
                            {/* <Button
                              onClick={() => {
                                // setSelectedId(item?.discount?.id)
                                // setOpen(true)
                                // setRateValue(item?.rate)
                                localStorage.setItem(
                                  "coverage",
                                  JSON.stringify({
                                    id: item?.id,
                                    perilName: item?.peril?.name,
                                    description: item?.peril?.description,
                                  })
                                )
                                history.push("/dashboard/setup/manage-perils")
                              }}
                              size="sm"
                              color="red"
                              className="text-xs font-normal text-white capitalize bg-red-300"
                            >
                              Delete Peril rate
                            </Button> */}
                          </div>
                        </td>
                     
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loadingCover && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loadingCover && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loadingCover && (
              <div className="my-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && paginatedData?.length! !== 0 && !loadingCover && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <WhitePaginateComponent
              totalPages={totalItems?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
            />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default ManagePremiumRtaes
