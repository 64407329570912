// export const colors = [
//   { name: "lightRed", hex: "#FF9999" },
//   { name: "lightBlue", hex: "#99CCFF" },
//   { name: "lightYellow", hex: "#FFFF99" },
//   { name: "lightGreen", hex: "#99FF99" },
//   { name: "lightOrange", hex: "#FFD580" },
//   { name: "lightPurple", hex: "#D1B3FF" },
//   { name: "lightPink", hex: "#FFB6C1" },
//   { name: "lightTeal", hex: "#A0E7E5" },
//   { name: "lightCyan", hex: "#E0FFFF" },
//   { name: "lightMagenta", hex: "#FFCCE5" },
//   { name: "lightBrown", hex: "#D2B48C" },
//   { name: "lightLime", hex: "#CCFF99" },
//   { name: "lightTurquoise", hex: "#AFEEEE" },
//   { name: "lightCoral", hex: "#FFB3B3" },
//   { name: "lightPeach", hex: "#FFDAB9" },
//   { name: "lightLavender", hex: "#E6E6FA" },
//   { name: "lightOlive", hex: "#C4D4A3" },
//   { name: "lightNavy", hex: "#B3C7E6" },
//   { name: "lightGold", hex: "#FFECB3" },
//   { name: "lightGray", hex: "#D3D3D3" },
//   { name: "lightIndigo", hex: "#C3B1E1" },
// ]

export const colors = [
  { name: "Blue", hex: "#66B2FF" },
  { name: "Red", hex: "#FF6666" },
  { name: "Yellow", hex: "#FFEB66" },
  { name: "Green", hex: "#66FF66" },
  { name: "Orange", hex: "#FFB266" },
  { name: "Pink", hex: "#FF99A6" },
  { name: "Purple", hex: "#B266FF" },
  { name: "Teal", hex: "#66D9CC" },
  { name: "Cyan", hex: "#66FFFF" },
  { name: "Magenta", hex: "#FF99CC" },
  { name: "Brown", hex: "#C89B6E" },
  { name: "Lime", hex: "#B3FF66" },
  { name: "Turquoise", hex: "#66D9E8" },
  { name: "Coral", hex: "#FF7F7F" },
  { name: "Peach", hex: "#FFC299" },
  { name: "Lavender", hex: "#D9B3FF" },
  { name: "Olive", hex: "#A3C66F" },
  { name: "Navy", hex: "#7FA4FF" },
  { name: "Gold", hex: "#FFCC66" },
  { name: "Gray", hex: "#B3B3B3" },
  { name: "White", hex: "#FFFFFF" }, 
  { name: "Black", hex: "#000000" }, 
]

export function getColorCode(arr: (string | null)[]) {
  const change = arr?.map((arr)=>arr?.toLowerCase())?.map((arr)=>arr![0]?.toUpperCase() + arr?.slice(1))
  const colorCodes = colors
    .map((color, index) => {
      if (change.includes(color.name)) {
        return color.hex
      }
      return null
    })
    .filter((colorCode) => colorCode !== null)
  return colorCodes as string[]
}
