import React from "react";
import { Drawer } from "@material-tailwind/react";
import SidebarContent from "./SidebarContent";
import useDrawer from "../../hooks/use-drawer";

export function MobileSidebar({
  open,
  closeDrawer,
}: {
  open: boolean;
  closeDrawer: () => void;
}) {
  // const { open, closeDrawer } = useDrawer();

  return (
    <>
      <Drawer open={open} onClose={closeDrawer}>
        <SidebarContent />
      </Drawer>
    </>
  );
}
