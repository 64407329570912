import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
  RouteProps,
  useHistory,
} from "react-router-dom";
// import { LoginEmail, Signup } from "./pages";
import { DashboardContent } from "./components";
import { ComponentType, useEffect, useState } from "react";
import LoginEmail from "./pages/login/email";
import Signup from "./pages/login/change-password";
import { PaymentFailedPage, PaymentSuccessPage } from "./pages";
import LoginOtp from "./pages/login/otp";
import ChangePassword from "./pages/login/change-password";
import OtpRequest from "./pages/login/otp-for-login";
import EnterOtp from "./pages/login/otp-for-login";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./store/store";
import { clearAuthState } from "./store/authSlice";
import CryptoJS from "crypto-js";

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const history = useHistory();
  const dispatch = useDispatch()
  const { token, expiresAt } = useSelector((state: RootState) => state.auth);

 

  const isAuthenticated = !!token;

  const logoutUser = () => {
    const currentTime = new Date().getTime();
    const expiresAtTime = parseInt(expiresAt as string, 10) * 1000;
    if (expiresAt && currentTime > expiresAtTime) {
      // Session has expired, clear user data and redirect to login
      dispatch(clearAuthState());
      history.replace("/");  // Ensure history is used correctly here
    }
  };
  

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        logoutUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", logoutUser);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", logoutUser);
    };
  }, [expiresAt, dispatch]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  useEffect(() => {
    const handlePopstate = () => {
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardContent} />
        <Route path="/payment-success" component={PaymentSuccessPage} />
        <Route path="/payment-failed" component={PaymentFailedPage} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/request-otp" component={LoginOtp} />
        <Route path="/continue-login" component={EnterOtp} />
        <Route path="/" component={LoginEmail} />

        <Route path="/signup" component={Signup} />

      </Switch>
    </Router>
  );
}

export default App;
