import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState, useMemo } from "react"
import { CustomModal } from "../../../components"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { UpdateType } from "../../../types/admin"
import { toast } from "react-toastify"
import { UserType } from "../../../types/user"
import { SubmitHandler, useForm } from "react-hook-form"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { BiPlus } from "react-icons/bi"
import { useQuery } from "@apollo/client"
import {
  GETPAYMENTOFPOLICY,
  GETPAYMENTREQUISITIONDetail,
  GETPOLICYDETAIL,
  GETPOLICYDETAILTEN,
} from "../../../GraphQl/queries"
import moment from "moment"

type PolicyDetail = {
  id: string
  request: {
    id: string
    start_date: string
    end_date: string
    customer: {
      first_name: string
      last_name: string
    }
    business_class: {
      name: string
      id: string
    }
    sub_class: {
      id: string
      name: string
    }
  }
}

type PaymentHistoryType = {
  amount: string
  approved_by: string
  created_at: string
  id: string
  status: string
  transaction_number: string
  transaction_type: string
  description: string
  payment_requisition: {
    payee_type: string
    payee_id: string
    reference: string
    reference_amount: string
    rate: string
    id: string
    created_by: string
  }
}

type RequisitionType = {
  account_details: {
    acct_number: string
    bank_name: string
    branch: string
  }
  created_at: string
  id: string
  insurance_company_id: string
  payee_details: string
  payee_id: string
  payee_name: string
  payee_number: string
  payee_type: string
  payment_stage: string
  policy_id: string
  rate: string
  transaction_type: string
  reference: string
  reference_amount: string
  // amount_requisitioned: string;
  transaction_name: string
}

type FormType = {
  reference_amount: string
  transaction_category: string
  transaction_type_id: string
  policy_id: string
  payee_type: string
  rate: string
  payee_id: string
  insurance_company_id: string
}

const RequisitionView = () => {
  const history = useHistory()
  const [user, setUser] = useState<UserType>()
  const [requistionText, setRequistionSearch] = useState("")
  const [historyText, setHistoryText] = useState("")
  const [policy_number, SetPolicynumber] = useState("")
  const [activeHistory, setActiveHistory] = useState(1)
  const [activeRequisition, setActiveRequisition] = useState(1)
  const [token, setToken] = useState()
  const [loading, setLoading] = useState(false)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<UpdateType>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [showUpdateModal, setUpdateModal] = useState(false)
  const [selectedPolicy, setPolicy] = useState<PolicyDetail | null>()
  const [policyDetails, setPolicies] = useState<PolicyDetail[] | null>()

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    }
  }, [showUpdateModal])

  const {
    register: updateRegister,
    handleSubmit: updateSubmit,
    setValue,
    formState: { errors: updateError, isSubmitting: isUpdateSubmitting },
  } = useForm<UpdateType>({
    defaultValues: defaultValues,
  })

  const {
    data: requisition,
    loading: loadingRequistion,
    refetch: refetchRequisition,
  } = useQuery<{
    view_payment_requistion_details: RequisitionType[]
    payment_requisition: any
  }>(GETPAYMENTREQUISITIONDetail, {
    variables: {
      id: selectedPolicy?.id,
    },
  })

  const {
    data: policyDetail,
    loading: loadingPolicyDetail,
    refetch,
  } = useQuery<{
    policy: PolicyDetail[]
  }>(GETPOLICYDETAILTEN)

  const {
    data: policyResult,
    loading: loadingPolicyResult,
    refetch: searchPolicy,
  } = useQuery<{
    policy: PolicyDetail[]
  }>(GETPOLICYDETAIL)

  const {
    data: pamentHistory,
    loading: loadingPayment,
    refetch: refetcHistory,
  } = useQuery<{
    payment_history: PaymentHistoryType[]
  }>(GETPAYMENTOFPOLICY, {
    variables: {
      policy_id: selectedPolicy?.id,
    },
  })

  const PAGE_SIZE = 6

  const handleRowClick = (item: UpdateType) => {
    setSelectedRow(item)
    setOpenDrawer(true)
  }

  const handleRequistionSerach = (e: any) => {
    setRequistionSearch(e.target.value)
    setActiveHistory(1)
  }

  const handleHistorySearch = (e: any) => {
    setHistoryText(e.target.value)
    setActiveHistory(1)
  }

  const filterData = (data: PaymentHistoryType) => {
    const isTextMatch =
      data?.description?.toLowerCase()?.includes(historyText?.toLowerCase()) ||
      data?.payment_requisition?.payee_type
        ?.toLowerCase()
        ?.includes(historyText?.toLowerCase()) ||
      data?.transaction_type
        ?.toLowerCase()
        ?.includes(historyText?.toLowerCase()) ||
      data?.transaction_number
        ?.toLowerCase()
        ?.includes(historyText?.toLowerCase()) ||
      data?.transaction_type
        ?.toLowerCase()
        ?.includes(historyText?.toLowerCase())
    return isTextMatch
  }

  const filterRequisition = (data: RequisitionType) => {
    const isTextMatch =
      data?.payee_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_type
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.reference?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      data?.payee_number
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.payment_stage
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.rate?.toLowerCase()?.includes(requistionText?.toLowerCase())
    return isTextMatch
  }

  // Filtered and paginated data for the current page
  const paginatedData = pamentHistory?.payment_history
    ?.filter(filterData)
    .slice((activeHistory - 1) * PAGE_SIZE, activeHistory * PAGE_SIZE)

  const paginatedRequisitionData = requisition?.view_payment_requistion_details
    ?.filter(filterRequisition)
    .slice((activeHistory - 1) * PAGE_SIZE, activeHistory * PAGE_SIZE)

  const nextPage = () => {
    if (
      (activeHistory - 1) * PAGE_SIZE <
      (pamentHistory?.payment_history?.filter(filterData).length || 0)
    ) {
      setActiveHistory(activeHistory + 1)
    }
  }

  const prevPage = () => {
    if (activeHistory > 1) {
      setActiveHistory(activeHistory - 1)
    }
  }

  const nextRequisitionPage = () => {
    if (
      (activeRequisition - 1) * PAGE_SIZE <
      (requisition?.view_payment_requistion_details?.filter(filterRequisition)
        .length || 0)
    ) {
      setActiveRequisition(activeHistory + 1)
    }
  }

  const prevRequisitionPage = () => {
    if (activeHistory > 1) {
      setActiveRequisition(activeRequisition - 1)
    }
  }

  useEffect(() => {
    let storage = localStorage.getItem("user")
    if (storage) {
      setUser(JSON.parse(storage))
    }
  }, [])

  useEffect(() => {
    if (policyDetail?.policy?.length === 1) {
      setPolicy(policyDetail?.policy[0])
    }
  }, [policyDetail])

  useEffect(() => {
    setPolicies(policyDetail?.policy)
  }, [policyDetail])

  useEffect(() => {
    setPolicies(policyResult?.policy)
  }, [policyResult])

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value)
      })
    }
  }, [defaultValues])

  const handleDelete = async () => {
    setOpenDrawer(false)
    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/delete-admin`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            admin_id: selectedRow?.admin_id,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        toast.success("Admin deleted successfully")
        SetShowDeleteModal(false)
        if (user?.email === selectedRow?.email) {
          localStorage.clear()
          history?.push("/")
          //()
        }
      } else {
        toast.error(req?.msg)
      }
      setLoading(false)
    } catch (error) {
      toast.error("Error deleting admin")
      setLoading(false)
    }
  }

  const handleUpdate: SubmitHandler<UpdateType> = async (data) => {
    // try {
    //   setLoading(true);
    //   const response = await fetch(`${BASEURL}/auth/delete-admin`, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       role: selectedRow?.role,
    //       department: selectedRow?.department,
    //       email: selectedRow?.email,
    //     }),
    //   });
    //   const req = await response.json();
    //   if (response.ok) {
    //     toast.success(req?.msg);
    //     mutate();
    //     setUpdateModal(false);
    //   } else {
    //     toast.info(req?.msg);
    //   }
    //   setLoading(false);
    // } catch (error: any) {
    //   console.log(error?.message);
    //   toast.error("Error updating admin");
    //   setLoading(false);
    // }
  }

  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ) {
    let timeoutId: NodeJS.Timeout
    return function (this: any, ...args: Parameters<T>) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  const handleDebouncedInputChange = debounce((value: string) => {
    searchPolicy({ policy_number: `${value}%` })
  }, 1800) // Debounce time in milliseconds

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetPolicynumber(event.target.value)
    handleDebouncedInputChange(event.target.value)
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)

      setColors(data.colors)
    }
  }, [])

  React.useEffect(() => {
    if (userData) {
      setUser(JSON.parse(userData))
    }
  }, [])

  return (
    <>
      <CustomModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        children={
          <>
            <div className="mb-6 flex flex-col gap-2">
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Reference Amount
                </Typography>

                <Input
                  size="lg"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={""} // {...register("rate", { required: true })}
                />
              </div>
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Category of Transaction
                </Typography>

                <select
                  id="subclass"
                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // {...register("subclass", { required: true })}
                >
                  {/* <option>
                    {loadingSubClass ? "Loading..." : "Select subclass"}
                  </option>
                  {subclasses?.subclass?.map((data) => (
                    <option label={data?.name} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                  <option
                    value={"00000000-0000-0000-0000-000000000000"}
                    label="All"
                  >
                    All
                  </option> */}
                </select>
              </div>

              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Payment Transaction
                </Typography>
                <select
                  id="subclass"
                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // {...register("subclass", { required: true })}
                >
                  {/* <option>
                    {loadingSubClass ? "Loading..." : "Select subclass"}
                  </option>
                  {subclasses?.subclass?.map((data) => (
                    <option label={data?.name} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                  <option
                    value={"00000000-0000-0000-0000-000000000000"}
                    label="All"
                  >
                    All
                  </option> */}
                </select>
              </div>
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Client Type
                </Typography>
                <select
                  id="subclass"
                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // {...register("subclass", { required: true })}
                >
                  {/* <option>
                    {loadingSubClass ? "Loading..." : "Select subclass"}
                  </option>
                  {subclasses?.subclass?.map((data) => (
                    <option label={data?.name} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                  <option
                    value={"00000000-0000-0000-0000-000000000000"}
                    label="All"
                  >
                    All
                  </option> */}
                </select>
              </div>
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Payee
                </Typography>
                <Input
                  size="lg"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={undefined} // {...register("rate", { required: true })}
                />
              </div>
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Rate
                </Typography>
                <Input
                  size="lg"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={undefined} // {...register("rate", { required: true })}
                />
              </div>
              <div className="w-full my-2 flex items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Amount Requisitioned
                </Typography>
                <Input
                  size="lg"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={undefined} // {...register("rate", { required: true })}
                />
              </div>
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  className="capitalize flex flex-row items-centerfont-normal"
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                >
                  <BiPlus size={20} />
                  {/* {isSubmitting ? "Creating..." : "Create commission Rate"} */}
                  Create
                </Button>
              </div>
            </div>
          </>
        }
      />

      {/* <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      /> */}

      <CustomHeader title="Payment Requisition" />
      <div className="w-full mb-5">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search by Policy Number"
                value={policy_number}
                onChange={handleChange}
                // onChange={(e) => SetPolicynumber(e.target.value)}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          <div className="mx-1" />

          <Button
            onClick={() => {
              if (policy_number) {
                searchPolicy({ policy_number: `${policy_number}%` })
                // refetch({
                //   variables: {
                //     policy_number: `${policy_number}%`,
                //   },
                // });
              }
            }}
            className="w-full bg-[#3a2980] md:w-36 lg:w-36 flex items-center justify-center"
          >
            {loadingPolicyDetail || loadingPolicyResult ? (
              <Spinner className="w-4 h-4" />
            ) : (
              "Search"
            )}
          </Button>
          <Button
            onClick={() => {
              setPolicy(null)
              SetPolicynumber("")
              refetch()
              setPolicies(policyDetail?.policy)
            }}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="flex items-center justify-center"
          >
            Reset
          </Button>
        </div>
      </div>

      <div className="rounded-lg overflow-x-scroll sroll">
        {policyDetail && policyDetail?.policy?.length > 0 && (
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70"
            >
              Select a policy by clicking on a row
            </Typography>
          </div>
        )}
        <table className="w-full bg-white min-w-max table-auto">
          <thead className="">
            <tr>
              {[
                "Policyholder",
                "Class of Business",
                "SubClass",
                "Effective From",
                "Effective To",
              ].map((head) => (
                <th
                  key={head}
                  className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                >
                  <Typography
                    color="black"
                    className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {policyDetails?.slice(0, 10)?.map((item, index: number) => {
              const isLast = index === policyDetails?.length - 1
              const classes = isLast
                ? "px-2 py-4  text-center"
                : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

              return (
                <tr
                  className={
                    policyDetails?.length > 1
                      ? ` transition-all hover:cursor-pointer  ${
                          item.id === selectedPolicy?.id
                            ? "bg-red-300 hover:bg-red-300"
                            : "hover:bg-gray-100"
                        }`
                      : ` transition-all hover:cursor-pointer hover:bg-gray-100`
                  }
                  key={index.toString()}
                  onClick={() => {
                    if (selectedPolicy?.id === item?.id) {
                      setPolicy(null)
                    } else {
                      setPolicy(item)
                    }
                  }}
                >
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {`${item?.request?.customer?.first_name} ${item?.request?.customer?.last_name}`}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs"
                    >
                      {item?.request?.business_class?.name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {item?.request?.sub_class?.name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {moment(item?.request?.start_date).format("LLL")}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize"
                    >
                      {moment(item?.request?.end_date).format("LLL")}
                    </Typography>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              List of All Transactions on this Policy
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={historyText}
                    onChange={handleHistorySearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <div className="mx-2" />

              <Button
                onClick={() => SetShowDeleteModal(true)}
                className="w-full md:w-36 lg:w-36"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                Create
              </Button>
            </div>
          </div>

          <table className="w-full bg-white min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Transaction Number",
                  "Transaction Type",
                  "Description",
                  // "phone Number",
                  // "Debit Amount",
                  "Amount Paid",
                  "payee type",
                  "Payment Date",
                  // "Outstanding Amount",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index: number) => {
                const isLast = index === paginatedData?.length - 1
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

                return (
                  <tr
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.transaction_number}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.transaction_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.description}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {"GH₵" +
                          ((item?.amount as any) ?? 0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.payment_requisition?.payee_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.created_at).format("LLL")}
                      </Typography>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
        {loadingPayment && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {!loadingPayment && !pamentHistory && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {/* Paginator */}
        {pamentHistory && pamentHistory?.payment_history?.length > 0 && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                disabled={activeHistory === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="text-white">{activeHistory}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (pamentHistory?.payment_history?.filter(filterData)
                      .length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextPage}
                disabled={
                  activeHistory ===
                  Math.ceil(
                    (pamentHistory?.payment_history?.filter(filterData)
                      .length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of All Payment Requisitions
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={requistionText}
                    onChange={handleRequistionSerach}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className="w-full bg-white min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Payment Transaction Type",
                  "Transaction Reference",
                  "Payee Details",
                  "amount",
                  "Rate",
                  "Account Details",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedRequisitionData?.map((item, index: number) => {
                const isLast = index === paginatedRequisitionData?.length - 1
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

                return (
                  <tr
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.transaction_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.reference}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Client Name:{" "}
                        <span className="font-normal">{item?.payee_name}</span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Client Type:{" "}
                        <span className="font-normal">{item?.payee_type}</span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Phone No.:{" "}
                        <span className="font-normal">
                          {item?.payee_number}
                        </span>
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {"GH₵ " +
                          (
                            (requisition?.payment_requisition[index]
                              .amount_requisitioned as any) ?? 0
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.rate}%
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Bank:{" "}
                        <span className="font-normal">
                          {item?.account_details?.bank_name}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Branch:{" "}
                        <span className="font-normal">
                          {item?.account_details?.branch}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        A/C No:{" "}
                        <span className="font-normal">
                          {item?.account_details?.acct_number}
                        </span>
                      </Typography>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
        {loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {!paginatedRequisitionData && !loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {/* Paginator */}
        {paginatedRequisitionData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevRequisitionPage}
                disabled={activeRequisition === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="text-white">{activeRequisition}</strong>{" "}
                of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (requisition?.view_payment_requistion_details?.filter(
                      filterRequisition
                    ).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextRequisitionPage}
                disabled={
                  activeRequisition ===
                  Math.ceil(
                    (requisition?.view_payment_requistion_details?.filter(
                      filterRequisition
                    ).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default RequisitionView
