import {
  UsersView,
  CreateNotification,
  Customers,
  DashboardOverview,
  CreateIntermediaries,
  Payments,
  Policies,
  ReportView,
  RiskClass,
  RolesAndPermissions,
  Settings,
  CreateSubAgent,
  Intermediary,
  BanksPage,
  DiscountPage,
  VehiclePage,
  CurrenyPage,
  BodyTypePage,
  CreateAdmin,
  SubAgents,
  CountryPage,
  Company,
  CreateComapny,
  CreateStaff,
  Staff,
  PremiumView,
  CommissionsView,
  FeeView,
  RequisitionView,
  StaffsView,
  InsuranceCompanyView,
  ReplenishmentView,
  ClientView,
  QuotationView,
  NewPolicyView,
  RenewalView,
  SetupLocationView,
  SetupBranchView,
  ManageDistrict,
  SetupCLientTypeView,
  ManageTown,
  SetupWorkGroupView,
  PremiumRateView,
  LevieView,
  ManageLevieView,
  ManageComissionRate,
  SetupLoading,
  ManageLoadingRate,
  ManageDiscountRate,
  SetupDiscount,
  ManageSubclass,
  BusinessClassView,
  PerilView,
  CoverTypeView,
  ManagePerilAndExlusion,
  TaxView,
  ManageTaxes,
  RiskClassView,
  ManageRiskRate,
  ManageStaff,
  Agents,
  ManageAgent,
  AccountFunding,
  FundingApproval,
  PaymentOrder,
  PaymentApproval,
  SetupTax,
  PaidCommissions,
  Invest2InsureOverview,
  Invest2InsureCollection,
  Invest2InsureWithdrawal,
  Invest2InsureReport,
  LapsedView,
  Invest2InsurePayments,
  ResolverView,
  PolicyResolverDetail,
} from "./pages"
import SetupCommission from "./pages/dashboard/commissions"
import ManageDrivers from "./pages/Invest2insure/manageDrivers/manage"
import ManageDriver from "./pages/Invest2insure/manageDrivers/index"
import CloseAccountRequest from "./pages/Invest2insure/manageDrivers/CloseAccountRequest"
import AgentCommission from "./pages/Invest2insure/agent_financial_details/commisions"
import ReplenishmentsReciepts from "./pages/dashboard/riplenishment/receipts"
import AccountBalances from "./pages/dashboard/accountBalances"
import PaymentApprovals from "./pages/Invest2insure/agent_financial_details/payment_approvals"
import PaidCommisions from "./pages/Invest2insure/agent_financial_details/paid_commission"
import PaymentOrders from "./pages/Invest2insure/agent_financial_details/payment_order"
import PaidCommisionss from "./pages/Invest2insure/agent_financial_details/paid_commission"
import PaymentOrderLogs from "./pages/dashboard/payment-failure-logs"
import ReplenishmentRequests from "./pages/dashboard/riplenishment/ReplenishmentRequests"
import PremiumRatesSetup from "./pages/dashboard/setup/premiumRates"
import ReplenishmentWithStateView from "./pages/dashboard/riplenishment/replenishmentwithState"
import LoadingRatesSetup from "./pages/dashboard/additionalSetup"
import ManagePremiumRtaes from "./pages/dashboard/setup/managePremiumRtaes"
import DiscountRatesSetup from "./pages/dashboard/discountRates"
import AdditionalSetup from "./pages/dashboard/additionalSetup"
import ManageDiscountRates from "./pages/dashboard/discountRates/manage"
import ManageAdditionalRate from "./pages/dashboard/additionalSetup/manage"
import ManageAdditionalBenefits from "./pages/dashboard/additionalSetup/manage"
import ManagePeril from "./pages/dashboard/setup/managePerils"
import Loadings from "./pages/dashboard/loading"
import PolicyLoading from "./pages/dashboard/loading"
import ManagePolicyLoadings from "./pages/dashboard/loading/manage"

interface PrivatrRoutes {
  path?: string
  component: () => JSX.Element
  name?: string
  layout?: string
  exact?: boolean
}

export const privateRoutes: PrivatrRoutes[] = [
  {
    path: "/",
    component: DashboardOverview,
    name: "dashboard",
    exact: true,
    layout: "/dashboard",
  },
  {
    path: "/intermediaries",
    exact: false,
    component: UsersView,
    name: "intermediaries",
    layout: "/dashboard",
  },
  {
    path: "/insurance-companies",
    exact: false,
    component: InsuranceCompanyView,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/account-balances",
    exact: false,
    component: AccountBalances,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/client",
    exact: false,
    component: ClientView,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/policy",
    exact: false,
    component: Policies,
    name: "clients",
    layout: "/dashboard",
  },
  {
    path: "/quotations",
    exact: false,
    component: QuotationView,
    name: "quotation",
    layout: "/dashboard",
  },
  {
    path: "/new-policy",
    exact: false,
    component: NewPolicyView,
    name: "new-policy",
    layout: "/dashboard",
  },
  {
    path: "/renewals",
    exact: false,
    component: RenewalView,
    name: "renewals",
    layout: "/dashboard",
  },
  {
    path: "/create-company",
    exact: false,
    component: CreateComapny,
    name: "insurance-companies",
    layout: "/dashboard",
  },
  {
    path: "/staffs",
    exact: false,
    component: StaffsView,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/create-staffs",
    exact: false,
    component: CreateStaff,
    name: "staffs",
    layout: "/dashboard",
  },
  {
    path: "/create-notification",
    exact: false,
    component: CreateNotification,
    name: "create-notification",
    layout: "/dashboard",
  },
  {
    path: "/reports",
    exact: false,
    component: ReportView,
    name: "reports",
    layout: "/dashboard",
  },
  {
    path: "/settings",
    exact: false,
    component: Settings,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/make-payments",
    exact: false,
    component: ReplenishmentWithStateView,
    name: "replenishment",
    layout: "/dashboard",
  },
  {
    path: "/replenishment-requests",
    exact: false,
    component: ReplenishmentRequests,
    name: "replenishment",
    layout: "/dashboard",
  },
  {
    path: "/agent-receipts",
    exact: false,
    component: ReplenishmentsReciepts,
    name: "replenishment",
    layout: "/dashboard",
  },
  {
    path: "/customers",
    exact: false,
    component: Customers,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/overview",
    exact: false,
    component: Invest2InsureOverview,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/collections",
    exact: false,
    component: Invest2InsureCollection,
    name: "settings",
    layout: "/dashboard",
  },

  {
    path: "/i2i/invest-2-insure-payments",
    exact: false,
    component: Invest2InsurePayments,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/report",
    exact: false,
    component: Invest2InsureReport,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/withdrawals",
    exact: false,
    component: Invest2InsureWithdrawal,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/lapsed",
    exact: false,
    component: LapsedView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/taxes",
    exact: false,
    component: PremiumView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/paid_commissions",
    exact: false,
    component: PaidCommissions,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/pending_commissions",
    exact: false,
    component: CommissionsView,
    name: "financial",
    layout: "/dashboard",
  },
  // {
  //   path: "/commissions",
  //   exact: false,
  //   component: UpdatedCommissionsView,
  //   name: "financial",
  //   layout: "/dashboard",
  // },
  {
    path: "/fees",
    exact: false,
    component: FeeView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/requisition",
    exact: false,
    component: RequisitionView,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/orders",
    exact: false,
    component: PaymentOrder,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/approvals",
    exact: false,
    component: PaymentApproval,
    name: "financial",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-intermediary",
    exact: false,
    component: CreateIntermediaries,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-staff",
    exact: false,
    component: CreateStaff,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/create-users",
    exact: false,
    component: CreateAdmin,
    name: "users",
    layout: "/dashboard",
  },
  {
    path: "/setup/staff",
    exact: false,
    component: Staff,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/setup/funding",
    exact: false,
    component: AccountFunding,
    name: "funding",
    layout: "/dashboard",
  },
  {
    path: "/setup/agent",
    exact: false,
    component: Agents,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-staff",
    exact: false,
    component: ManageStaff,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-agent",
    exact: false,
    component: ManageAgent,
    name: "Staff",
    layout: "/dashboard",
  },
  {
    path: "/setup/intermediaries",
    exact: false,
    component: Intermediary,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/insurance-companies",
    exact: false,
    component: Company,
    name: "company",
    layout: "/dashboard",
  },
  {
    path: "/setup/create-insurance-company",
    exact: false,
    component: CreateComapny,
    name: "create-company",
    layout: "/dashboard",
  },
  {
    path: "/setup/risk-class",
    exact: false,
    component: RiskClass,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/banks",
    exact: false,
    component: BanksPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/riskclass",
    exact: false,
    component: RiskClassView,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-riskclass",
    exact: false,
    component: ManageRiskRate,
    name: "settings",
    layout: "/dashboard",
  },
  // {
  //   path: "/setup/discounts",
  //   exact: false,
  //   component: DiscountPage,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/levies",
    exact: false,
    component: LevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/commissions",
    exact: false,
    component: SetupCommission,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/premium-rates-setup",
    exact: false,
    component: PremiumRatesSetup,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-perils",
    exact: false,
    component: ManagePeril,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/additional-rates-setup",
    exact: false,
    component: AdditionalSetup,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-additional-rates",
    exact: false,
    component: ManageAdditionalBenefits,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/discount-rates-setup",
    exact: false,
    component: DiscountRatesSetup,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-discount-rates",
    exact: false,
    component: ManageDiscountRate,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/policy-loadings",
    exact: false,
    component: PolicyLoading,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-policy-loadings",
    exact: false,
    component: ManagePolicyLoadings,
    name: "commissions",
    layout: "/dashboard",
  },

  {
    path: "/setup/manage-commissions",
    exact: false,
    component: ManageComissionRate,
    name: "commissions",
    layout: "/dashboard",
  },
  {
    path: "/setup/loadings",
    exact: false,
    component: SetupLoading,
    name: "loadings",
    layout: "/dashboard",
  },
  {
    path: "/setup/discounts",
    exact: false,
    component: SetupDiscount,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-discounts",
    exact: false,
    component: ManageDiscountRate,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-premium-rates",
    exact: false,
    component: ManagePremiumRtaes,
    name: "discounts",
    layout: "/dashboard",
  },
  {
    path: "/setup/business-class",
    exact: false,
    component: BusinessClassView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-subclass",
    exact: false,
    component: ManageSubclass,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/peril",
    exact: false,
    component: PerilView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/workgroups",
    exact: false,
    component: SetupWorkGroupView,
    name: "subclass",
    layout: "/dashboard",
  },
  {
    path: "/setup/cover-types",
    exact: false,
    component: CoverTypeView,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-peril-exclusion",
    exact: false,
    component: ManagePerilAndExlusion,
    name: "covertypes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-levies",
    exact: false,
    component: ManageLevieView,
    name: "levies",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-loadings",
    exact: false,
    component: ManageLoadingRate,
    name: "levies",
    layout: "/dashboard",
  },

  {
    path: "/setup/premium-rates",
    exact: false,
    component: PremiumRateView,
    name: "premium-rates",
    layout: "/dashboard",
  },
  {
    path: "/setup/vehicle",
    exact: false,
    component: VehiclePage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/countries",
    exact: false,
    component: CountryPage,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/location",
    exact: false,
    component: SetupLocationView,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_district/:id",
    exact: false,
    component: ManageDistrict,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage_town/:id",
    exact: false,
    component: ManageTown,
    name: "location",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_types",
    exact: false,
    component: SetupCLientTypeView,
    name: "client_types",
    layout: "/dashboard",
  },
  {
    path: "/setup/client_registration",
    exact: false,
    component: Company,
    name: "client_registration",
    layout: "/dashboard",
  },
  {
    path: "/setup/branches",
    exact: false,
    component: SetupBranchView,
    name: "branches",
    layout: "/dashboard",
  },
  {
    path: "/setup/tax",
    exact: false,
    component: SetupTax,
    name: "tax",
    layout: "/dashboard",
  },
  // {
  //   path: "/setup/currency",
  //   exact: false,
  //   component: CurrenyPage,
  //   name: "settings",
  //   layout: "/dashboard",
  // },
  {
    path: "/setup/body-types",
    exact: false,
    component: BodyTypePage,
    name: "settings",
    layout: "/dashboard",
  },

  {
    path: "/policies",
    exact: false,
    component: Policies,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/payments",
    exact: false,
    component: Payments,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/taxes",
    exact: false,
    component: TaxView,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/setup/manage-tax",
    exact: false,
    component: ManageTaxes,
    name: "taxes",
    layout: "/dashboard",
  },
  {
    path: "/create-subagent",
    exact: false,
    component: CreateSubAgent,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/subagent",
    exact: false,
    component: SubAgents,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/setup/roles",
    exact: false,
    component: RolesAndPermissions,
    name: "roles",
    layout: "/dashboard",
  },
  {
    path: "/funding-approval",
    exact: false,
    component: FundingApproval,
    name: "funding",
    layout: "/dashboard",
  },
  {
    path: "/resolve-policy-creation",
    exact: false,
    component: ResolverView,
    name: "resolver",
    layout: "/dashboard",
  },
  {
    path: "/manage-policy",
    exact: false,
    component: PolicyResolverDetail,
    name: "resolver-detail",
    layout: "/dashboard",
  },
  {
    path: "/i2i/manage-drivers",
    exact: false,
    component: ManageDriver,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/manage-driver",
    exact: false,
    component: ManageDrivers,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/close-account-requests",
    exact: false,
    component: CloseAccountRequest,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/pending_commissions",
    exact: false,
    component: AgentCommission,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/payment-orders",
    exact: false,
    component: PaymentOrders,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/paid-commissions",
    exact: false,
    component: PaidCommisionss,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/payment-approvals",
    exact: false,
    component: PaymentApprovals,
    name: "settings",
    layout: "/dashboard",
  },
  // {
  //   path: "/payment-failure-logs",
  //   exact: false,
  //   component: PaymentOrderLogs,
  //   name: "financial",
  //   layout: "/dashboard",
  // },
]

export const investRoutes = [
  {
    path: "/i2i/overview",
    exact: false,
    component: Invest2InsureOverview,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/collections",
    exact: false,
    component: Invest2InsureCollection,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/invest-2-insure-payments",
    exact: false,
    component: Invest2InsurePayments,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/report",
    exact: false,
    component: Invest2InsureReport,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/withdrawals",
    exact: false,
    component: Invest2InsureWithdrawal,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/manage-drivers",
    exact: false,
    component: ManageDriver,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/manage-driver",
    exact: false,
    component: ManageDrivers,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/close-account-requests",
    exact: false,
    component: CloseAccountRequest,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/pending_commissions",
    exact: false,
    component: AgentCommission,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/payment-orders",
    exact: false,
    component: PaymentOrders,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/paid-commissions",
    exact: false,
    component: PaidCommisionss,
    name: "settings",
    layout: "/dashboard",
  },
  {
    path: "/i2i/payment-approvals",
    exact: false,
    component: PaymentApprovals,
    name: "settings",
    layout: "/dashboard",
  },
]
