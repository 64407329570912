import { useQuery } from "@apollo/client"
import {
  GET_ALL_PREMIUMS,
  GET_ALL_PREMIUMS2,
  GET_COVERTYPES_SUBCLASS,
} from "../GraphQl/queries"

interface CoverType1 {
  // Add your CoverType1 interface properties here
}

interface ClassSelection {
  id: string
  name: string
}

interface UseCoverTypesResult {
  data:
    | {
        company_subclass_covertypes: CoverType1[]
      }
    | undefined
  loading: boolean
  refetch?: () => void
}

export const useCoverTypes = (
  companyId: string | null,
  selectedClass: ClassSelection,
  selectedSubClass: ClassSelection
): UseCoverTypesResult => {
  // Case 1: Both "all"
  const {
    data: allData,
    loading: allLoading,
    refetch: allRefetch,
  } = useQuery(GET_ALL_PREMIUMS, {
    variables: {
      company_id: companyId,
    },
    skip: !(selectedClass.id === "all" && selectedSubClass.id === "allS"),
  })

  // Case 2: Class selected, SubClass "all"
  const {
    data: classData,
    loading: classLoading,
    refetch: classRefetch,
  } = useQuery(GET_ALL_PREMIUMS2, {
    variables: {
      company_id: companyId,
      class_id: selectedClass.id,
    },
    skip: !(selectedClass.id !== "all" && selectedSubClass.id === "allS"),
  })

  // Case 3: Both specific selections
  const {
    data: subClassData,
    loading: subClassLoading,
    refetch: subClassRefetch,
  } = useQuery(GET_COVERTYPES_SUBCLASS, {
    variables: {
      sub_class_id: selectedSubClass.id,
      company_id: companyId,
    },
    skip: !(selectedClass.id !== "all" && selectedSubClass.id !== "allS"),
  })

  // Determine which data and loading state to return based on conditions
  if (selectedClass.id === "all" && selectedSubClass.id === "allS") {
    return {
      data: allData,
      loading: allLoading,
      refetch: allRefetch,
    }
  }

  if (selectedClass.id !== "all" && selectedSubClass.id === "allS") {
    return {
      data: classData,
      loading: classLoading,
      refetch: classRefetch,
    }
  }

  return {
    data: subClassData,
    loading: subClassLoading,
    refetch: subClassRefetch,
  }
}
