import { Button, DialogFooter } from "@material-tailwind/react";
import React from "react";

interface ErrorLogProps {
  errors: string[];
  message: string;
  onClose: () => void;
}

const ErrorLog: React.FC<ErrorLogProps> = ({ errors, message, onClose }) => {
  return (
    <div className="p-6 bg-gray-50 rounded-md shadow-md">
      <p className="text-red-600 font-bold text-lg mb-4">{message}</p>
      
      {errors.length > 0 && (
        <ul className="list-disc list-inside text-gray-800 space-y-2 mb-6">
          {errors.map((error, index) => (
            <li key={index} className="text-sm text-red-500">{error}</li>
          ))}
        </ul>
      )}

      <DialogFooter className="flex justify-end">
        <Button
          variant="text"
          color="red"
          className="bg-red-500 text-white hover:bg-red-600 transition duration-200"
          onClick={onClose}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </div>
  );
};

export default ErrorLog;
