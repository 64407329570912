import React, { useEffect, useState } from "react";
import { CustomDeleteModal, HeaderIcon } from "../../../components";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { Button, CardBody, Input, Typography } from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import { DEFAULTPRIMARYCOLOR } from "../../../util";
import { UserType } from "../../../types/user";

const SubAgents = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleOpen = () => {
    history.push(`/dashboard/create-subagent`);
    //();
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      {" "}
      <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={() => {}}
        loading={loading}
        title="Delete selected admin"
      />
      <CustomHeader title="Sub agents" />
      <div className="w-full">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          <div className="mx-3" />

          <Button
            onClick={handleOpen}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" w-full md:w-1/4 lg:w-1/4"
          >
            Add agent
          </Button>
        </div>
      </div>
      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4 font-bold leading-none opacity-70 capitalize"
            >
              Total count{" "}
              {/* {data?.admins && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.admins?.length}
                </span>
              )} */}
            </Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {["name", "email", "type", "status", ""].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* {FAKEDATA?.map((item: AdminType, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.name + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.role}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.email}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.phoneNumber}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Tooltip content="Edit Admin">
                        <IconButton
                          onClick={() => {
                            setSelectedRow(item);
                            setUpdateModal(true);
                          }}
                          variant="text"
                        >
                          <AiOutlineEdit size={18} color="#222230" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Delete Admin">
                        <IconButton
                          onClick={() => {
                            setSelectedRow(item);
                            SetShowDeleteModal(true);
                          }}
                          variant="text"
                        >
                          <AiOutlineDelete size={18} color="red" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          </table>
          {/* {isLoading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )} */}

          {/* {!paginatedData && !isLoading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )} */}
        </CardBody>

        {/* Paginator */}
        {/* {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-gray-900">{activePage}</strong> of{" "}
                <strong className="text-gray-900">
                  {Math.ceil(
                    (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default SubAgents;
