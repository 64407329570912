import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { ADDTAXRATE, UPDATERISKCLASS } from "../../../GraphQl/mutations";
import { GETDETAILOFRISKCLASS } from "../../../GraphQl/queries";
import { DEFAULTPRIMARYCOLOR } from "../../../util";
import { UserType } from "../../../types/user";

type FormType = {
  basic_premium: string;
  total_premuim: string;
  total_amount: string;
  fee_amount: string;
  ecowas_peril: string;
};

type RateType = {
  basic_premium: string;
  ecowas_perils: string;
  fee_amount: string;
  total_amount: string;
};

const ManageRiskRate = () => {
  const getStorage = localStorage.getItem("risk");
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [selectedRisk, setRisk] = useState<{
    id: string;
    name: string;
  }>();
  const PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const { data, loading, refetch } = useQuery<{
    third_party_only_risk_class: RateType[];
  }>(GETDETAILOFRISKCLASS, {
    variables: {
      id: selectedRisk?.id,
    },
  });

  const [UpdateRiskClass, { loading: RequestLoading }] = useMutation(
    UPDATERISKCLASS,
    {
      onCompleted: (data) => {
        toast.success("Risk class Successfully updated");
        reset();
        refetch();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating updating risk class"),
    }
  );

  const filterData = (filter: RateType) => {
    const isTextMatch =
      filter?.basic_premium
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.ecowas_perils
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.fee_amount?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.basic_premium?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  // Filtered and paginated data for the current page
  const paginatedData = data?.third_party_only_risk_class
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.third_party_only_risk_class?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = (data) => {
    try {
      UpdateRiskClass({
        variables: {
          id: selectedRisk?.id,
          basic_premium: data?.basic_premium,
          ecowas_peril: data?.ecowas_peril,
          total_amount: data?.total_amount,
          fee_amount: data?.fee_amount,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating updating risk class");
    }
  };

  useEffect(() => {
    if (getStorage) {
      setRisk(JSON.parse(getStorage));
      refetch();
    }
  }, []);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Manage Risk Class" />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Type of tax", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedRisk?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {" "}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              Third Party Only Rate ({selectedRisk?.name})
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="capitalize p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Basic Premium
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("basic_premium", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Fee Amount(Total Contributions)
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("fee_amount", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Total Amount
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("total_amount", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Ecowas Perils
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("ecowas_peril", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Total Premium
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("ecowas_peril", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading
                  ? "Updating..."
                  : "Update risk class"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">Updated Risk Class</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "Basic Premium",
                      "Total Premium",
                      "Ecowas Peril",
                      "Fee Amount",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-4  text-left"
                      : "px-4 py-4 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.basic_premium}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.total_amount}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.ecowas_perils}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            GH₵ {item?.fee_amount}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.third_party_only_risk_class?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.third_party_only_risk_class?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManageRiskRate;
