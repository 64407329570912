import React from "react"
import { AmountType } from "../pages/dashboard/riplenishment"
import { useForm, SubmitHandler } from "react-hook-form"
import { useLazyQuery, useQuery } from "@apollo/client"
import { CHECKREFERENCENUM } from "../GraphQl/queries"
import { toast } from "react-toastify"

interface ConfirmationDialogProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setRefNum: (ref: string) => void
  onConfirm: () => void
  children: React.ReactNode
  total: string
  row: AmountType[]
}
type refNum = {
  reference: string
}

const ReplenishmentConfirmation = ({
  show,
  setShow,
  total,
  setRefNum,
  onConfirm,
  children,
  row,
}: ConfirmationDialogProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<refNum>()

  // const { data, error, loading } = useQuery(CHECKREFERENCENUM, {
  //   variables: {
  //     ref: "KW748382",
  //   },
  // })
  // console.log(data)
  // const countsCash = data?.cash_payment_total?.aggregate?.count
  // const countsCheque = data?.cheque_payment_total?.aggregate?.count
  // const countsDriverCount = data?.driver_total?.aggregate?.count

  const watchFields = watch()
  const [checkReference, { data: refData }] = useLazyQuery(CHECKREFERENCENUM)

  // setRefNum(watchFields.reference)
  const onSubmit: SubmitHandler<refNum> = async (data) => {
    const output = await trigger(["reference"], { shouldFocus: true })
    if (!output) return

    try {
      // Call the query with the reference from the form
      await checkReference({ variables: { ref: data?.reference } })

      // Check if all counts are 0
      const countsCash = refData?.cash_payment_total?.aggregate?.count
      const countsCheque = refData?.cheque_payment_total?.aggregate?.count
      const countsDriverCount = refData?.driver_total?.aggregate?.count
      console.log(countsCash, countsCheque, countsDriverCount)

      if (countsCash === 0 && countsCheque === 0 && countsDriverCount === 0) {
        // All counts are 0, proceed with submission
        console.log(data?.reference)
        onConfirm()
        setShow(false)
        reset()
      } else {
        // At least one count is not 0
        toast.error("Reference number already exists.")
      }
    } catch (error) {
      console.error("Error checking reference:", error)
      toast.error("An error occurred while checking the reference number.")
    }
  }

  // const handleConfirm = () => {
  //   onConfirm()
  //   setShow(false)
  // }
  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white rounded p-8 w-1/2"
          >
            <p className=" font-bold text-[1.5rem]">Replenish Here</p>
            <p className="text-[1.1rem] mb-2">
              Please enter a reference number
            </p>
            <div className=" mb-6">
              {" "}
              <div className=" mt-5 flex flex-col gap-y-4">
                <div className=" text-left mt-1 space-y-2">
                  <label htmlFor="reference" className="text-sm">
                    Reference Number
                  </label>
                  <input
                    id="messageTitle"
                    type="text"
                    {...register("reference", {
                      required: true,
                    })}
                    onChange={(e) => {
                      setRefNum(e.target.value)
                    }}
                    className={` border-black/20 border rounded-md focus:border-black text-black/70 focus:outline-none px-2 py-[0.7rem] w-full ${
                      errors.reference && "focus:border-red-400"
                    } `}
                  />
                  {/* {errors.reference && (
                    <p className=" text-red-500 text-[0.9rem]">
                      Reference number must be 20 characters maximum
                    </p>
                  )} */}
                </div>
                <div>
                  <p>
                    Do you want to replenish a sum of GHC{" "}
                    <span className="font-bold text-[1.2rem] text-green-400">
                      {total} ?
                    </span>
                    {/* <span className=" font-bold">
                      {row.length === 1 ? `for  ${row[0]?.name}` : ""}?
                    </span> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                // onClick={handleConfirm}
                disabled={isSubmitting}
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
              >
                <span>{isSubmitting ? "paying..." : "Yes"}</span>
              </button>
              <button
                onClick={() => setShow(false)}
                className="bg-red-400 px-4 text-white py-2 rounded"
              >
                No
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default ReplenishmentConfirmation
