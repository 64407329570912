import React from "react"
import { ActivationType } from "../../types/campaign"
import { Spinner, Typography } from "@material-tailwind/react"
import moment from "moment"
import { IntermediariesAgentType } from "../../types/query"
import { ResponseType } from "../../pages/dashboard/agent"
import { GETTAXPAYABLE } from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { CommissionType } from "../../pages/dashboard/financial-details/commissions"

type RequisitionType = {
  account_details: string
  amount_requisitioned: number
  created_at: string
  id: string
  insurance_company_id: string
  payee_details: string
  payee_id: string
  payee_name: string
  payee_number: string
  payee_type: string
  payment_stage: string
  provider_transaction_id: string
  policy_id: string
  rate: string
  transaction_type: string
  reference: string
  reference_amount: string
  // amount_requisitioned: string;
  transaction_name: string
}

type AccountDetails = {
  code: string
  branch: string
  bank_name: string
  acct_number: string
  account_name: string
}

const PaymentOrderDetail = ({
  content,
}: {
  content: RequisitionType | undefined
}) => {
  const parseRequisitionData = (data: RequisitionType) => {
    if(data===undefined) return null
    const parsedAccountDetails: AccountDetails = JSON.parse(
      data?.account_details
    )

    return {
      ...data,
      account_details: parsedAccountDetails,
    }
  }

  

  return (
    <div className="overflow-scroll scrolls h-full">
      {/* <div className="border border-gray-200 rounded-md py-2 px-4 mb-3">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
      </div> */}
      {/* <Typography
        className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
        color="blue-gray"
      >
        {content?.id}
      </Typography>*/}

      <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
        <div className="border border-gray-200 rounded-md py-2 px-4">
          <Typography className="text-sm text-brand-gray" color="blue-gray">
            ID
          </Typography>
          <Typography
            className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
            color="blue-gray"
          >
            {content?.id}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Amount
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(content?.amount_requisitioned ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Payee details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Name: {content?.payee_name}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Payee Type: {content?.payee_type}
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Number: {content?.payee_number}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Commision Rate
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.rate}%
          </Typography>
        </div>
      </div>
      
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Bank details
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Name: {parseRequisitionData(content!)?.account_details?.bank_name}
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Branch: {parseRequisitionData(content!)?.account_details?.branch}
          </Typography>
        </div>
      </div>
      <div className="h-20" />
    </div>
  )
}

export default PaymentOrderDetail
