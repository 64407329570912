import { Button, DialogFooter, Typography } from "@material-tailwind/react"
import React from "react"

const ViewCoverTypes = ({
  cover,
  onClose,
}: {
  cover: { name: string; id: string; description?: string | undefined; rate: number }[]
  onClose: () => void
}) => {
  return (
    <div>
      <table className=" bg-white w-full min-w-max table-auto text-left">
        <thead className="">
          <tr>
            {["Name", "Description", "Rate"].map((head) => (
              <th
                key={head}
                className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
              >
                <Typography
                  color="black"
                  className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cover?.map((item, index: number) => {
            // const isLast = index === paginatedData?.length - 1
            const classes = "px-4 py-3 border-b-2 border-blue-gray-50 text-left"

            return (
              <tr
                key={item?.id + index.toString()}
                className="hover:bg-gray-100 transition-all even:bg-gray-200"
              >
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.name}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.description || "N/A"}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {item?.rate}%
                  </Typography>
                </td>
                {/* <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.rate}%
                          </Typography>
                        </td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
      <DialogFooter className="px-10 ">
        <Button
          variant="text"
          color="white"
          className="mr-1 bg-red-200 hover:text-white hover:bg-red-200"
          onClick={onClose}
        >
          <span>Cancel</span>
        </Button>
      </DialogFooter>
    </div>
  )
}

export default ViewCoverTypes
