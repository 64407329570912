import React, { useEffect, useState, useCallback } from 'react';
import { Input } from '@material-tailwind/react';
import { debounce } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { GET_USERS_SEARCH } from '../../../GraphQl/queries';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';


// Define TypeScript types for user data
interface CompanyStaff {
    name: string;
    phone_number: string;
    staff_number: string;
    email: string;
    id: string;
    userType:string;
}

interface SubAgent {
    agent_code: string;
    agent_meta_data: string;
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    id: string;
    userType:string;
}
interface Brokers {
    agent_code: string;
    agent_meta_data: string;
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    id: string;
    userType:string;
}

interface UserData {
    company_staff: CompanyStaff[];
    company_agents: SubAgent[];
    brokers:Brokers[];
}
type User = CompanyStaff | SubAgent;
interface SearchComponentProps {
    setSelectedIntermediaryId:(id:string)=> void;
    setUserType:(userType:string)=>void;
    setSelectedUser: (user: { username: string; usercode: string }) => void;
    updateTotalAmount : (val:number)=>void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({setSelectedIntermediaryId,setUserType,setSelectedUser,updateTotalAmount}) => {
    const [searchText, setSearchText] = useState<string>('');
    const [filteredResults, setFilteredResults] = useState<User[]>([]);
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {companyId} = useSelector((state:RootState)=> state.auth)
    


    const { loading, data } = useQuery<UserData>(GET_USERS_SEARCH,{
        variables:{
            id: companyId
        }
    });

    

    useEffect(() => {
        if (!loading && data) {
            setAllUsers([...data.company_staff, ...data.company_agents, ...data.brokers]);
        }
    }, [loading, data]);


    

    

    // Debounced search function
    const handleSearch = useCallback(
        debounce((text: string) => {
            if (!data) return;

            if (text === '') {
                setSelectedIntermediaryId("")
                setFilteredResults([]);
                return;
            }
            setIsLoading(true)
            

            const allUsers = [
                ...data.company_staff.map(user => ({ ...user, userType: 'staff' })),
                ...data.company_agents.map(user => ({ ...user, userType: 'agent' })),
                ...data.brokers.map(user => ({ ...user, userType: 'broker' }))
            ];
            const lowercasedFilter = text.toLowerCase();
            const filteredData = allUsers.filter(user => {
                if ('name' in user) {
                    return (
                        user.name.toLowerCase().includes(lowercasedFilter) ||
                        user.phone_number.toLowerCase().includes(lowercasedFilter) ||
                        user.staff_number.toLowerCase().includes(lowercasedFilter) ||
                        user.email.toLowerCase().includes(lowercasedFilter)
                    );
                } else {
                    return (
                        user.first_name.toLowerCase().includes(lowercasedFilter) ||
                        user.last_name.toLowerCase().includes(lowercasedFilter) ||
                        user.phone_number.toLowerCase().includes(lowercasedFilter) ||
                        user.email.toLowerCase().includes(lowercasedFilter) ||
                        user.agent_code.toLowerCase().includes(lowercasedFilter)

                    );
                }
            });
            setFilteredResults(filteredData);
            setIsLoading(false)
        }, 300),
        [data]
    );

    

    // Handle input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setSearchText(text);
        handleSearch(text);
    };

    if (loading) return <div className='text-white'>Loading Users...</div>;

    return (
        <div className='w-full  bg-white rounded-lg gap-4 p-2'>
            <div className="flex space-x-1 w-[40%]">
                <Input
                    crossOrigin="anonymous"
                    label="Search by name, staff_number, email"
                    value={searchText}
                    onChange={handleChange}
                    icon={<i className="fas fa-search text-base"></i>}
                    className="w-full text-sm border-gray-300 rounded-lg focus:border-2 focus:border-black focus:ring-blue-500 dark:focus:bg-white dark:focus:border-blue-500"
                />
                <button
                    onClick={() => handleSearch(searchText)}
                    className="p-2 px-4 bg-black rounded-lg text-white justify-center flex"
                >
                    Search
                </button>
            </div>
            <div>
            {isLoading && <p className='text-sm'>Loading...</p>}
            {!isLoading && filteredResults.length === 0 && <p className='text-sm'>No results found</p>}
            {!isLoading && filteredResults.map((user, index) => (
                    <div key={index}>
                        <p className='text-sm cursor-pointer' onClick={() => {
                            setUserType(user?.userType)
                            setSelectedIntermediaryId(user?.id);
                            updateTotalAmount(0);
                            localStorage.setItem("int_id",user?.id)
                            setSelectedUser({
                                username: 'name' in user ? user.name : `${user.first_name} ${user.last_name}`,
                                usercode: 'agent_code' in  user ? user.agent_code : `${user.staff_number}`
                            });
                        }}>
                            {'name' in user ? user.name : `${user.first_name} ${user.last_name}`} - {'agent_code' in user ? user.agent_code : user.staff_number} - <span className="capitalize">{user.userType}</span>   
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SearchComponent;
