import React from "react"
import { ActivationType } from "../../types/campaign"
import { Spinner, Typography } from "@material-tailwind/react"
import moment from "moment"
import { IntermediariesAgentType } from "../../types/query"
import { ResponseType } from "../../pages/dashboard/agent"
import { GETTAXPAYABLE } from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { CommissionType } from "../../pages/dashboard/financial-details/commissions"

type DriverPaymentsType = {
  __typename: string
  amount: number
  company_staff:
    | {
        __typename: string
        id: string
        name: string
        phone_number: string
      }
    | string
  datecreated: string
  id: string
  sub_agent: null
  transaction_id: string
  policy:
    | {
        __typename: string
        policy_number: string
        request: {
          __typename: string
          amount: string
          registration_number_text: string
        }
      }
    | string
  premium_balance: number
}

// {
//   __typename: 'driver_invest2insure_payment',
//   amount: 20,
//   company_staff: {
//     __typename: 'company_staff',
//     id: 'cfbaea3f-8b16-458c-87b2-15219fc7e684',
//     name: 'Kofi Siriboe',
//     phone_number: '0508305891'
//   },
//   datecreated: '2024-07-12T16:24:44.243001',
//   id: 'a6997bd9-bbce-4dca-9f44-8021c2b98cb0',
//   sub_agent: null,
//   transaction_id: '455749554',
//   policy: {
//     __typename: 'policy',
//     policy_number: 'GP-10-7-24',
//     request: {
//       __typename: 'request',
//       amount: '652',
//       registration_number_text: 'Gr 124-21'
//     }
//   },
//   premium_balance: 432
// }

const PayDetails = ({
  content,
}: {
  content: DriverPaymentsType | undefined
}) => {
  

  return (
    <div className="overflow-scroll scrolls h-full">
      {/* <div className="border border-gray-200 rounded-md py-2 px-4 mb-3">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
      </div> */}
      {/* <Typography
        className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
        color="blue-gray"
      >
        {content?.id}
      </Typography>*/}

      <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
        <div className="border border-gray-200 rounded-md py-2 px-4">
          <Typography className="text-sm text-brand-gray" color="blue-gray">
            ID
          </Typography>
          <Typography
            className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
            color="blue-gray"
          >
            {content?.id}
          </Typography>
        </div>
        <div className="border border-gray-200 rounded-md py-2 px-4">
          <Typography className="text-sm text-brand-gray" color="blue-gray">
            Trasaction ID
          </Typography>
          <Typography
            className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
            color="blue-gray"
          >
            {content?.transaction_id}
          </Typography>
        </div>
        <div className="border border-gray-200 rounded-md py-2 px-4">
          <Typography className="text-sm text-brand-gray" color="blue-gray">
            Policy Number
          </Typography>
          <Typography
            className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
            color="blue-gray"
          >
            {typeof content?.policy === "string"
              ? content?.policy
              : (
                  content?.policy as {
                    __typename: string
                    policy_number: string
                    request: {
                      __typename: string
                      amount: string
                      registration_number_text: string
                    }
                  }
                )?.policy_number}
          </Typography>
        </div>
        <div className="border border-gray-200 rounded-md py-2 px-4">
          <Typography className="text-sm text-brand-gray" color="blue-gray">
            Registration Number
          </Typography>
          <Typography
            className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
            color="blue-gray"
          >
            {typeof content?.policy === "string"
              ? content?.policy
              : (
                  content?.policy as {
                    __typename: string
                    policy_number: string
                    request: {
                      __typename: string
                      amount: string
                      registration_number_text: string
                    }
                  }
                )?.request?.registration_number_text}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Amount
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(content?.amount ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Premium Ballance
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(content?.premium_balance ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        <div className="flex flex-col justify-between mb-2 gap-3">
          <div>
            <Typography className="text-sm text-brand-gray " color="blue-gray">
              Staff details
            </Typography>
            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Name:{" "}
              {typeof content?.company_staff === "string"
                ? content?.company_staff
                : (
                    content?.company_staff as {
                      __typename: string
                      id: string
                      name: string
                      phone_number: string
                    }
                  )?.name}
            </Typography>

            <Typography
              className="text-sm font-semibold  capitalize text-black"
              color="blue-gray"
            >
              Number:{" "}
              {typeof content?.company_staff === "string"
                ? null
                : (
                    content?.company_staff as {
                      __typename: string
                      id: string
                      name: string
                      phone_number: string
                    }
                  )?.phone_number}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Date Created
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {moment(content?.datecreated).format("LLL")}
          </Typography>
        </div>
      </div>

      {/* <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Bank details
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Name: {parseRequisitionData(content!)?.account_details?.bank_name}
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Branch: {parseRequisitionData(content!)?.account_details?.branch}
          </Typography>
        </div>
      </div> */}
      <div className="h-20" />
    </div>
  )
}

export default PayDetails
