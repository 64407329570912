import { Button, DialogFooter } from "@material-tailwind/react"
import React, { useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import { SubmitHandler, useForm } from "react-hook-form"
import {
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react"
import { useMutation, useQuery } from "@apollo/client"
import { SubClassType } from "../business-class/manage"
import { BusinessClassType } from "../../../types/class"
import { UPDATE_COMMISION } from "../../../GraphQl/mutations"
import {
  GET_COMMIISSION_RATES,
  GETBUSINESSCLASSES,
  GETSUBCLASSES,
} from "../../../GraphQl/queries"
import { DEFAULTPRIMARYCOLOR, intermediary_types } from "../../../util"
import { UserType } from "../../../types/user"

type PinType = {
  id: string | undefined
  subclass_id: string | undefined
  covertype: string | undefined
  class_id: string | undefined
  rate: string | undefined
  desc: string | undefined
  usertype: string | undefined
}

type FormType = {
  id?: string
  class: string
  subclass: string
  cover: string
  rate: string
  intermediary: string
  cover_type: string
  description: string
  user_type?: string
}
type RateType = {
  id: string
  class: string
  subclass: string
  user_type: string
  rate: string
  description: string
  cover_type: string
}

const UpdateCommisionRates = ({
  selectedRow,
  close,
  setOpenOtp,
}: {
  selectedRow: RateType | undefined
  close: () => void
  setOpenOtp: (value: boolean) => void
}) => {
  const [formData, setFormData] = useState<PinType | null>(null)

  const [selectedClass, setSlectedClass] = useState<string | null>("")

  console.log(selectedRow)
  const {
    register: updateRegister,
    watch,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating, errors: updateError },
    setValue,
  } = useForm<FormType>()
  const [
    updateCommissionsRates,
    { loading: LoadingUpdate, error: ErrorUpdate, data: Data },
  ] = useMutation(UPDATE_COMMISION)

  const watchClass = watch("class")

  const {
    data: subclasses,
    loading: loadingSubClass,
    refetch: refetchSubClass,
  } = useQuery<{
    subclass: SubClassType[]
  }>(GETSUBCLASSES, {
    variables: {
      class_id: watchClass,
    },
  })

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[]
  }>(GETBUSINESSCLASSES)
  const {
    data: singleRate,
    refetch: fetchRates,
    loading: loadingSingleRate,
  } = useQuery(GET_COMMIISSION_RATES, {
    variables: {
      id: selectedRow?.id,
    },
  })
  // console.log(singleRate)
  const handleUpdate: SubmitHandler<FormType> = async (data) => {
    // console.log(data)
    // console.log(selectedRow?.id)
    // console.log(selectedClass)
    if (data?.class === "Select class") {
      return toast.error("Select a valid class")
    }

    if (data?.subclass === "Select subclass") {
      return toast.error("Select a valid subclass")
    }
console.log({
  id: selectedRow?.id,
  class_id:
    data?.class === "Home Owners"
      ? "fc56a437-4a7e-4b1c-820a-2bbcb37c8cda"
      : data?.class === "Motor Insurance"
      ? "7a741a9f-f315-4c0f-aca1-c76fe1da1971"
      : data?.class === "Personal Accidents"
      ? "54a4e9cb-4d56-4de2-9c50-5f99db59ee61"
      : data?.class,
  covertype: data.cover_type,
  desc: data.description,
  rate: data.rate,
  usertype:
    data.intermediary === "All"
      ? "00000000-0000-0000-0000-000000000000"
      : data.intermediary,
  subclass_id:
    data.subclass === "All"
      ? "00000000-0000-0000-0000-000000000000"
      : data.subclass,
})
    try {
      updateCommissionsRates({
        variables: {
          id: selectedRow?.id,
          class_id:
            data?.class === "Home Owners"
              ? "fc56a437-4a7e-4b1c-820a-2bbcb37c8cda"
              : data?.class === "Motor Insurance"
              ? "7a741a9f-f315-4c0f-aca1-c76fe1da1971"
              : data?.class === "Personal Accidents"
              ? "54a4e9cb-4d56-4de2-9c50-5f99db59ee61"
              : data?.class,
          covertype: data.cover_type,
          desc: data.description,
          rate: data.rate,
          usertype:
            data.intermediary === "All"
              ? "00000000-0000-0000-0000-000000000000"
              : data.intermediary,
          subclass_id:
            data.subclass === "All"
              ? "00000000-0000-0000-0000-000000000000"
              : data.subclass,
        },
      })

      toast.success("updated successfully")
      resetUpdate()
      fetchRates()
      refreshClass()
      close()
      window.location.reload()
    } catch (error) {
      toast.error("error updatting record")
    }
    // setFormData(getData)
    // console.log("Form submitted successfully", data)
    // setOpenOtp(true)
  }

  const handlePinSubmit = async () => {
    console.log("Form submitted successfully", formData)
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)

      setColors(data.colors)
    }
  }, [])
  return (
    <>
      <ToastContainer />
      <div className=" rounded-full border">
        <div
          style={{
            background:
              colors?.length! > 1
                ? `${colors![0]?.toLowerCase()}`
                : `${DEFAULTPRIMARYCOLOR}`,
          }}
          className=" p-3 rounded-md"
        >
          <Typography className="text-xl text-white">
            Create Commission Rate
          </Typography>
        </div>
        <form
          onSubmit={handleUpdateSubmit(handleUpdate)}
          className="p-3 capitalize bg-white rounded-b-xl"
        >
          <div className="mt-5 flex flex-col items-end justify-end">
            <div className="w-full max-w-2xl ">
              <div className="mb-6 flex flex-col gap-2">
                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Class of Business
                  </Typography>

                  <select
                    id="Class"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={selectedRow?.class}
                    // value={selectedClass as string}
                    {...updateRegister("class", { required: true })}
                    onChange={(e) => {
                      setSlectedClass(
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-code"
                        )
                      )
                    }}
                  >
                    <option value={selectedRow?.class}>
                      {selectedRow?.class}
                    </option>
                    {classes?.business_classes?.map((data) => (
                      <option
                        data-code={data?.name}
                        label={data?.name}
                        value={data?.id}
                      >
                        {data?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Subclass
                  </Typography>

                  <select
                    id="subclass"
                    defaultValue={selectedRow?.subclass}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...updateRegister("subclass", { required: true })}
                  >
                    {/* <option>Select subclass</option> */}
                    {subclasses?.subclass?.map((data) => (
                      <option label={data?.name} value={data?.id}>
                        {data?.name}
                      </option>
                    ))}
                    <option
                      value={"00000000-0000-0000-0000-000000000000"}
                      label="All"
                    >
                      All
                    </option>
                  </select>
                </div>
                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    User/ Intermediary Type
                  </Typography>
                  <select
                    id="intermediary"
                    defaultValue={
                      selectedRow?.user_type ===
                      "00000000-0000-0000-0000-000000000000"
                        ? "All"
                        : selectedRow?.user_type
                    }
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...updateRegister("intermediary", {
                      required: true,
                    })}
                  >
                    <option
                      label={"All"}
                      value={"00000000-0000-0000-0000-000000000000"}
                    >
                      All
                    </option>

                    {intermediary_types
                      ?.slice(0, 3)
                      ?.map((intermediary_type) => (
                        <option
                          label={intermediary_type?.name}
                          value={intermediary_type?.code}
                        >
                          {intermediary_type?.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Cover Type
                  </Typography>
                  <select
                    id="cover_type"
                    defaultValue={selectedRow?.cover_type}
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    {...updateRegister("cover_type", { required: true })}
                  >
                    {selectedClass === "" &&
                    selectedRow?.class === "Motor Insurance"
                      ? [
                          { name: "Comprehensive", code: "COMP" },
                          { name: "Third Party Only", code: "TP" },
                          {
                            name: "Third Party Fire and Theft",
                            code: "TFT",
                          },
                          { name: "All", code: "All" },
                        ]?.map((intermediary_type) => (
                          <option
                            label={intermediary_type?.name}
                            value={intermediary_type?.name}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))
                      : selectedClass === "Motor Insurance"
                      ? [
                          { name: "Comprehensive", code: "COMP" },
                          { name: "Third Party Only", code: "TP" },
                          {
                            name: "Third Party Fire and Theft",
                            code: "TFT",
                          },
                          { name: "All", code: "All" },
                        ]?.map((intermediary_type) => (
                          <option
                            label={intermediary_type?.name}
                            value={intermediary_type?.name}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))
                      : selectedRow?.class === "Motor Insurance" &&
                        selectedClass === "Motor Insurance"
                      ? [
                          { name: "Comprehensive", code: "COMP" },
                          { name: "Third Party Only", code: "TP" },
                          {
                            name: "Third Party Fire and Theft",
                            code: "TFT",
                          },
                          { name: "All", code: "All" },
                        ]?.map((intermediary_type) => (
                          <option
                            label={intermediary_type?.name}
                            value={intermediary_type?.name}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))
                      : selectedRow?.class === "Motor Insurance" &&
                        selectedClass !== "Motor Insurance"
                      ? [{ name: "All", code: "All" }]?.map(
                          (intermediary_type) => (
                            <option
                              label={intermediary_type?.name}
                              value={intermediary_type?.name}
                            >
                              {intermediary_type?.name}
                            </option>
                          )
                        )
                      : [{ name: "All", code: "All" }]?.map(
                          (intermediary_type) => (
                            <option
                              label={intermediary_type?.name}
                              value={intermediary_type?.name}
                            >
                              {intermediary_type?.name}
                            </option>
                          )
                        )}
                  </select>
                </div>
                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    rate
                  </Typography>
                  <Input
                    defaultValue={selectedRow?.rate}
                    size="lg"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...updateRegister("rate", { required: true })}
                  />
                </div>

                <div className="w-full my-2 flex items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Description
                  </Typography>
                  <Textarea
                    size="lg"
                    defaultValue={selectedRow?.description}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...updateRegister("description", {
                      required: false,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button variant="text" color="red" onClick={close} className="mr-1">
              <span>Cancel</span>
            </Button>
            <Button
              type="submit"
              className="flex items-center justify-center"
              variant="gradient"
              color="green"
            >
              {updating ? (
                <Spinner className="h-4 w-4 text-white" />
              ) : (
                <span>Update</span>
              )}
            </Button>
          </DialogFooter>
        </form>
      </div>
    </>
  )
}

export default UpdateCommisionRates
