export const Policies_Table_Head = [
  "PolicyHolder",
  "policy number",
  "registration number",
  "premium payable",
  "premium paid",
  "premium balance",
  "status",
  // "datecreated",
  "policy start Date",
  "policy end Date",
 
  "actions",
]
export const Payments_Table_Head = [
  "reference number",
  "amount",
  "policy number",
  "date paid",
  "staff/agent",
  "premium balance",
]
