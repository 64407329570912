import { useQuery } from "@apollo/client"
import { useDebounceSearch } from "./useDebounce"
import { useSelector } from "react-redux"
import { RootState } from "../store/store"
import { GETCOMMISSIONSEARCH, GETCOMMISSIONSEARCHAMOUNT, SEARCHREPORTS, SEARCHREPORTSAMOUNT } from "../GraphQl/queries"

export const useSearch = (QUERY: any, searchText: string) => {
  const { companyId } = useSelector((state: RootState) => state.auth)
  const search = useDebounceSearch(searchText)
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      company_id: companyId,
      searchText: `%${search.toLowerCase()}%`,
    },
  })
  return { loading, error, data }
}

export default function useSearchReports(searchInput: string) {
  const isNumber = !isNaN(parseFloat(searchInput))

  const searchText = isNumber ? "" : `%${searchInput}%`
  const searchAmount = isNumber ? parseFloat(searchInput) : 0

  const {
    loading: loadingReports,
    error: errorReports,
    data: reportData,
  } = useQuery(SEARCHREPORTS, {
    variables: {
      searchText: searchText,
      jsonFilter: "",
    },
  })

  const {
    loading: loadingAmount,
    error: errorAmount,
    data: amountData,
  } = useQuery(SEARCHREPORTSAMOUNT, {
    variables: {
      searchAmount: searchAmount,
    },
  })

  return {
    loading: loadingReports || loadingAmount,
    error: errorReports || errorAmount,
    data: isNumber ? amountData : reportData,
  }
}

export function useSearchCollections(searchInput: string) {
  const isNumber = !isNaN(parseFloat(searchInput))
  const searchText = isNumber ? "" : `%${searchInput}%`
  const searchAmount = isNumber ? parseFloat(searchInput) : 0

  const {
    loading: loadingCollection,
    error: errorCollection,
    data: collectionsData,
  } = useQuery(GETCOMMISSIONSEARCH, {
    variables: {
      searchText: searchText,
      jsonFilter: "",
    },
  })

  const {
    loading: loadingAmount,
    error: errorAmount,
    data: amountData,
  } = useQuery(GETCOMMISSIONSEARCHAMOUNT, {
    variables: {
      searchAmount: searchAmount,
    },
  })

  return {
    loading: loadingCollection || loadingAmount,
    error: errorCollection || errorAmount,
    data: isNumber ? amountData : collectionsData,
  }
}
