import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice'
import replenishmentReducer from './replenishmentSlice'

const store = configureStore({
    reducer:{
        auth:authReducer,
        replenishment:replenishmentReducer
    }
})

export type RootState= ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;