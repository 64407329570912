import React from "react"
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react"
import { ToastContainer } from "react-toastify"

export function DriverModal({
  children,
  onClose,
  open,
  title,
  size,
  focus,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  title?: React.ReactNode
  focus?: string
  size?: string | "xl" | any
  onConfirm?: () => void
}) {
  // const [open, setOpen] = React.useState(false)
  // const handleOpen = () => setOpen(!open)

  return (
    <>
      {/* <Button onClick={handleOpen} variant="gradient">
        Open Dialog
      </Button> */}
      <Dialog open={open} handler={() => {}} size={size} className=" w-full">
        <ToastContainer />
        <DialogHeader>{title}</DialogHeader>
        <DialogBody>{children}</DialogBody>
        {focus === "message" ? null : (
          <DialogFooter>
            <Button
              variant="gradient"
              color="green"
              className="flex items-center justify-center w-full "
              onClick={onClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        )}
      </Dialog>
    </>
  )
}
