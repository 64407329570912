import React from "react"
import { AdminType } from "../../types/admin"
import { Typography } from "@material-tailwind/react"

interface CompanyStaff {
  id: string
  name: string
  phone_number_one: string
}
interface Driver {
  driver_code: string
  id: string
  full_name: string
  phone_number_1: string
  phone_number_2: string
  gender: string
  email: string
  address: string
  id_card_type: string
  balance: string
}
interface BusinessClassByBusinessClass {
  id: string
  name: string
}
interface SubClass {
  id: string
  name: string
}
interface Request {
  registration_number_text: string
  premium_amount: string
  businessClassByBusinessClass: BusinessClassByBusinessClass
  sub_class: SubClass
}
interface Policy {
  policy_number: string
  request: Request
}
interface ResponseType {
  policy_number: string;
  id: string;
  datecreated: string;
  request: {
    registration_number_text: string;
    policyholder: {
      id: string;
      last_name: string;
      first_name: string;
    };
    start_date: string;
    end_date: string;
    premium_amount: string;
    businessClassByBusinessClass: {
      id: string;
      name: string;
    };
    sub_class: {
      id: string;
      name: string;
    };
  };
  driver_invest2insure_payments: Array<{
    amount: number;
    driver: {
      full_name: string;
      driver_code: string;
      id: string;
      phone_number_1: string;
      phone_number_2: string;
      balance: string;
      gender: string;
      email: string
      address: string
      id_card_type: string
    };
    sub_agent: null | {
      first_name: string;
      last_name: string;
      phone_number: string;
    };
    company_staff: {
      id: string;
      name: string;
      phone_number_one: string;
    };
  }>;
}

const InvestToInsureDetail = ({
  content,
}: {
  content?: ResponseType | undefined
}) => {

  return (
    <div className="overflow-y-auto h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.id}
        </Typography>
      </div>
      {/* <div className="border border-gray-200 rounded-md py-2 px-4 mt-2">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          Trsaction ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.transaction_id}
        </Typography>
      </div> */}
      <div className="border border-gray-200 rounded-md py-2 px-4 mt-2">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          Policy Number
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.policy_number}
        </Typography>
      </div>
      <div className="border border-gray-200 rounded-md py-2 px-4 mt-2">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          Registration Number
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.request?.registration_number_text}
        </Typography>
      </div>

      <Typography variant="h5" className="text-lg mt-10 mb-3" color="blue-gray">
        Driver details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Name
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.full_name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Driver code
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.driver_code}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Amount
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.request?.premium_amount ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Balance
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.driver_invest2insure_payments[0].driver?.balance ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              gender
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver_invest2insure_payments[0].driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.gender}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver_invest2insure_payments[0].driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.phone_number_1}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone
            </Typography>
            <Typography
              as={"a"}
              href={`tel:${content?.driver_invest2insure_payments[0].driver?.phone_number_2}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.phone_number_2}
            </Typography>
          </div>
        </div>
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Email
            </Typography>
            <Typography
              as={"a"}
              href={`mailto:${content?.driver_invest2insure_payments[0].driver?.email}`}
              className="text-sm font-semibold  mt-2"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.email}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Address
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.address}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              ID card type
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_invest2insure_payments[0].driver?.id_card_type}
            </Typography>
          </div>
        </div>
        <div className="h-20" />
      </div>
    </div>
  )
}

export default InvestToInsureDetail
