import { Button, Input, Spinner, Typography } from "@material-tailwind/react"
import { useForm, SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { BASEURL } from "../../../util"
import Logo from "../../../assets/logo.png"
import { GET_ROLE_PERMISSIONS } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { useState } from "react"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import { useSelector,useDispatch } from "react-redux"
import { setAuthState } from "../../../store/authSlice"
import { RootState } from "../../../store/store"

type LoginType = {
  otp: string
}

export default function EnterOtp() {
  const dispatch=useDispatch()
  const usertype=useSelector((state:RootState)=> state.auth.usertype);
  const userId = useSelector((state:RootState)=> state.auth.userId);
  const options = [
    { value: "company_administrator", label: "company administrator" },
    { value: "staff", label: "staff" },
  ]

  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const history = useHistory()
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>()

  const otp = watch("otp")
  // const usertype = localStorage.getItem("usertype")

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    // const payload = {
    //   otp: data.otp,
    //   user_id: localStorage.getItem("usser_id"),
    //   user_type: localStorage.getItem("usertype"),
    // }

    const payload = {
      otp: data.otp,
      user_id: userId,
      user_type: usertype,
    };

    
    
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(payload),
      }
      const req = await fetch(
        `${process.env.REACT_APP_BASE_URL}/company-confirm-otp`,
        options
      )
      const res = await req.json()
      // if (req.ok) {
        
      //   if (res?.access_token) {
      //     const { data } = res
      //     localStorage.setItem("role_id", data?.role_id)
      //     localStorage.setItem("company_id", data?.company_id)
      //     localStorage.setItem("token", res?.access_token)
      //     localStorage.setItem("expires_at", res?.expires_at)
      //     localStorage.setItem("show_i2i", data?.has_invest_2_insure)
      //     if (usertype !== "staff") {
      //       history.push("/dashboard")
      //       localStorage.setItem("user", JSON.stringify(data))
      //     } else {
      //       let company_details = data?.company_details
      //       let formatedData = {
      //         ...data,
      //         ...company_details,
      //       }
      //       // localStorage.setItem("sidebarOpen", "i")
      //       localStorage.setItem("user", JSON.stringify(formatedData))
      //       history.push("/dashboard")
      //     }
      //   } else {
      //     toast.error(res?.data ?? "An error occured")
      //   }
      // } else {
      //   toast.error(res?.data ?? "Access Denied")
      // }

      if (req.ok) {
        if (res?.access_token) {
          const { data } = res;
        
          dispatch(setAuthState({
            usertype: usertype,
            userId: userId,
            roleId: data?.role_id,
            companyId: data?.company_id,
            token: res?.access_token,
            expiresAt: res?.expires_at,
            showI2I: data?.has_invest_2_insure,
            user: data 
          }));

          if (usertype !== "staff") {
            history.push("/dashboard");
          } else {
            let company_details = data?.company_details;
            let formattedData = {
              ...company_details,
              ...data,
            };
            dispatch(setAuthState({
              usertype: usertype,
              userId: userId,
              roleId: data?.role_id,
              companyId: data?.company_id,
              token: res?.access_token,
              expiresAt: res?.expires_at,
              showI2I: data?.has_invest_2_insure,
              user: formattedData 
            }));
            history.push("/dashboard");
          }
        } else {
          toast.error(res?.data ?? "An error occurred");
        }
      } else {
        toast.error(res?.data ?? "Access Denied");
      }

    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <main className="w-full h-screen bg-black grid grid-cols-1 lg:grid-cols-2">
      <main className="auth flex items-center justify-center h-screen bg-white">
        <div className="flex  flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            {/* <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          /> */}
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter OTP sent to phone
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Enter OTP
                </label>
                <div className="mt-2">
                  <Input
                    crossOrigin={""}
                    id="otp"
                    error={!!errors.otp}
                    type="text"
                    label="OTP"
                    size="lg"
                    className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                    {...register("otp", { required: true })}
                  />
                  {errors.otp && (
                    <span className="text-left mt-2 text-xs text-brand-primary">
                      Otp is required
                    </span>
                  )}
                </div>
              </div>

              <div>
                <Button
                  variant="gradient"
                  size="lg"
                  type="submit"
                  style={{
                    background: "#ED7C31",
                  }}
                  className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
                >
                  {isSubmitting ? (
                    <Spinner className="w-5 h-5 text-white" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </form>

            <p className="mt-5 text-center text-sm text-gray-500">
              Take me back to?{" "}
              <a
                href="/"
                className="font-semibold leading-6 text-brand-primary hover:text-brand-primary"
              >
                Login
              </a>
            </p>
          </div>
        </div>
      </main>
      <div className="hidden lg:flex h-full flex-col items-center justify-center text-center">
        <img
          className="w-96"
          src="https://www.cyfe.com/wp-content/uploads/2019/10/Sales-Dashboards.png.webp"
        />
        <Typography className="mt-10 w-52 text-md font-semibold capitalize text-white">
          Your number one insurance company portal
        </Typography>
      </div>
    </main>
  )
}
