import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState, useMemo } from "react"
import {
  CustomDeleteModal,
  CustomModal,
  DetailDrawer,
  HeaderIcon,
  RenderAdminDetail,
} from "../../../components"
import useSWR from "swr"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai"
import { AdminType, UpdateType } from "../../../types/admin"
import { ToastContainer, toast } from "react-toastify"
import { UserType } from "../../../types/user"
import { SubmitHandler, useForm } from "react-hook-form"
import { AddAdminModal } from "../../../components/forms/add-admin"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { BiBlock, BiSearch } from "react-icons/bi"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import moment from "moment"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { COMMISSIONAGGREGATE } from "../../../GraphQl/queries"
import { CommissionAggregateType } from "../../../types/query"
import { useQuery } from "@apollo/client"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

type ResponseType = {
  "Transaction Ref": string
  "Transaction Id": string
  "Transaction Date": string
  "Policy Number": string
  Policyholder: string
  Payee: string
  "Class/Subclass": string
  "Basic Premium": number
  "Commission Rate": string
  "Gross Commission": number
  "Tax Amount": number
  "Net Commission": number
  RiskID: string
}

const PaidCommissions = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [token, setToken] = useState()
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<ResponseType>()
  const [commissions, setCommissions] = useState<ResponseType[]>([])

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const PAGE_SIZE = 10

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token: stateToken,
  } = useSelector((state: RootState) => state.auth)

  const handleRowClick = (item: ResponseType) => {
    setSelectedRow(item)
    setOpenDrawer(true)
  }

  const {
    data: commissionAggregate,
    loading: loadingAggregate,
    refetch,
  } = useQuery<CommissionAggregateType>(COMMISSIONAGGREGATE, {
    variables: {
      id: companyId,
      code: "BDD",
    },
  })
  // console.log(commissionAggregate)

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (admin: ResponseType) => {
    const isTextMatch =
      admin?.["Transaction Ref"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policy Number"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policyholder"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Class/Subclass"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Commission Rate"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const fetchPaidComissions = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get-paid-commissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            usertype: DASHBOARD_USERTYPE.company,
            company_id: companyId,
            commission_type: "other",
          }),
        }
      )
      const req = await res.json()
      // console.log(req)

      if (res.ok) {
        if (req?.error === 404) {
          setCommissions([])
        } else {
          setCommissions(req?.data)
          console.log(req)
        }
      }
    } catch (error) {
      toast.error("Failed to fetch commissions. Refresh to try again")
    } finally {
      setLoading(false)
    }
    //-------------------------
  }
  //-------------------------

  // console.log(commissions)

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totoalItems = commissions?.filter(filterData)
  // const paginatedData = totoalItems?.slice(startIndex, endIndex)

  const endOffset = currentPage + itemsPerPage
  const paginatedData = totoalItems?.slice(currentPage, endOffset)

  // console.log(paginatedData)

  //------------------
  // const paginatedData = commissions
  //   ?.filter(filterData)
  //   ?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)

  // const nextPage = () => {
  //   if (
  //     (activePage - 1) * PAGE_SIZE <
  //     (commissions?.filter(filterData).length || 0)
  //   ) {
  //     setActivePage(activePage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1)
  //   }
  // }

  useEffect(() => {
    fetchPaidComissions()
  }, [])

  const handlePrint = () => {
    if (paginatedData.length === 0) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
      <html>
        <head>
          <title>Collection of Paid Commissions</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              text-align: center;
              font-size: 24px;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 10px;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          <h1>Paid Commissions</h1>
          <table>
            <thead>
              <tr>
                <th>Transaction Ref</th>
                <th>Transaction Date</th>
                <th>Policy Number</th>
                <th>Payee</th>
                <th>Class/Subclass</th>
                <th>Basic Premium</th>
                <th>Commission Rate</th>
                <th>Gross Commission</th>
                <th>Tax Amount</th>
                <th>Net Commission</th>
              </tr>
            </thead>
            <tbody>
             ${paginatedData
               .map(
                 (rowData) => `<tr>
                <td>${rowData["Transaction Ref"]}</td>
                <td>${moment(rowData["Transaction Date"]).format("LLL")}</td>
                <td>${rowData["Policy Number"]}</td>
                <td>${rowData["Policyholder"]}</td>
                <td>${rowData["Class/Subclass"]}</td>
                <td>GHS ${(
                  (rowData?.["Basic Premium"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${rowData["Commission Rate"]}%</td>
                <td>GHS ${(
                  (rowData?.["Gross Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Tax Amount"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Net Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              </tr>
              `
               )
               .join(" ")}
            </tbody>
          </table>
        </body>
      </html>
    `

    // Write the content to the new window
    printWindow!.document.write(printContent)

    // Close the document after printing
    printWindow!.document.close()

    // Print the window
    printWindow!.print()
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      {/* <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      /> */}

      {/* <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      /> */}

      <CustomHeader title="Paid commssions" />
      <div className="mb-5 w-full grid-cols-1 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-yellow rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer h-full">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  commissionAggregate?.all_types_of_commissios?.length
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                Commission Types
              </Typography>
            </div>
          </div>
        </Card>

        {commissionAggregate?.all_types_of_commissios?.map((item) => (
          <Card
            key={item?.commission_id}
            className="bg-brand-info rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer"
          >
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GHS " +
                    ((item?.total_amount as any) ?? 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  {item?.commission_name}
                </Typography>
              </div>
            </div>
          </Card>
        ))}
      </div>
      {/* <div className="w-full mb-10">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          <div className="mx-3" />

          <Button
            onClick={handleOpen}
              className="w-full md:w-36 lg:w-36"

 style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
          >
            Add premium
          </Button>
        </div>
      </div> */}
      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Total paid commissions
              {commissions && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {commissions?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-3 bg-white w-full">
            {/* <div className="w-full">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div> */}
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none w-full"
            >
              <div className="w-full  flex flex-col gap-y-4 ">
                <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row">
                  <Button
                    onClick={handlePrint}
                    size="sm"
                    color="green"
                    className=" px-10 text-[1.0rem]"
                  >
                    Print
                  </Button>
                </div>
                <div className="p-3 flex gap-x-4 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                  <div className="w-full ">
                    <Input
                      crossOrigin="anonymous"
                      value={searchText}
                      onChange={handleSearch}
                      label="Search"
                      icon={<BiSearch className="h-5 w-5" />}
                    />
                  </div>

                  <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">
                        From
                      </label>
                      <Input
                        type="datetime-local"
                        value={startDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleStartDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">To</label>
                      <Input
                        type="datetime-local"
                        value={endDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleEndDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              {/* //min-w-max  */}
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "Transaction Ref",
                      "Provider transaction id",
                      "Policy No.",
                      "Class/Subclass",
                      "Risk ID",
                      "Premium",
                      "Rate",
                      "Gross Commission",
                      "Tax",
                      "Net Commission",
                      "Payee",
                      "Transaction Date",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "p-4  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.["Policy Number"] + index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer h-[4.0rem]"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Ref"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Id"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Policy Number"]}
                          </Typography>
                        </td>
                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.["Policy Number"]}
                      </Typography>
                    </td> */}
                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {`${item?.Policyholder}`}
                      </Typography>
                    </td> */}
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.["Class/Subclass"]}
                          </Typography>
                        </td>
                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS {item?.["Basic Premium"]}
                      </Typography>
                    </td> */}
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.RiskID}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS {item?.["Basic Premium"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.["Commission Rate"]}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Gross Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Tax Amount"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Net Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.Policyholder}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.["Transaction Date"]).format("LLL")}
                          </Typography>
                        </td>

                        {/* <td className={classes}>
                      <Button
                        onClick={() => handlePrint(item)}
                        size="sm"
                        color="green"
                        className="w-full flex items-center justify-center text-[11px] font-normal capitalize"
                      >
                        Print
                      </Button>
                    </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedData && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {paginatedData?.length! === 0 && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {paginatedData && paginatedData?.length !== 0 && !loading && (
          // <GeneralPagination
          //   totalItems={totoalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />

          <PerPageAndPagination
          total={totoalItems?.length! ?? 0}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={itemsPerPage}
          setPerPage={setItemsPerPage}
        />
        )}

        {/* Paginator */}
        {/* {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (commissions?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (commissions?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )} */}
      </div>
    </>
  )
}

export default PaidCommissions
