import React, {
  useEffect,
  useMemo,
  useState,
  KeyboardEventHandler,
} from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Avatar,
  Button,
  IconButton,
  Input,
  Spinner,
  Tab,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
  tab,
} from "@material-tailwind/react"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { UserType } from "../../../types/user"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import {
  BASEURL,
  DEFAULTPRIMARYCOLOR,
  DEFAULTSECONDARYCOLOR,
} from "../../../util"
import { useHistory } from "react-router-dom"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import CreatableSelect from "react-select/creatable"
import { useMutation } from "@apollo/client"
import { UPDATECOMPANYCOLORS } from "../../../GraphQl/mutations"
import { useSelector,useDispatch } from "react-redux"
import { RootState } from "../../../store/store"
import { clearAuthState } from "../../../store/authSlice"

type PasswordType = {
  old_password: string
  new_password: string
  confirm_password: string
}

type ResetPinType = {
  old_pin: string
  new_pin: string
}

type SetPinType = {
  new_pin: string
  confirm_pin: string
}

interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

const Settings = () => {
  const history = useHistory()
  const tabs = ["Change password", "Change App colors", "Set Pin"]
  const staffTabs = ["Change password", "Set Pin"]
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [selectedFile, setSelectedFile] = useState<File | null | any>(null)
  const [passwordType2, setPasswordType2] = useState("password")
  const [passwordType, setPasswordType] = useState("password")
  const dispatch=useDispatch()
  const [passwordType3, setPasswordType3] = useState("password")
  const [inputValue, setInputValue] = React.useState("")
  const [value, _setValue] = React.useState<readonly Option[]>([])
  const [isMasked, setIsMasked] = useState(true)
  const {
    companyId,
    roleId,
    usertype:stateUserType,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const [user, setUser] = useState<UserType>({
    id: "",
    name: "",
    phone: "",
    email: "",
    pin: "",
    is_first_time: false,
    user_type: "",
    company_id: "",
    role_id: "",
    colors: [],
  })

  const usertype = stateUserType
  const tabsToRender = usertype === "staff" ? staffTabs : tabs
  const numType = usertype === "staff" ? 1 : 2
  const components = {
    DropdownIndicator: null,
  }
  
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        _setValue((prev) => [...prev, createOption(inputValue)])
        setInputValue("")
        event.preventDefault()
    }
  }

  const handleFocus = () => {
    setIsMasked(false)
  }

  const handleBlur = (e: any) => {
    if (!e.target.value) {
      setIsMasked(true)
    }
  }

 
  

  const [updateCompanyColors, { loading: RequestLoading }] = useMutation(
    UPDATECOMPANYCOLORS,
    {
      onCompleted: (data) => {
        toast.success("Colors updated succesfully")
      },
      onError: (error) =>
        toast.error(error?.message ?? "Colors updated succesfully"),
    }
  )

  const toggleType1 = () =>
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password")

  const toggleType2 = () =>
    passwordType2 === "password"
      ? setPasswordType2("text")
      : setPasswordType2("password")

  const toggleType3 = () =>
    passwordType3 === "password"
      ? setPasswordType3("text")
      : setPasswordType3("password")

  type ColorType = string

  function convertColorsToLabelValueArray(colors: ColorType[]) {
    return colors.map((color) => ({ label: color, value: color }))
  }

  type ColorObject = { label: string; value: string }

  function convertLabelValueArrayToColors(
    colorObjects: readonly ColorObject[]
  ): string[] {
    return colorObjects.map((colorObject) => colorObject.value)
  }

  const defaultValues = useMemo(() => {
    return {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
      is_first_time: user?.is_first_time,
      user_type: user?.user_type,
      company_id: user?.company_id,
      role_id: user?.role_id,
    }
  }, [user])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PasswordType>()
  const {
    register: pinRegister,
    handleSubmit: restPinSubmit,
    formState: { errors: resetError, isSubmitting: resetISubmittig },
  } = useForm<ResetPinType>()

  const {
    register: setpinRegister,
    handleSubmit: setPinSubmit,
    formState: { errors: setError, isSubmitting: setISubmittig },
  } = useForm<SetPinType>()

  const changePassword: SubmitHandler<PasswordType> = async (data) => {
    if (data?.new_password !== data?.confirm_password) {
      return toast.warn("New password and confirm password does not match")
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            old_password: data?.old_password,
            new_password: data?.new_password,
            usertype: DASHBOARD_USERTYPE.company,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data)
        }
        toast.success(req?.message ?? "Password has been changed succesfully")
        dispatch(clearAuthState());
        setTimeout(() => {
          history.replace("/");
          //()
        }, 1500)
      } else {
        toast.info(req?.data ?? "Something went wrong")
      }
    } catch (error) {
      toast.error("Error updating password")
    }
  }
  const resetPin: SubmitHandler<ResetPinType> = async (data) => {
    const num = Number(data.new_pin)
    if (!num) {
      toast.error("PIN must be a number")
      return
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/company-update-pin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            pin: data?.new_pin,
            oldpin: data?.old_pin,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data)
        }
        toast.success(req?.message ?? "Pin successfully resetted")
        dispatch(clearAuthState());
        setTimeout(() => {
          history.replace("/");
          //()
        }, 1500)
      } else {
        toast.info(req?.data ?? "Something went wrong")
      }
    } catch (error) {
      toast.error("Error resetting pin")
    }
  }

  const setPin: SubmitHandler<SetPinType> = async (data) => {
    if (data?.new_pin !== data?.confirm_pin) {
      return toast.warn("The two PINS do not match")
    }

    const num = Number(data.new_pin)
    const num1 = Number(data.confirm_pin)
    if (!num) {
      toast.error("PIN must be a number")
      return
    }
    if (!num1) {
      toast.error("PIN must be a number")
      return
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/company-set-pin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            pin: data?.new_pin,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data)
        }
        toast.success(req?.message ?? "Pin successfully set")
        dispatch(clearAuthState());
        setTimeout(() => {
          history?.replace("/")
          //()
        }, 1500)
      } else {
        toast.info(req?.data ?? "Something went wrong")
      }
    } catch (error) {
      toast.error("Error resetting pin")
    }
  }

  React.useEffect(() => {
    const data = stateUser
    if (data) {
      setUser(data)
    }
  }, [])

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = userData

      const manipulatedColors = data?.colors
        ? convertColorsToLabelValueArray(data.colors)
        : []

      setColors(data.colors)
      _setValue(manipulatedColors)
    }
  }, [])


 

 

  return (
    <>
      <CustomHeader title="Settings" />

      <div className="grid grid-cols-1  lg:grid-cols-3 gap-5">
        <div className="w-full">
          <Tabs id="custom-animation" value={selectedTab}>
            <TabsHeader
              style={{
                background:
                  colors?.length! > 1
                    ? `${DEFAULTSECONDARYCOLOR}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="flex flex-col py-2"
            >
              {tabsToRender?.map((tab) => (
                <Tab
                  key={tab}
                  value={tab}
                  onClick={() => {setSelectedTab(tab); console.log(tab)}}
                  className={`text-gray-500 ${
                    selectedTab === tab && "text-gray-900"
                  } place-items-start w-full py-2 text-sm font-semibold `}
                >
                  {tab}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>
        {selectedTab === tabs[0] && (
          <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
            <form onSubmit={handleSubmit(changePassword)} className="w-full">
              <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                  >
                    Old password
                  </Typography>
                  <Input
                    {...register("old_password", { required: true })}
                    type="password"
                    crossOrigin="anonymous"
                    size="lg"
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                  >
                    New password
                  </Typography>
                  <Input
                    {...register("new_password", { required: true })}
                    type="password"
                    crossOrigin="anonymous"
                    size="lg"
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                  >
                    Confirm password
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    type="password"
                    size="lg"
                    {...register("confirm_password", { required: true })}
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>

              <Button
                type="submit"
                className="mt-6 flex items-center justify-center"
              >
                {isSubmitting ? <Spinner className="w-4 h-4" /> : "Update"}
              </Button>
            </form>
          </div>
        )}

        {selectedTab === tabs[1] && (
          <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
            <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-[14px] w-1/3"
              >
                Press enter after typing each color
              </Typography>
              <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => _setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Type and press enter..."
                value={value}
                className="w-full"
              />
            </div>
            <Button
              onClick={() =>
                updateCompanyColors({
                  variables: {
                    id: companyId,
                    colors: convertLabelValueArrayToColors(
                      value as readonly ColorObject[]
                    ),
                  },
                })
              }
              type="button"
              className="mt-6 flex items-center justify-center"
            >
              {RequestLoading ? <Spinner className="w-4 h-4" /> : "Update"}
            </Button>
          </div>
        )}

        {(selectedTab === tabs[numType] || selectedTab === staffTabs[numType]) && (
          <>
            {stateUser?.pin ? (
              <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
                {/* Form for resetting PIN with old PIN and new PIN */}
                <form onSubmit={restPinSubmit(resetPin)} className="w-full">
                  <p className="pb-4 text-[1.1rem] font-semibold">
                    Reset pin by entering your old and new pin
                  </p>
                  <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        Old PIN
                      </Typography>
                      <Input
                        {...pinRegister("old_pin", { required: true })}
                        maxLength={4}
                        type={isMasked ? "password" : "password"}
                        crossOrigin="anonymous"
                        size="lg"
                        className="!border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {resetError.old_pin && (
                        <span className="text-left mt-2 text-xs text-brand-primary">
                          Old PIN is required
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        New PIN
                      </Typography>
                      <Input
                        {...pinRegister("new_pin", { required: true })}
                        type="text"
                        maxLength={4}
                        crossOrigin="anonymous"
                        size="lg"
                        className="!border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      {resetError.new_pin && (
                        <span className="text-left mt-2 text-xs text-brand-primary">
                          New PIN is required
                        </span>
                      )}
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className="mt-6 flex items-center justify-center"
                  >
                    {resetISubmittig ? (
                      <Spinner className="w-4 h-4" />
                    ) : (
                      "Reset"
                    )}
                  </Button>
                </form>
              </div>
            ) : (
              <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
                {/* Form for resetting PIN with reset PIN and confirm PIN */}
                <form onSubmit={setPinSubmit(setPin)} className="w-full">
                  <p className="pb-4 text-[1.1rem] font-semibold">
                    Set your pin here(4 digits).
                  </p>
                  <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        Enter PIN
                      </Typography>
                      <Input
                        {...setpinRegister("new_pin", { required: true })}
                        maxLength={4}
                        type={isMasked ? "text" : "tel"}
                        crossOrigin="anonymous"
                        size="lg"
                        className="!border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {setError.new_pin && (
                        <span className="text-left mt-2 text-xs text-brand-primary">
                          Reset PIN is required
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="text-sm font-bold mb-3"
                      >
                        Confirm PIN
                      </Typography>
                      <Input
                        {...setpinRegister("confirm_pin", { required: true })}
                        type="text"
                        maxLength={4}
                        crossOrigin="anonymous"
                        size="lg"
                        className="!border-blue-gray-200 focus:!border-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                      {setError.confirm_pin && (
                        <span className="text-left mt-2 text-xs text-brand-primary">
                          Confirm PIN is required
                        </span>
                      )}
                    </div>
                  </div>

                  <Button
                    type="submit"
                    className="mt-6 flex items-center justify-center"
                  >
                    {setISubmittig ? <Spinner className="w-4 h-4" /> : "Submit"}
                  </Button>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Settings
