import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../components/headers/CustomHeader"
import {
  Button,
  Card,
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import Papa from "papaparse"

import moment from "moment"
import { toast } from "react-toastify"
import {DEFAULTPRIMARYCOLOR } from "../../util"

import { DetailDrawer } from "../../components"

import InvestToInsureDetail from "../../components/Details/invest-2-insure-detail"
import { FiDownloadCloud } from "react-icons/fi"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { RiAdminLine } from "react-icons/ri"
import { DriverAggreateType } from "../../types/query"
import {
  DRIVERAGGREGATE,
  GETINVESTTOINSUREPOLICIES,
} from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { useSearch } from "../../hooks/useSearch"
import { getColorCode } from "../../colorCodes"
import PerPageAndPagination from "../../components/paginations/perpageAndPagination"



interface BusinessClassByBusinessClass {
  id: string
  name: string
}
interface SubClass {
  id: string
  name: string
}
interface Request {
  registration_number_text: string
  policyholder: {
    first_name: string
    last_name: string
    id: string
  }
  start_date: string
  end_date: string
  premium_amount: string
  businessClassByBusinessClass: BusinessClassByBusinessClass
  sub_class: SubClass
}
interface Policy {
  policy_number: string
  request: Request
}

interface ResponseType {
  policy_number: string
  id: string
  datecreated: string
  request: {
    registration_number_text: string
    policyholder: {
      id: string
      last_name: string
      first_name: string
    }
    start_date: string
    end_date: string
    premium_amount: string
    businessClassByBusinessClass: {
      id: string
      name: string
    }
    sub_class: {
      id: string
      name: string
    }
  }
  driver_invest2insure_payments: Array<{
    amount: number
    driver: {
      full_name: string
      driver_code: string
      id: string
      phone_number_1: string
      phone_number_2: string
      balance: string
      gender: string
      email: string
      address: string
      id_card_type: string
    }
    sub_agent: null | {
      first_name: string
      last_name: string
      phone_number: string
    }
    company_staff: {
      id: string
      name: string
      phone_number_one: string
    }
  }>
}

const Invest2InsurePayments = () => {
  const [searchText, setSearchText] = useState("")
  const [data, setData] = useState<ResponseType[]>([])

  const [loading, setLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState<ResponseType>()
  const [open, setOpen] = useState<boolean>(false)
  // const [paginatedData, setPaginatedData] = useState<ResponseType[] | [] | undefined>([])
  const [totalPageSize, setTotalPageSize] = useState(0)

  const {
    companyId,

    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const { data: totalPolicies, loading: load } =
    useQuery<DriverAggreateType>(DRIVERAGGREGATE)
  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
    setActivePage(1)
    setSearchText("")
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
    setStartDate("")
    setEndDate("")
  }

  const { loading: loadingData, data: investData } = useSearch(
    GETINVESTTOINSUREPOLICIES,
    searchText
  )
  // console.log(investData)

  const normalizeDate = (date: Date | string): Date => {
    const normalizedDate = new Date(date)
    normalizedDate.setHours(0, 0, 0, 0)
    return normalizedDate
  }




  // console.log(totalPageSize)
  // const startIndex = (activePage - 1) * pageSize
  // const endIndex = startIndex + pageSize

  function filterPoliciesByDateRange(
    policies: ResponseType[],
    fromDate: string | Date | null,
    toDate: string | Date | null
  ) {
    // Helper function to normalize dates
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date)
      normalizedDate.setHours(0, 0, 0, 0)
      return normalizedDate
    }

    // Convert and normalize fromDate and toDate
    const from = fromDate ? normalizeDate(fromDate) : null
    const to = toDate ? normalizeDate(toDate) : null

    return policies?.filter((policy: any) => {
      // If no dates are provided, return all policies
      if (!from && !to) {
        return true
      }

      const policyStartDate = normalizeDate(policy.request.start_date)
      const policyEndDate = normalizeDate(policy.request.end_date)

      // Check if the policy falls within the date range
      if (from && to) {
        return policyStartDate >= from && policyEndDate <= to
      } else if (from) {
        return policyStartDate >= from
      } else if (to) {
        return policyEndDate <= to
      }

      // This line should never be reached, but TypeScript might expect a return
      return false
    })
  }
  const [activePage, setActivePage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate ) {
      setActivePage(0)
   
    }
  }, [searchText, startDate, endDate])
  useEffect(() => {
    if ( pageSize) {
      setActivePage(0)
      setSearchText("")
      setStartDate("")
      setEndDate("")
    }
  }, [ pageSize])

  // console.log(investData?.policy)
  const filteredData = filterPoliciesByDateRange(
    investData?.policy as ResponseType[],
    startDate,
    endDate
  )
  // console.log(filteredData)
  const lengthOfItems = filteredData?.length

  const endOffset = activePage + pageSize
  const paginatedData = filteredData?.slice(activePage, endOffset)

  // const paginatedData = filteredData?.slice(startIndex, endIndex)
  // console.log(investData?.policy)
  //-----------------

  useEffect(() => {
    const fetchData = async () => {
      setData([])
      setLoading(true)
      try {
        const res = await fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/get-all-invest2insure-data?company_id=${companyId}&page=${
            activePage + 1
          }&limit=${pageSize}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const req = await res.json()

        if (res?.ok) {
          if (req?.error) {
            toast.error(req?.data)
          } else {
            // console.log(req?.data)
            setData(req?.data)
            setTotalPageSize(req?.total_records)
          }
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        toast.error(error?.message ?? "Something went wrong. Try again!")
      }
    }

    fetchData()
  }, [activePage, pageSize, companyId, token])

  // console.log(data, totalPageSize)

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }

    if (searchText || startDate || endDate) {
      const type = filteredData?.map((item: ResponseType) => ({
        policy_number: item?.policy_number,
        business_class: item?.request?.businessClassByBusinessClass?.name,
        sub_class: item?.request?.sub_class?.name,
        vehicle_reg_number: item?.request?.registration_number_text,
        premium_amount: item?.request?.premium_amount,
        policy_start_date: moment(item?.request?.end_date).format("LLL"),
        policy_end_data: moment(item?.request?.start_date).format("LLL"),
        driver_name: item?.driver_invest2insure_payments[0]?.driver?.full_name,
        driver_phone:
          item?.driver_invest2insure_payments[0]?.driver?.phone_number_1,
        staff_name: item?.driver_invest2insure_payments[0]?.company_staff?.name,
        staff_number:
          item?.driver_invest2insure_payments[0]?.company_staff
            ?.phone_number_one,
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"invest-2-insure-policies"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    }
    const type = investData?.policy?.map((item: ResponseType) => ({
      policy_number: item?.policy_number,
      business_class: item?.request?.businessClassByBusinessClass?.name,
      sub_class: item?.request?.sub_class?.name,
      vehicle_reg_number: item?.request?.registration_number_text,
      premium_amount: item?.request?.premium_amount,
      policy_start_date: moment(item?.request?.end_date).format("LLL"),
      policy_end_data: moment(item?.request?.start_date).format("LLL"),
      driver_name: item?.driver_invest2insure_payments[0]?.driver?.full_name,
      driver_phone:
        item?.driver_invest2insure_payments[0]?.driver?.phone_number_1,
      staff_name: item?.driver_invest2insure_payments[0]?.company_staff?.name,
      staff_number:
        item?.driver_invest2insure_payments[0]?.company_staff?.phone_number_one,
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"invest-2-insure-policies"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  //--------------------------

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [stateUser?.colors, userData])

  return (
    <>
      <CustomHeader title="Invest-2-Insure Policies" />
      <DetailDrawer
        title="Payment Detail"
        open={open}
        size={400}
        onClose={() => setOpen(false)}
        children={<InvestToInsureDetail content={selectedRow} />}
      />
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {load ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  totalPolicies?.total_drivers_policies?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total drivers policies
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
      </div>
      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of Policies{" "}
              <span className=" text-white">
                {totalPolicies?.total_drivers_policies?.aggregate?.count}
              </span>
            </Typography>
          </div>

          <div className="w-full">
            <div className="flex flex-col  gap-y-4  bg-white px-3 py-4">
              <div className="flex shrink-0 flex-col justify-end gap-2 sm:flex-row">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                <div className="w-[60%] mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
                <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "policy holder",
                      "policy number",
                      "Business Class",
                      "Sub Class",
                      "Vehicle Reg. No.",
                      "Premium amount",
                      "Policy Start Date",
                      "Policy Expiry Date",
                      "driver's name",
                      "staff/Agent",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4  text-center"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-2 py-3  text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        onClick={() => {
                          setSelectedRow(item)
                          setOpen(true)
                        }}
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        {/* <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.transaction_id}
                          </Typography>
                        </td> */}
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request?.policyholder.first_name +
                              " " +
                              item?.request?.policyholder.last_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.policy_number}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request?.businessClassByBusinessClass.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request?.sub_class.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request?.registration_number_text}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {parseFloat(
                              item?.request?.premium_amount ?? "0"
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.request?.start_date).format("LLL")}
                          </Typography>
                        </td>

                        {/* <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {(item?.amount ?? 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td> */}

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.request?.end_date).format("LLL")}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {
                              item?.driver_invest2insure_payments[0]?.driver
                                ?.full_name
                            }
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.driver_invest2insure_payments[0]
                                  ?.company_staff
                                  ? `${item?.driver_invest2insure_payments[0]?.company_staff?.name}`
                                  : `${item?.driver_invest2insure_payments[0]?.sub_agent?.first_name} ${item?.driver_invest2insure_payments[0]?.sub_agent?.last_name}`}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.driver_invest2insure_payments[0]
                                  ?.company_staff
                                  ? item?.driver_invest2insure_payments[0]
                                      ?.company_staff?.phone_number_one
                                  : item?.driver_invest2insure_payments[0]
                                      ?.sub_agent?.phone_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              User type:{" "}
                              <span className="font-normal">
                                {item?.driver_invest2insure_payments[0]
                                  ?.company_staff
                                  ? "Company Staff"
                                  : "Sub Agent"}
                              </span>
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {(loadingData || loading) && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedData && (!loadingData || !loading) && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedData?.length! < 1 && (!loadingData || !loading) && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {
          // <GeneralPagination
          //   totalItems={
          //     searchText || startDate || endDate
          //       ? lengthOfItems
          //       : totalPageSize! ?? 0
          //   }
          //   itemsPerPage={pageSize}
          //   currentPage={activePage}
          //   onPageChange={handlePageChange}
          // />

          <PerPageAndPagination
            total={
              (searchText || startDate || endDate
                ? lengthOfItems
                : totalPageSize!) ?? 0
            }
            currentPage={activePage}
            setCurrentPage={setActivePage}
            perPage={pageSize}
            setPerPage={setPageSize}
          />
        }
      </div>
    </>
  )
}

export default Invest2InsurePayments
