import React, { useState } from "react"
import {
  Button,
  DialogFooter,
  Input,
  Typography,
} from "@material-tailwind/react"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import systemconfig from "../../systemconfig"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

type FormType = {
  messageTitle: string
  messageBody: string
}

const SendMessageDetails = ({
  onClose,
  driver,
}: {
  onClose?: () => void
  driver?: string | string[]
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<FormType>()

  const watchFields = watch()
 


  const {companyId,roleId,usertype,user:stateUser,token}=useSelector((state:RootState) => state.auth)
  const onSubmit: SubmitHandler<FormType> = async (data) => {
   

    const output = await trigger(["messageBody", "messageTitle"], {
      shouldFocus: true,
    })

    if (!output) return
    try {
      let payload = JSON.stringify({
        channel: "sms",
        message: data.messageBody,
        target: "customers",
        title: data.messageTitle,
        user_id: driver,
      })
    
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }

      const req = await fetch(`${systemconfig.baseUrl}/create-notification-company`, options)

      const res = await req.json()
     

      if (!req.ok) {
        
        toast.error("An Error occured while sending message to driver")
        reset()
        return
      }

      toast.success(res.message ?? "Message sent successfully")
      reset()
      onClose!()
      // refreshClass();
    } catch (error: any) {
      toast.error(error?.message ?? "An Error occured")
      console.log(error)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=" overflow-auto h-full w-full"
    >
      <div className=" px-7 flex flex-col gap-y-10">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Message Title
          </Typography>
          <input
            id="messageTitle"
            type="text"
            className={` border-black/20 border rounded-md focus:border-black text-black/70 focus:outline-none px-2 py-[0.7rem] w-full ${
              errors.messageTitle && "focus:border-red-400"
            }`}
            {...register("messageTitle", { required: true })}
          />
        </div>

        <div className=" flex flex-col gap-y-2 ">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-normal text-base"
          >
            Message Body
          </Typography>
          <textarea
            rows={5}
            id="messageBody"
            {...register("messageBody", { required: true })}
            className={` px-3 border border-black/20 resize-none rounded-md focus:border-black text-black/70 focus:outline-none ${
              errors.messageBody && "focus:border-red-400"
            }`}
          ></textarea>
        </div>
      </div>

      <DialogFooter className="px-10">
        <Button variant="text" color="red" className="mr-1" onClick={onClose}>
          <span>Cancel</span>
        </Button>

        <Button
          variant="gradient"
          type="submit"
          disabled={isSubmitting}
          color="green"
          className="flex items-center justify-center "
        >
          <span>{isSubmitting ? "sending..." : "Send Message"}</span>
        </Button>
      </DialogFooter>
    </form>
  )
}

export default SendMessageDetails

//GLI-743591

// 'clear-payments?payment_mode=cash&admintype=company_administrator&total_amount=1&reference_number=reyfhvvvhgfgfgfgfhgfhgfhgfhgfhgfhgfhgfhgfgfgfg&user_id=60a4c927-2ae8-4760-8018-198ed2341ce8&usertype=staff&company_id=61c2b015-ebef-40af-8eec-8b5160eb5575'
