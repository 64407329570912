import systemconfig from "../systemconfig"
export const BASEURL = systemconfig.baseUrl


export function calculateDaysLeft(startDate: string, endDate: string) {
  // Convert the dates to milliseconds
  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()

  // Calculate the difference in milliseconds
  const difference = end - start

  // Convert the difference from milliseconds to days
  const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24))

  return daysDifference
}

export function calculateAgeing(scheduledDate: string): number | string {
  const today: number = new Date().getTime()
  const schedule: number = new Date(scheduledDate).getTime()

  // Calculate the difference in milliseconds
  const differenceInMilliseconds: number = today - schedule

  // Convert the difference from milliseconds to days
  const differenceInDays: number = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  )

  return differenceInDays >= 0 ? differenceInDays : "N/A"
}

/** Validate Ghana card */
export const cardNumberPattern = /^(GHA|NRG)-\d{9}-\d$/

export const id_types = [
  { code: "04", name: "National ID" },
  { code: "01", name: "Driver's License" },
  { code: "02", name: "Voter Id" },
  { code: "03", name: "Passport" },
  { code: "05", name: "NHIS Card" },
  { code: "06", name: "Other" },
]

export const intermediary_types = [
  { code: "Agent", name: "Agent" },
  { code: "Broker", name: "Broker" },
  { code: "Staff", name: "Staff" },
  { code: "00000000-0000-0000-0000-000000000000", name: "All" },
]

export const DEFAULTPRIMARYCOLOR = "#f87171"
export const DEFAULTSECONDARYCOLOR = "black"
