import {
  Avatar,
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaBarsStaggered } from "react-icons/fa6";
import useDrawer from "../../hooks/use-drawer";
import { MobileSidebar } from "../Sidebar/MobileSidebar";
import NotificationDrawer from "../modal/notification-drawer";
import { NotificationType } from "../../types/notification";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { MdDarkMode } from "react-icons/md";

const HeaderIcon = () => {
  const { open, toggleDrawer, setOpen } = useDrawer();
  const [openNotification, setOpenNotification] = useState(false);
  const [token, setToken] = useState();
  const history = useHistory();
  let storageToken = localStorage.getItem("token");

  React.useEffect(() => {
    if (storageToken) {
    }
  }, []);

  React.useEffect(() => {
    // token && mutate();
  }, [token]);

  const toggleNotification = () => setOpenNotification(!openNotification);

  return (
    <>
      <NotificationDrawer
        open={openNotification}
        onClose={toggleNotification}
        notifications={[]}
        loading={false}
        size={350}
      />
      <MobileSidebar open={open} closeDrawer={() => setOpen(false)} />

      <div className="flex flex-row items-center justify-center gap-x-3">
        {/* <IconButton size="sm" variant="gradient" color="white">
          <MdDarkMode className="" size={17} />
        </IconButton> */}
        <div className="relative">
        
        </div>
        <div className="block xl:hidden">
          <IconButton onClick={toggleDrawer} color="red" size="sm">
            {open ? (
              <AiOutlineCloseCircle size={20} />
            ) : (
              <FaBarsStaggered size={20} />
            )}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default HeaderIcon;
