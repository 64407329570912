import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const CustomBarChart = (props: {
  data:
    | {
        name: string;
        total_premium: number;
      }[]
    | undefined;
}) => {
  let demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props?.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 10 }} />
        <YAxis dataKey="total_premium" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="total_premium"
          label="Total premium"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey={"name"}
          label="Name"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomPremiumNumberBarChart = (props: {
  data:
    | {
        name: string;
        record_count: number;
      }[]
    | undefined;
}) => {
  let demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props?.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 10 }} />
        <YAxis dataKey="record_count" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="record_count"
          label="Record count"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey={"name"}
          label="Name"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomSubscriopChart = (props: {
  data:
    | {
        month: string;
        subscription: number;
      }[]
    | undefined;
}) => {
  let demoUrl = "https://codesandbox.io/s/simple-bar-chart-tpz8r";

  let sortedData = [...(props?.data || [])]?.sort((a, b) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndexA = months?.indexOf(a?.month);
    const monthIndexB = months?.indexOf(b?.month);

    return monthIndexA - monthIndexB;
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
        <YAxis dataKey="subscription" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="subscription"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey={"month"}
          label="Month"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomVolumeBarChart = (props: {
  data: Record<string, number> | undefined;
}) => {
  // Transform the volumes_generated_by_staff object into an array of objects
  const transformedData = Object.entries(props?.data || {}).map(
    ([month, record_count]) => ({
      name: month,
      record_count,
    })
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={transformedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 10 }} />
        <YAxis dataKey="record_count" />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "12px" }} />
        <Bar
          dataKey="record_count"
          label="Record count"
          fill="#ED7C31"
          width={4}
          activeBar={<Rectangle fill="#f87171" stroke="#f87171" />}
        />
        <Bar
          dataKey="name"
          label="Month"
          fill="#000"
          activeBar={<Rectangle fill="#000" stroke="#000" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomVolumeBarChart;
