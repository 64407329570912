import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { BusinessClassType } from "../../../types/class";
import {
  GETBUSINESSCLASSES,
  GETCOVERTYPES,
  GETPREMIUMRATES,
  GETSUBCLASSES,
} from "../../../GraphQl/queries";
import { toast } from "react-toastify";
import { ADDPREMIUMRATE, DELETEPREMIUMRATE } from "../../../GraphQl/mutations";
import { SubClassType } from "../business-class/manage";
import { CoverTypes, FormType, PremuimRateType } from "../../../types/query";
import { UserType } from "../../../types/user";
import { DEFAULTPRIMARYCOLOR } from "../../../util";

const PremiumRateView = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<FormType>();

  const watchClass = watch("class");

  const { data, loading, refetch } = useQuery<{
    view_premium_rate: PremuimRateType[];
  }>(GETPREMIUMRATES);

  const { data: subclasses, loading: loadingSubClass } = useQuery<{
    subclass: SubClassType[];
  }>(GETSUBCLASSES, {
    variables: {
      class_id: watchClass,
    },
  });

  const {
    data: coverTypeData,
    loading: loadingCoverType,
    refetch: refetchCoverTypes,
  } = useQuery<{
    covertypes: CoverTypes[];
  }>(GETCOVERTYPES);

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[];
  }>(GETBUSINESSCLASSES);

  const [CreatePremiumRate, { loading: RequestLoading }] = useMutation(
    ADDPREMIUMRATE,
    {
      onCompleted: (data) => {
        toast.success("Premium rate Successfully added");
        reset();
        refetch();
        refreshClass();
        refetchCoverTypes();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating premium rate"),
    }
  );

  const [DeletePremiumRate] = useMutation(DELETEPREMIUMRATE, {
    onCompleted: (data) => {
      toast.success("Premium rate deleted successfully");
      refetch();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting premium rate"),
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: PremuimRateType) => {
    const isTextMatch =
      filter?.class_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.covertype_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.premium_rate
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.subclass_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.premium_description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = data?.view_premium_rate
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.view_premium_rate?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = (data) => {
    try {
      if (data.class === "all" && data?.cover === "all" && data?.subclass) {
        CreatePremiumRate({
          variables: {
            class_id: null,
            description: data?.description,
            cover_type_id: null,
            subclass_id: null,
            rate: data?.rate,
          },
        });
      } else {
        CreatePremiumRate({
          variables: {
            class_id: data?.class,
            description: data?.description,
            cover_type_id: data?.cover,
            subclass_id: data?.subclass,
            rate: data?.rate,
          },
        });
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating premium rate");
    }
  };

  const deleteRate = (id: string) => {
    try {
      DeletePremiumRate({
        variables: {
          id,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting premium rate");
    }
  };

  useEffect(() => {
    refreshClass();
  }, [watchClass]);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Premium rating" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              Create Premium Rate
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Class of Business
                    </Typography>
                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                    >
                      <option label="All" value="all">
                        {loadingClasses ? "Loading..." : "All"}
                      </option>
                      {classes?.business_classes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Subclass
                    </Typography>

                    <select
                      id="subclass"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("subclass", { required: true })}
                    >
                      <option>
                        {loadingSubClass ? "Loading..." : "Select subclass"}
                      </option>
                      {subclasses?.subclass?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                      <option
                        value={"00000000-0000-0000-0000-000000000000"}
                        label="All"
                      >
                        All
                      </option>
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Type of Cover
                    </Typography>
                    <select
                      id="covertypes"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("cover", { required: true })}
                    >
                      <option label="All" value="all">
                        {loadingCoverType ? "Loading..." : "All"}
                      </option>
                      {coverTypeData?.covertypes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Rate
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("rate", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading
                  ? "Creating..."
                  : "Create Premium Rate"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">
              List of Premium Loading Rates
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "class",
                      "Subclass",
                      "Description",
                      "Cover Type",
                      "Rate",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.class_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.subclass_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.premium_description}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.covertype_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.premium_rate}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <IconButton
                            onClick={() => deleteRate(item?.id)}
                            color="red"
                            size="sm"
                          >
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.view_premium_rate?.filter(filterData).length ||
                          0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.view_premium_rate?.filter(filterData).length ||
                          0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default PremiumRateView;
