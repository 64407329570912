import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { ReactNode } from "react";
import { GrClose } from "react-icons/gr";
import { IoClose } from "react-icons/io5";

interface ModalProps {
  onClose: () => void;
  title?: string;
  size?: string | "lg" | any;
  showClose?: boolean;
  children?: ReactNode | null;
  open: boolean;
  className?: string | undefined;
}

export default function CustomModal({
  onClose,
  open,
  children,
  size,
  className,
  title,
  showClose,
}: ModalProps) {
  return (
    <Dialog open={open} handler={onClose} size={size} className={className}>
      {title && (
        <DialogHeader className="font-semibold text-lg flex flex-row items-center justify-between w-full">
          <div> {title}</div>
          {showClose && (
            <Button onClick={onClose} placeholder="" className="bg-black">
              <IoClose color="white" className="text-base text-white" />
            </Button>
          )}
        </DialogHeader>
      )}
      <DialogBody>{children}</DialogBody>
    </Dialog>
  );
}
