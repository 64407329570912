import { Typography } from "@material-tailwind/react"
import React from "react"
import { GET_SINGLE_DRIVER } from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
export interface CollectionRequest {
  amount: number
  created_at: string
  created_by: string
  driver_current_balance: number
  id: string
  payment_mode: string
  status: string
  reference_number: string
  driver_id: string
  company_staff: {
    id: string
    name: string
    phone_number: string
  }
  sub_agent: {
    id: string
    first_name: string
    last_name: string
    phone_number: string
  } | null // Assuming sub_agent can be null
}

export type DriversDataType = {
  account_status: string
  balance: number
  date_created: string
  driver_code: string
  driver_id: string
  driver_name: string
  phone_number: string
  total_number_of_policies: number
  total_premium_balance: number
  total_premium_paid: number
  total_premium_payable: number
  wallet_number: string
}

const DriverColectionDetails = ({
  content,
}: {
  content?: DriversDataType | null
}) => {
  // const { error, data: content } = useQuery<{
  //   collection_request: CollectionRequest[]
  // }>(GET_SINGLE_DRIVER, {
  //   variables: {
  //     id: id,
  //   },
  // })
 
 
  return (
    <div className="overflow-y-auto h-full">
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.driver_id}
        </Typography>
      </div>
      <div className="border border-gray-200 rounded-md py-2 px-4 mt-2">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          Driver Code
        </Typography>
        <Typography
          className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
          color="blue-gray"
        >
          {content?.driver_code}
        </Typography>
      </div>

      <Typography variant="h5" className="text-lg mt-10 mb-3" color="blue-gray">
        Driver details
      </Typography>
      <div className="border border-gray-200 rounded-md py-2 px-4">
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Name
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver_name}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Balance
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.balance ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
             Total Premium Balnace
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.total_premium_balance ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
             Total  Premium Paid
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              GHS
              {(content?.total_premium_paid ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </div>
         
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Phone Number
            </Typography>
            <Typography
              as={"a"}
              // href={`tel:${content?.driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.phone_number}
            </Typography>
          </div>
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              Wallet Number
            </Typography>
            <Typography
              as={"a"}
              // href={`tel:${content?.driver?.phone_number_1}`}
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.wallet_number}
            </Typography>
          </div>
        
       
        </div>
        <div className="">
          <div>
            <Typography
              className="text-sm text-brand-gray mt-4"
              color="blue-gray"
            >
              Account Status
            </Typography>
            <Typography
              as={"a"}
              // href={`mailto:${content?.driver?.email}`}
              className="text-sm font-semibold  mt-2"
              color="blue-gray"
            >
              {content?.account_status}
            </Typography>
          </div>
         
          <div>
            {/* <Typography
              className="text-sm text-brand-gray mt-4 capitalize"
              color="blue-gray"
            >
              ID card type
            </Typography>
            <Typography
              className="text-xs font-semibold  mt-2 capitalize text-black"
              color="blue-gray"
            >
              {content?.driver?.id_card_type}
            </Typography> */}
          </div>
        </div>
        <div className="h-20" />
      </div>
    </div>
  )
}

export default DriverColectionDetails
