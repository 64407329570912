import React, { useEffect, useState, useMemo } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
    CardBody,
    Input,
    Spinner,
    Typography,
} from "@material-tailwind/react"
import {  useLazyQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { GETAGENTID, GETBROKERID, GETSTAFFID } from "../../../GraphQl/queries"
import moment from "moment"
import ReplenishmentConfirmation from "../../../config/replenishment-confirmationBox"
import SearchComponent from "./searchComponent"
import { DriverModal } from "../../../components/modal/DriverModal"
import Otp from "../../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState, AppDispatch } from "../../../store/store"
import { useDispatch } from 'react-redux'
import { setIntermediary, clearIntermediary, setCurrentReferenceNumber, fetchData, setSelectedUser, setUserType, clearPayment, resetState, setSelectedRowAmount, updateTotalAmount, setSelectedRow, setSelectedIntermediaryId,clearState,setDriverPaymenttoNull } from '../../../store/replenishmentSlice'
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"



type checkpaymentType = {
    amount: number
    cheque_number: string
    is_cleared: boolean
    date_on_cheque: string
    date_replenished: string
    created_at: string
    policy_number: string
    policy_id: string
    policy_status: string
    payment_type: string
    replenished_by_name: string
    id: string
}

type PaymentType = {
    id: string
    policy_id: string
    // Add other fields if necessary
}

export type AmountType = {
    id: string
    amount: number
    name?: string | null
}

type cashpaymentType = {
    name: string
    ID: string
    amount: number
    is_replenished: boolean
    replenished_by_name: string
    date_on_cheque: string
    date_replenished: string
    created_at: string
    policy_number: string
    policy_status: string
    policy_id: string
    created_by: string
    payment_type: string
    id: string
}
type drivercollectionType = {
    reference_number: string
    replenished_by_name: string
    status: string
    date_replenished: string
    amount: number
    created_at: string
    policy_id: string
    driver: {
        driver_code: string
        full_name: string
        phone_number_1: string
    }
    is_replenished: boolean
    payment_mode: string
    id: string
    created_by: string
    agent_id: string
}


const ReplenishmentWithStateView = () => {
    const [searchText, setSearchText] = useState("")
    const [open, setOpen] = useState<boolean>(false)

    const [cashType, setCashType] = useState("Not Replenished")

    const [chequeType, setChequeType] = useState("Cleared")
    const [selectedChannel, setSelectedChannel] = useState("cash")



    const [getAgentId, { data: agentData, loading: loadingAgent }] =
        useLazyQuery(GETAGENTID)
    const [getBrokerId, { data: brokerData, loading: loadingBroker }] =
        useLazyQuery(GETBROKERID)
    const [getStaffId, { data: staffData, loading: loadingStaff }] =
        useLazyQuery(GETSTAFFID)
    const [searchTextItem, setSearchTextItem] = useState("")
    const [openOtp, setOpenOtp] = useState(false)
    const [currentData, setCurrentData] = useState<
    cashpaymentType[] | checkpaymentType[] | drivercollectionType[] | []
  >([])

    const dispatch: AppDispatch = useDispatch();
    const {
        companyId,
        user: stateUser,
        usertype: stateUserType,
        token,
    } = useSelector((state: RootState) => state.auth)


      useEffect(() => {
        dispatch(clearState()); 
        return () => {
          dispatch(clearState());
        };
      }, [dispatch]);
      

    const {
        cashLimit,
        currentReferenceNumber,
        balance,
        selectedIntermediaryId,
        chequePayments,
        cashPayments,
        driverCollections,
        selectedRowAmount,
        loading,
        selectedUser,
        selectedRows,
        usertype,
        total_amount: totalAmount
    } = useSelector((state: RootState) => state.replenishment)


    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTextItem(e.target.value)
    }
    useEffect(() => {
        if (searchText === "") {
            dispatch(clearIntermediary())
            return
        }

        if (usertype === "agent") {
            if (agentData?.sub_agents?.length > 0) {
                dispatch(setIntermediary({
                    intermediaryId: agentData.sub_agents[0].id,
                    staffagentName: `${agentData.sub_agents[0].first_name} ${agentData.sub_agents[0].last_name}`,
                    cachedId: searchText
                }))
            } else {
                dispatch(clearIntermediary())
            }
        } else if (usertype === "broker") {
            if (brokerData?.intemediary?.length > 0) {
                dispatch(setIntermediary({
                    intermediaryId: brokerData.intemediary[0].id,
                    staffagentName: `${brokerData.intemediary[0].first_name} ${brokerData.intemediary[0].last_name}`,
                    cachedId: searchText
                }))
            } else {
                dispatch(clearIntermediary())
            }
        } else if (usertype === "staff") {
            if (staffData?.company_staff?.length > 0) {
                dispatch(setIntermediary({
                    intermediaryId: staffData.company_staff[0].id,
                    staffagentName: staffData.company_staff[0].name,
                    cachedId: searchText
                }))
            } else {
                dispatch(clearIntermediary())
            }
        }
    }, [searchText, agentData, brokerData, staffData, usertype, dispatch])



    const paymentTypes = [
        { name: "Policies by cash", value: "cash" },
        { name: "Policies by cheque", value: "cheque" },
        { name: "driver collections", value: "driver collections" },
      ]


    useEffect(() => {
        const totalAmount = selectedRowAmount.reduce(
            (acc: number, curr: AmountType) => acc + curr.amount,
            0
        );

        dispatch(updateTotalAmount(totalAmount));
    }, [selectedRowAmount, dispatch]);

    useEffect(() => {
        const intermediaryId = selectedIntermediaryId;
        if (companyId && intermediaryId !== "") {
            dispatch(fetchData({
                companyId,
                userId: intermediaryId,
                usertype
            }));
        } else {
            dispatch(resetState())
        }
    }, [selectedIntermediaryId, companyId, usertype, dispatch])

    useEffect(() => {
        setSearchText("")
    }, [usertype])

  
    const paginateFxn = (
        channel: string,
        cashtype: string,
        chequeType: string
    ) => {
        let paginate:
            | cashpaymentType[]
            | checkpaymentType[]
            | drivercollectionType[]
            | [] = []

        if (channel === "cash" && cashtype === "Replenished") {
            paginate = (cashPayments as cashpaymentType[]).filter(
                (item) => item.is_replenished
            )
        } else if (channel === "cash" && cashtype === "Not Replenished") {
            paginate = (cashPayments as cashpaymentType[]).filter(
                (item) => !item.is_replenished
            )
        } else if (channel === "driver collections" && cashtype === "Replenished") {
            paginate = (driverCollections as drivercollectionType[]).filter(
                (item) => item.is_replenished
            )
        } else if (
            channel === "driver collections" &&
            cashtype === "Not Replenished"
        ) {
            paginate = (driverCollections as drivercollectionType[]).filter(
                (item) => !item.is_replenished
            )
        } else if (channel === "cheque" && chequeType === "Cleared") {
            paginate = (chequePayments as checkpaymentType[]).filter(
                (item) => item.is_cleared
            )
        } else if (channel === "cheque" && chequeType === "Not Cleared") {
            paginate = (chequePayments as checkpaymentType[]).filter(
                (item) => !item.is_cleared
            )
        }
        return paginate
    }

    const [currentPage, setCurrentPage] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
  
    const handlePageChange = (page: number) => {
      setCurrentPage(page)
    }
  
    useEffect(()=>{
      if(searchTextItem || itemsPerPage){
          setCurrentPage(0)
      }
    },[searchTextItem, itemsPerPage])
  
  
    const startIndex = (currentPage - 1) * itemsPerPage
  
    const paginatedData1 = useMemo(() => {
      return paginateFxn(selectedChannel, cashType, chequeType)
    }, [selectedChannel, cashType, chequeType,driverCollections,cashPayments,chequePayments])
  
    useEffect(() => {
      const filteredItems = (() => {
        if (selectedChannel === "cash") {
          return (paginatedData1 as cashpaymentType[])?.filter(
            (item) =>
              moment(item?.created_at)
                .format("LL")
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              moment(item?.date_replenished)
                .format("LL")
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.amount
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.payment_type
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.policy_number
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.policy_status
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.replenished_by_name
                ?.trim()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
             
              item?.policy_id
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase())
          )
        } else if (selectedChannel === "cheque") {
          return (paginatedData1 as checkpaymentType[])?.filter(
            (item) =>
              item?.amount
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.payment_type
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.policy_number
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.policy_status
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.replenished_by_name
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.date_replenished
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.created_at
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase())
          )
        } else if (selectedChannel === "driver collections") {
          return (paginatedData1 as drivercollectionType[])?.filter(
            (item) =>
              moment(item?.created_at)
                .format("LL")
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              moment(item?.date_replenished)
                .format("LL")
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.amount
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.payment_mode
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.status
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.replenished_by_name
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase()) ||
              item?.driver?.full_name
                .toLowerCase()
                .includes(searchTextItem.toLowerCase()) ||
              item?.driver?.driver_code
                ?.toLowerCase()
                ?.includes(searchTextItem.toLowerCase())
          )
        } else {
          return []
        }
      })()
  
     
  
      setCurrentData(filteredItems)
    }, [paginatedData1, searchTextItem, selectedChannel])
   
  
 
  
    useEffect(() => {
      if (selectedChannel === "cash" && cashType === "Replenished") {
        setSearchTextItem("")
      } else if (selectedChannel === "cash" && cashType === "Not Replenished") {
        setSearchTextItem("")
      } else if (selectedChannel === "cheque" && chequeType === "Cleared") {
        setSearchTextItem("")
      } else if (selectedChannel === "cheque" && chequeType === "Not Cleared") {
        setSearchTextItem("")
      } else if (
        selectedChannel === "driver collections" &&
        cashType === "Replenished"
      ) {
        setSearchTextItem("")
      } else if (
        selectedChannel === "driver collections" &&
        cashType === "Not Replenished"
      ) {
        setSearchTextItem("")
      }
    }, [selectedChannel, cashType, chequeType])
  
    const totalItems = currentData?.length
    const endOffset = currentPage + itemsPerPage
    const paginatedData = currentData?.slice(currentPage, endOffset)
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && paginatedData) {
            dispatch(setSelectedRow(
                paginatedData.map((item: any) => ({
                    id: item.id,
                    policy_id: item.policy_id,
                    // add other properties if needed
                }))
            ));
        } else {
            dispatch(setSelectedRow([]));
        }
    }
    const handleRowCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        payment: PaymentType
    ) => {
        if (event.target.checked) {
            dispatch(setSelectedRow([
                ...selectedRows,
                payment
            ]));
        } else {
            dispatch(setSelectedRow(
                selectedRows.filter((p) => p.id !== payment.id)
            ));
        }
    }

    //------------------
    const handleAmountSelectAll = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked && paginatedData) {
            const amounts = paginatedData.map((item: any) => ({ id: item.id, amount: item.amount }));
            dispatch(setSelectedRowAmount(amounts));
        } else {
            dispatch(setSelectedRowAmount([]));
        }
    }

    const handleRowCheckboxAmountChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        amount: AmountType
    ) => {
        let updatedSelectedRowAmount;

        if (event.target.checked) {
            updatedSelectedRowAmount = [...selectedRowAmount, amount];
        } else {
            updatedSelectedRowAmount = selectedRowAmount.filter(
                (p) => p.id !== amount.id
            );
        }

        // Dispatch the action with the updated array
        dispatch(setSelectedRowAmount(updatedSelectedRowAmount));
    };

    const changeToParseInt = Number.isNaN(parseFloat(totalAmount.toString()))
        ? "0.00"
        : parseFloat(totalAmount.toString()).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

    const checkPaymentSelected = () => {
        if (!selectedRows || selectedRows.length === 0) {
            toast.warn("No payments selected.")
            return
        }
        setOpen(true)
    }

    const handleSelectIntermediary = (id: string) => {
        dispatch(setSelectedIntermediaryId(id));
    };

    const handleRevertAmount =(type:number)=>{
        dispatch(updateTotalAmount(0));
        dispatch(setSelectedRow([]));
        dispatch(resetState());
        

    }

    const handleSelectUserType = (type: string) => {
        dispatch(setUserType(type));
    };

    const handleSelectUser = (user: any) => {
        dispatch(setSelectedUser(user));
    };

    const handleCHangeRef = (type: string) => {
        dispatch(setCurrentReferenceNumber(type));
    };
    const clearCollections = (type: string) => {

        dispatch(clearPayment({
            channel: type,
            admin_type: stateUserType,
            selectedRows,
            totalAmount,
            currentReferenceNumber,
            token,
            companyId,
            userId: selectedIntermediaryId,
            usertype,
        }))
            .then((result) => {
                if (clearPayment.fulfilled.match(result) && companyId) {
                    // If the payment clearing is successful, wait 2 seconds and call fetchData again
                    setTimeout(() => {
                        dispatch(fetchData({
                            companyId,
                            userId: selectedIntermediaryId,
                            usertype
                        }));
                    }, 2000); // Wait 2 seconds

                    dispatch(setCurrentReferenceNumber(""));  // Action to reset currentReferenceNumber
                    dispatch(setSelectedRow([]));
                    dispatch(updateTotalAmount(0));
                }
            })
            .catch((error) => {
                console.error("Error clearing payments: ", error);
                // You can handle any additional error logic here if necessary
            });
    }

    return (
        <>
            <CustomHeader title="Replenish or Clear" />
            <DriverModal
                title="Enter Your PIN"
                open={openOtp}
                size={"xs"}
                focus="message"
                onClose={() => setOpenOtp(false)}
                children={
                    <Otp
                        onConfirm={() =>
                            selectedChannel === "driver collections"
                                ? clearCollections("driver_collection")
                                : clearCollections("cash")
                        }
                        onClose={() => setOpenOtp(false)}
                    />
                }
            />
            <ReplenishmentConfirmation
                show={open}
                setShow={setOpen}
                onConfirm={
                    () => setOpenOtp(true)
                }
                total={changeToParseInt}
                setRefNum={handleCHangeRef}
                row={selectedRowAmount}
                children={
                    <></>

                }
            />


            <div className="w-full flex flex-col  gap-5">
                <SearchComponent
                    updateTotalAmount={handleRevertAmount}
                    setSelectedIntermediaryId={handleSelectIntermediary}
                    setUserType={handleSelectUserType}
                    setSelectedUser={handleSelectUser}
                />
                <div className=" flex justify-between items-center">
                    <div className=" flex items-center w-[53%] top-[-50%]  gap-x-3  text-white">
                        <div className=" flex text-[1.4rem]">
                            <p>{selectedUser && selectedUser.username}</p>
                        </div>
                        <div className="text-[1.4rem] ">
                            <p>{selectedUser && selectedUser.usercode}</p>
                        </div>
                    </div>

                    <div className="w-full flex justify-end p-2 ">
                        <div className="flex justify-between w-1/3  gap-4">
                            <div className="bg-red-300 p-4 rounded-lg shadow-md w-full text-center">
                                <h2 className="text-xl font-bold text-white">Cash Balance</h2>
                                <p className="text-2xl font-black mt-2">
                                    {" "}
                                    GHS{" "}
                                    {Number.isNaN(parseFloat(balance))
                                        ? "0.00"
                                        : parseFloat(balance).toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                </p>
                            </div>
                            {/* Card 2 */}
                            <div className="bg-white p-4 rounded-lg shadow-md w-full text-center">
                                <h2 className="text-xl font-bold text-gray-800">Cash Limit</h2>
                                <p className="text-2xl font-black  mt-2">
                                    GHS{" "}
                                    {cashLimit.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bg-black p-3 text-white">
                        <Typography className="text-base">List of All payments</Typography>
                    </div>

                    <div className="w-full p-4 bg-white rounded-lg shadow-md">
                        <CardBody className="shadow-none  px-0 py-0  rounded-none">
                            <div className="p-4 flex gap-6 w-[100%] mb-6">
                                <div className="w-full mr-3">
                                    <Input
                                        crossOrigin="anonymous"
                                        label="Search"
                                        value={searchTextItem}
                                        onChange={handleSearch}
                                        icon={<i className="fas fa-search text-base"></i>}
                                    />
                                </div>
                                <select
                                    className="w-full h-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                                    onChange={(e) => setSelectedChannel(e.target.value)}
                                    value={selectedChannel}
                                >
                                    <option value="">Select Payment Type</option>
                                    {paymentTypes.map((type) => (
                                        <option
                                            key={type.value} // Use value as key for simplicity
                                            value={type.value}
                                        >
                                            {type.name}
                                        </option>
                                    ))}
                                </select>
                                {selectedChannel === "cash" ? (
                                    <select
                                        className="w-full h-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                                        onChange={(e) => setCashType(e.target.value)}
                                        value={cashType}
                                    >
                                        <option value="">Select options</option>
                                        {["Replenished", "Not Replenished"].map((type) => (
                                            <option
                                                key={type} // Use value as key for simplicity
                                                value={type}
                                            >
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                ) : selectedChannel === "cheque" ? (
                                    <select
                                        className="w-full h-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                                        onChange={(e) => setChequeType(e.target.value)}
                                    >
                                        <option value="">Select type</option>
                                        {["Cleared", "Not Cleared"].map((type) => (
                                            <option
                                                key={type} // Use value as key for simplicity
                                                value={type}
                                            >
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select
                                        className="w-full h-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                                        onChange={(e) => setCashType(e.target.value)}
                                        value={cashType}
                                    >
                                        <option value="">Select options</option>
                                        {["Replenished", "Not Replenished"].map((type) => (
                                            <option
                                                key={type} // Use value as key for simplicity
                                                value={type}
                                            >
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                <>
                                    <div className="bg-red-300 flex p-2 gap-x-4 rounded-lg shadow-md w-full items-center justify-center">
                                        <h2 className="text-xl font-bold text-white">Total:</h2>
                                        <p className="text-2xl font-black">
                                            {" "}
                                            GHS{" "}
                                            {Number.isNaN(parseFloat(totalAmount.toString()))
                                                ? "0.00"
                                                : parseFloat(totalAmount.toString()).toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                        </p>
                                    </div>
                                </>
                            </div>
                            {selectedChannel === "cash" ? (
                                <div
                                    className="overflow-x-auto overflow-y-auto"
                                    style={{ maxHeight: "65vh" }}
                                >
                                    <table className=" bg-white w-full min-w-max table-auto ">
                                        <thead className="bg-white sticky z-10 top-0">
                                            <tr>
                                                <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                                                    <input
                                                        type="checkbox"
                                                        disabled={cashType === "Replenished" ? true : false}
                                                        onChange={(e) => {
                                                            handleSelectAll(e)
                                                            handleAmountSelectAll(e)
                                                        }}
                                                        checked={
                                                            selectedRows.length === paginatedData?.length
                                                        }
                                                    />
                                                </th>
                                                {[
                                                    "No",
                                                    "Policy Number",
                                                    "Amount",
                                                    "Payment type",
                                                    "Status",
                                                    "Date created",
                                                    "Date Replenished",
                                                    "Replenished by",
                                                ].map((head) => (
                                                    <th
                                                        key={head}
                                                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                                                    >
                                                        <Typography
                                                            color="black"
                                                            className="font-bold text-sm text-black leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(paginatedData as cashpaymentType[])?.map(
                                                (item, index) => {
                                                    const isLast = index === paginatedData.length - 1
                                                    const className = isLast
                                                        ? "px-2 py-3 text-center"
                                                        : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"
                                                    let status: string
                                                    let statusColor: string

                                                    // Type narrowing based on selectedChannel

                                                    // Type narrowing based on selectedChannel
                                                    if (selectedChannel === "cash") {
                                                        const cashItem = item as cashpaymentType // Type assertion for cash payment
                                                        status = cashItem.is_replenished
                                                            ? "Replenished"
                                                            : "Not Replenished"
                                                        statusColor = cashItem.is_replenished
                                                            ? "text-green-600"
                                                            : "text-red-600"
                                                    } else {
                                                        return null
                                                        // const chequeItem = item as checkpaymentType // Type assertion for cheque payment
                                                        // status = chequeItem.is_cleared
                                                        //   ? "Cleared"
                                                        //   : "Not Cleared"
                                                        // statusColor = chequeItem.is_cleared
                                                        //   ? "text-green-600"
                                                        //   : "text-red-600"
                                                    }


                                                    return (
                                                        <tr
                                                            key={index.toString()}
                                                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                                                        >
                                                            <td className={className}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={
                                                                        cashType === "Replenished" ? true : false
                                                                    }
                                                                    onChange={(event) => {
                                                                        handleRowCheckboxChange(event, {
                                                                            id: item.id,
                                                                            policy_id: item.policy_id,
                                                                        })
                                                                        handleRowCheckboxAmountChange(event, {
                                                                            id: item.id,
                                                                            amount: item.amount,
                                                                            name: item.name,
                                                                        })
                                                                    }}
                                                                    checked={selectedRows.some(
                                                                        (row) => row.id === item.id
                                                                    )}
                                                                />
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {index + 1}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item.policy_number}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    GHS{" "}
                                                                    {item?.amount.toLocaleString("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item.payment_type}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize"
                                                                >
                                                                    <span className={`font-bold ${statusColor}`}>
                                                                        {status}
                                                                    </span>
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {moment(item?.created_at).format("LLL")}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item?.is_replenished
                                                                        ? moment(item?.date_replenished).format(
                                                                            "LLL"
                                                                        )
                                                                        : "N/A"}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item?.replenished_by_name
                                                                        ? item?.replenished_by_name
                                                                        : "N/A"}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : selectedChannel === "cheque" ? (
                                <div
                                    className="overflow-x-auto overflow-y-auto"
                                    style={{ maxHeight: "65vh" }}
                                >
                                    <table className=" bg-white w-full min-w-max table-auto ">
                                        <thead className="bg-white sticky z-50 top-0">
                                            <tr>
                                                <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                                                    <input
                                                        type="checkbox"
                                                        disabled={chequeType === "Cleared" ? true : false}
                                                        onChange={(e) => {
                                                            handleSelectAll(e)
                                                            handleAmountSelectAll(e)
                                                        }}
                                                        checked={
                                                            selectedRows.length === paginatedData?.length
                                                        }
                                                    />
                                                </th>
                                                {[
                                                    "No",
                                                    "Policy Number",
                                                    "Amount",
                                                    "Payment type",
                                                    "Status",
                                                    "Date created",
                                                    "Date Replenished",
                                                    "Replenished By",
                                                ].map((head) => (
                                                    <th
                                                        key={head}
                                                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                                                    >
                                                        <Typography
                                                            color="black"
                                                            className="font-bold text-sm text-black leading-none opacity-70"
                                                        >
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(paginatedData as checkpaymentType[])?.map(
                                                (item, index) => {
                                                    const isLast = index === paginatedData.length - 1
                                                    const className = isLast
                                                        ? "px-2 py-3 text-center"
                                                        : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"
                                                    let status: string
                                                    let statusColor: string

                                                    // Type narrowing based on selectedChannel

                                                    // Type narrowing based on selectedChannel
                                                    if (selectedChannel === "cheque") {
                                                        const cashItem = item as checkpaymentType // Type assertion for cash payment
                                                        status = cashItem.is_cleared
                                                            ? "Cleared"
                                                            : "Not cleared"
                                                        statusColor = cashItem.is_cleared
                                                            ? "text-green-600"
                                                            : "text-red-600"
                                                    } else {
                                                        return null
                                                    }

                                                    return (
                                                        <tr
                                                            key={index.toString()}
                                                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                                                        >
                                                            <td className={className}>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={chequeType === "Cleared" ? true : false}
                                                                    onChange={(event) => {
                                                                        handleRowCheckboxChange(event, {
                                                                            id: item.id,
                                                                            policy_id: item.policy_id,
                                                                        })
                                                                        handleRowCheckboxAmountChange(event, {
                                                                            id: item.id,
                                                                            amount: item.amount,
                                                                        })
                                                                    }}
                                                                    checked={selectedRows.some(
                                                                        (row) => row.id === item.id
                                                                    )}
                                                                />
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {index + 1}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item.policy_number}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    GHS{" "}
                                                                    {item?.amount.toLocaleString("en-US", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item.payment_type}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize"
                                                                >
                                                                    <span className={`font-bold ${statusColor}`}>
                                                                        {status}
                                                                    </span>
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {moment(item?.created_at).format("LLL")}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    {item?.is_cleared
                                                                        ? moment(item?.date_replenished).format(
                                                                            "LLL"
                                                                        )
                                                                        : "N/A"}
                                                                </Typography>
                                                            </td>
                                                            <td className={className}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal text-xs capitalize "
                                                                >
                                                                    N/A
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : selectedChannel === "driver collections" ? (
                                <table className=" bg-white w-full min-w-max table-auto ">
                                    <thead className="">
                                        <tr>
                                            <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                                                <input
                                                    type="checkbox"
                                                    disabled={cashType === "Replenished" ? true : false}
                                                    onChange={(e) => {
                                                        handleSelectAll(e)
                                                        handleAmountSelectAll(e)
                                                        
                                                    }}
                                                    checked={
                                                        selectedRows.length === paginatedData?.length
                                                    }
                                                />
                                            </th>
                                            {[
                                                "No",
                                                "driver id",
                                                "Amount",
                                                "collection type",
                                                "Status",
                                                "Date created",
                                                "Date Replenished",
                                                "Replenished By",
                                            ].map((head) => (
                                                <th
                                                    key={head}
                                                    className=" border-y border-blue-gray-100 capitalize text-black bg-gray-50/50 p-4"
                                                >
                                                    <Typography
                                                        color="black"
                                                        className="font-bold text-sm text-black leading-none opacity-70"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(paginatedData as drivercollectionType[])?.map(
                                            (item, index) => {
                                                const isLast = index === paginatedData.length - 1
                                                const className = isLast
                                                    ? "px-2 py-3 text-center"
                                                    : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"
                                                let status: string
                                                let statusColor: string

                                                // Type narrowing based on selectedChannel

                                                // Type narrowing based on selectedChannel
                                                if (selectedChannel === "driver collections") {
                                                    const cashItem = item as drivercollectionType // Type assertion for cash payment
                                                    status = cashItem.is_replenished
                                                        ? "Replenished"
                                                        : "Not Replenished"
                                                    statusColor = cashItem.is_replenished
                                                        ? "text-green-600"
                                                        : "text-red-600"
                                                } else {
                                                    return null
                                                    // const chequeItem = item as checkpaymentType // Type assertion for cheque payment
                                                    // status = chequeItem.is_cleared
                                                    //   ? "Cleared"
                                                    //   : "Not Cleared"
                                                    // statusColor = chequeItem.is_cleared
                                                    //   ? "text-green-600"
                                                    //   : "text-red-600"
                                                }
                                                return (
                                                    <tr
                                                        key={index.toString()}
                                                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                                                    >
                                                        <td className={className}>
                                                            <input
                                                                type="checkbox"
                                                                disabled={
                                                                    cashType === "Replenished" ? true : false
                                                                }
                                                                onChange={(event) => {
                                                                    handleRowCheckboxChange(event, {
                                                                        id: item.id,
                                                                        policy_id: item.policy_id,
                                                                    })
                                                                    handleRowCheckboxAmountChange(event, {
                                                                        id: item.id,
                                                                        amount: item.amount,
                                                                    })
                                                                }}
                                                                checked={selectedRows.some(
                                                                    (row) => row.id === item.id
                                                                )}
                                                            />
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {index + 1}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {item?.driver?.driver_code}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                GHS{" "}
                                                                {item?.amount.toLocaleString("en-US", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {item.payment_mode}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize"
                                                            >
                                                                <span className={`font-bold ${statusColor}`}>
                                                                    {status}
                                                                </span>
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {moment(item?.created_at).format("LLL")}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {item?.is_replenished
                                                                    ? moment(item?.date_replenished).format("LLL")
                                                                    : "N/A"}
                                                            </Typography>
                                                        </td>
                                                        <td className={className}>
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-xs capitalize "
                                                            >
                                                                {item?.replenished_by_name
                                                                    ? item?.replenished_by_name
                                                                    : "N/A"}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : null}
                        </CardBody>
                        {(loadingBroker || loadingAgent || loading) && (
                            <div className="mt-10 flex items-center justify-center">
                                <Spinner className="w-10 h-10" />
                            </div>
                        )}
                    </div>
                </div>

                {paginatedData?.length! === 0 &&
                    (!loadingBroker || !loadingAgent || !loading) && (
                        <div className="mt-10 flex items-center justify-center">
                            <Typography color="white" variant="h6">
                                No data found
                            </Typography>
                        </div>
                    )}

                {/* {paginatedData?.length !== 0 &&
                    (!loadingBroker || !loadingAgent || !loading) && (
                        <GeneralPagination
                            totalItems={totalItems!}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    )} */}

{paginatedData && paginatedData?.length !== 0 && !loading && (
          <PerPageAndPagination
            total={totalItems!}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={itemsPerPage}
            setPerPage={setItemsPerPage}
          />
        )}

                <div className="flex justify-end space-x-4 mt-4 mb-3">
                    <button
                        // onClick={() => clearPayment("cash")}
                        onClick={() => checkPaymentSelected()}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                    >
                        {loading ? "loading..." : "Replenish"}
                    </button>
                    <button
                        onClick={() => clearPayment("cheque")}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                    >
                        {loading ? "loading..." : "Clear"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ReplenishmentWithStateView