import React, { useEffect, useState } from "react";
import { CreateAdminType } from "../../../../types/admin";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import { BiArrowBack } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { GET_ROLES } from "../../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../../util";
import { toast } from "react-toastify";
import { UserType } from "../../../../types/user";

export type RoleType = {
  name: string;
  id: string;
};

const CreateAdmin = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("info");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateAdminType>();

  const { data, refetch } = useQuery<{ roles: RoleType[] }>(GET_ROLES);

  const accountTyes = [
    {
      label: "Personal Information",
      value: "info",
    },
  ];

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const navigateBack = () => {
    history.push("/dashboard/users");
    //();
  };

  const onSubmit: SubmitHandler<CreateAdminType> = async (data) => {
    // try {
    //   const response = await fetch(`${BASEURL}/auth/create-admin`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //     body: JSON.stringify(data),
    //   });
    //   const req = await response.json();
    //   console.log(response.ok);
    //   console.log(req);
    //   if (response.ok) {
    //     toast.success("Admin created successfully");
    //     reset();
    //     refetch();
    //   }
    // } catch (error: any) {
    //   toast.error(error?.message ?? "Error creating adding admin");
    // }
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Create Intermediaries" />

      <Tooltip content="Intermediaries">
        <IconButton color="white" onClick={navigateBack}>
          <BiArrowBack size={20} />
        </IconButton>
      </Tooltip>

      <div className="my-5 bg-white pb-5 rounded-xl">
        <Tabs id="custom-animation" value={activeTab}>
          <TabsHeader
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
          >
            {accountTyes?.map(({ value, label }) => (
              <Tab
                className={`font-semibold text-sm capitalize`}
                key={value}
                value={value}
                onClick={(e) => handleTabChange(value)}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <div className="py-5">
            <TabsBody
              defaultValue={activeTab}
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
            >
              <TabPanel key={activeTab} value={activeTab}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
                    <div className="w-full">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-normal text-base"
                      >
                        First name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.first_name}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("first_name", { required: true })}
                      />
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-normal text-base"
                      >
                        Last name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        error={!!errors.last_name}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("last_name", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
                    <div className="w-full">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-normal text-base"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        type="email"
                        error={!!errors.email}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="w-full">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-normal text-base"
                      >
                        Phone Number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        //
                        error={!!errors.phone_number}
                        size="lg"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone_number", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
                    <div className="w-full md:w-1/3 lg:w-1/3">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-normal text-base"
                      >
                        Admin role
                      </Typography>

                      <select
                        id="roles"
                        className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        {...register("role_id", { required: true })}
                      >
                        {data?.roles?.map((item, index: number) => (
                          <option
                            key={index}
                            label={item?.name}
                            value={item?.id}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      {errors?.role_id && (
                        <p className="tex-xs mt-1 text-brand-primary">
                          admin role is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-end justify-end">
                    <Button
                      type="submit"
                      style={{
                        background:
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`,
                      }}
                      className=" mx-5"
                    >
                      {isSubmitting ? (
                        <Spinner className="w-5 h-5" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </form>
              </TabPanel>
            </TabsBody>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default CreateAdmin;
