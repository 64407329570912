import React from "react"
import PaginateComponent from "./paginate-component"

const PerPageAndPagination = ({
  currentPage,
  setCurrentPage,
  setPerPage,
  perPage,
  total,
}: {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setPerPage: React.Dispatch<React.SetStateAction<number>>
  perPage: number
  total: number
}) => {
  return (
    <div className=" w-full flex flex-col gap-y-2 sm:flex-row sm:items-center sm:justify-between px-10">
      <div className=" flex items-center gap-x-2 w-2/3">
        <p className="text-white text-[16px]">Row per page</p>
        <select
          name=""
          onChange={(e) => setPerPage(parseInt(e.target.value))}
          id=""
          value={perPage}
          className=" outline-none border-gray-800 w-[4rem] h-[2rem]"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <PaginateComponent
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalPages={total! ?? 0}
        perPage={perPage}
      />
    </div>
  )
}

export default PerPageAndPagination
