import React, { useEffect, useState } from "react"
import { CustomDeleteModal } from "../../../components"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Typography,
  Spinner,
  Tooltip,
  IconButton,
  Textarea,
  DialogFooter,
} from "@material-tailwind/react"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { UserType } from "../../../types/user"
import {
  GETALLSAVEDQUOTATIONS,
  GETALLINTERMEDIARIESAGENT,
  QUICKSEARCH,
} from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { CustomModal } from "../../../components"
import { BASEURL } from "../../../util"
import { ToastContainer } from "react-toastify"
import { toast } from "react-toastify"
import { useLazyQuery } from "@apollo/client"
import IntermediaryName from "./IntermediaryName"
import GeneralPagination from "../../../components/paginations/generalpagination"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

interface AmendedRatedDetails {
  fleet_discount: number
  other_discount: number
  own_damage_rate: number
  no_claim_discount: number
}

interface BusinessClass {
  name: string
  __typename: "business_classes"
}

interface Proposer {
  first_name: string
  last_name: string
  phone_number: string
  __typename: "customers"
}

interface CompanyStaff {
  id: string
  email: string
  name: string
  phone_number: string
  __typename: "company_staff"
}

interface VehicleDetails {
  color: string
  body_type: string
  vehicle_model: string
  vehicle_value: string
  chassis_number: string
  make_of_vehicle: string
  number_of_seats: number
  accessories_value: string
  registration_number: string
  year_of_manufacture: string
}

interface SubClass {
  name: string
  id: string
  __typename: "risk_class"
}

interface Request {
  amended_rated_details: AmendedRatedDetails
  benefit_added_details: {
    extra_pa: number
    extra_tppdl: number
    loss_of_use: number
    excess_buy_back: boolean
    umbrella_tppdl_limit: number
  }
  payment_channel: string
  amount: string
  request_id: string
  businessClassByBusinessClass: BusinessClass
  car_number_type: string
  cover_type: string
  proposer: Proposer
  registration_number_text: string
  duration: string
  end_date: string
  start_date: string
  sub_agent: null | any
  company_staff: CompanyStaff
  vehicle_details: VehicleDetails
  vehicle_details_name: string
  user_type: string
  sub_class: SubClass
  __typename: "request"
}

//--------------------------

type SubAagent = {
  id: string
  first_name: string
  last_name: string
  phone_number: string
}

type RiskClass = {
  id: string
  name: string
}

type VehicleDetailsType = {
  color: string
  body_type: string // Assuming this is an identifier for the body type
  vehicle_model: string // Assuming this is an identifier for the vehicle model
  vehicle_value: number
  chassis_number: string
  make_of_vehicle: string // Assuming this is an identifier for the make of the vehicle
  number_of_seats: number
  accessories_value: number
  registration_number: string
  year_of_manufacture: string
}

type SavedQuotationType = {
  request: {
    amended_rated_details: string

    amount: string

    businessClassByBusinessClass: BusinessClass
    cover_type: string
    created_at: string
    sub_agent: SubAagent
    currency: string
    company_staff: CompanyStaff
    duration: number
    end_date: string
    id: string
    is_paid: boolean
    payment_channel: string
    request_stage: string
    registration_number_text: string
    start_date: string
    user_type: string
    vehicle_details: VehicleDetailsType
    risk_class: RiskClass
  }
}

type IntermediaryType = {
  id: string
  name: string
}

const QuotationView = () => {
  const [searchText, setSearchText] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const handleOpen = () => setOpen(!open)
  const PAGE_SIZE = 10
  const [showUpdateModal, setUpdateModal] = useState(false)
  const [updating, setIsUpdating] = useState(false)
  const [selectedagentId, setSelectedAgentId] = useState("")
  const [reason, setReason] = useState("")
  const [submitloading, setSubmitLoading] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  const { data, loading, refetch } = useQuery<{
    request: Request[]
  }>(GETALLSAVEDQUOTATIONS, {
    variables: {
      id: companyId,
    },
  })

  // console.log(data)
  const {
    data: intermediaries,
    loading: loadingintermediaies,
    refetch: refetchintermediaries,
  } = useQuery<{
    intemediary: IntermediaryType[]
  }>(GETALLINTERMEDIARIESAGENT, {
    variables: {
      company_id: companyId,
    },
  })

  // console.log(s.split(",")[0].split(" ")[1])

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
if(searchText || startDate || endDate){
  const type = totalItems?.map((item: Request) => ({
    "Quotation Number": item?.request_id,
    "Sub Class": item?.sub_class?.name,
    Proposer: `${item?.proposer?.first_name} ${item?.proposer?.last_name}`,
    "Phone Number": item?.proposer?.phone_number,
    "Vehicle Reg. No": item?.vehicle_details?.registration_number,
    "Agent or broker": item?.company_staff?.name,
    "Type of Cover": item?.cover_type,
    "Vehicle and Accessories": item?.vehicle_details_name,
    TPPD: "N/A",
    "P.A Benefits": "N/A",
    "Own Damage Excess": "N/A",
    "No Claim Discount": "N/A",
    Duration: item?.duration,
    "Start Date": moment(item?.start_date).format("LL"),
    "end date": moment(item?.end_date).format("LL"),
  }))
  const firstItem = type![0]
  const headers = Object.keys(firstItem!)
  // Transform data to match headers (Simplified)
  const transformedData = type?.map((item: any) => {
    const transformedItem: Record<string, any> = {}
    headers.forEach((header) => {
      transformedItem[header] = item[header] // No need for JSON.stringify
    })
    return transformedItem
  })
  // Convert data to CSV format
  const csvData = Papa.unparse({
    fields: headers,
    data: transformedData!,
  })
  // Create a Blob and download link
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = window.URL.createObjectURL(blob)
  // Create a download link
  const a = document.createElement("a")
  a.href = url
  a.download = `${"saved-quotations"}.csv`
  document.body.appendChild(a)
  // Trigger a click on the link to start the download
  a.click()
  // Cleanup
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
  return
}
    const type = data?.request?.map((item: Request) => ({
      "Quotation Number": item?.request_id,
      "Sub Class": item?.sub_class?.name,
      Proposer: `${item?.proposer?.first_name} ${item?.proposer?.last_name}`,
      "Phone Number": item?.proposer?.phone_number,
      "Vehicle Reg. No": item?.vehicle_details?.registration_number,
      "Agent or broker": item?.company_staff?.name,
      "Type of Cover": item?.cover_type,
      "Vehicle and Accessories": item?.vehicle_details_name,
      TPPD: "N/A",
      "P.A Benefits": "N/A",
      "Own Damage Excess": "N/A",
      "No Claim Discount": "N/A",
      Duration: item?.duration,
      "Start Date": moment(item?.start_date).format("LL"),
      "end date": moment(item?.end_date).format("LL"),
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"saved-quotations"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  const filterData = (filter: Request) => {
    const date1 = moment(filter?.start_date).format("LL")
    const date2 = moment(filter?.end_date).format("LL")
    const searchTextMatch =
      filter?.proposer?.first_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter?.proposer?.last_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter?.request_id.toLowerCase().includes(searchText.toLowerCase()) ||
      filter?.proposer?.phone_number
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter?.vehicle_details?.registration_number
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter?.company_staff?.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter?.cover_type.toLowerCase().includes(searchText.toLowerCase()) ||
      filter?.sub_class?.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      date1?.toLowerCase().includes(searchText.toLowerCase()) ||
      date2?.toLowerCase().includes(searchText.toLowerCase()) ||
      filter?.duration.toLowerCase().includes(searchText.toLowerCase())

    return searchTextMatch
  }

  //------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  function filterPoliciesByDateRange(
    policies: Request[],
    fromDate: any,
    toDate: any
  ) {
    // Convert fromDate and toDate to Date objects if they're strings
    const from = fromDate instanceof Date ? fromDate : new Date(fromDate)
    const to = toDate instanceof Date ? toDate : new Date(toDate)

    return policies?.filter((policy: Request) => {
      if (!fromDate || !toDate) {
        return true // No filter applied
      }
      // console.log(policy?.start_date)
      // console.log(policy?.end_date)
      // console.log(fromDate, toDate)
      const startDate = new Date(policy?.start_date)
      const endDate = new Date(policy?.end_date)

      // Check if the policy overlaps with the given date range
      return (
        (fromDate === null || startDate <= from) &&
        (toDate === null || endDate <= to)
      )
    })
  }

  const filteredPolicies = data?.request?.filter(filterData)

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalItems = filterPoliciesByDateRange(
    filteredPolicies!,
    startDate,
    endDate
  )
  // const paginatedData = totalItems?.slice(startIndex, endIndex)
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)



  const changeOwner = async (e: any) => {
    e.preventDefault()

    setSubmitLoading(true)

    try {
      const payload = JSON.stringify({
        quotation_id: selectedRow?.id,
        new_owner_id: selectedagentId,
        reason: reason,
        usertype: usertype,
        company_id: companyId,
      })

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/change-quotation-owner`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add authorization token if needed
          },
          body: payload,
        }
      )

      if (res.ok) {
        toast.success("Changed quotation owner successfully.")
        refetch()
      } else {
        const errorResponse = await res.json()
        toast.error(errorResponse.message ?? "Failed to change owner.")
      }
    } catch (error: any) {
      toast.error(error.message ?? "Something went wrong. Try again!")
    } finally {
      setSubmitLoading(false)
    }
  }



  return (
    <>
      <CustomModal
        onClose={() => setUpdateModal(false)}
        open={showUpdateModal}
        size={"md"}
        title="Change agent on quotation"
        className=""
        children={
          <>
            <ToastContainer />
            <div>
              <form onSubmit={(e) => changeOwner(e)}>
                <div className="scrolls w-full">
                  <div className="mt-5 flex flex-col">
                    <div className="w-full">
                      <div className="mb-6 grid grid-cols-1 md:grid-cols-1  gap-6">
                        <div className="w-full flex-col  md:flex-col lg:flex-col my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-full"
                          >
                            New Agent ID
                          </Typography>
                          <select
                            onChange={(e) => setSelectedAgentId(e.target.value)}
                            className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            {intermediaries?.intemediary.map((item) => (
                              <option
                                key={item?.name}
                                value={item?.id}
                                label={item?.name}
                              >
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="w-full flex-col  md:flex-col lg:flex-col my-1 gap-1">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-full"
                          >
                            Reason
                          </Typography>
                          <Textarea
                            onChange={(e) => setReason(e.target.value)}
                            size="md"
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            placeholder="Enter your text here"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => setUpdateModal(false)}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center"
                    variant="gradient"
                    color="green"
                  >
                    {submitloading ? (
                      <Spinner className="h-4 w-4 text-white" />
                    ) : (
                      <span>Save</span>
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </>
        }
      />
      <CustomHeader title="Saved Quotations" />

      <div className="mt-10 w-full ">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4 font-bold  leading-none opacity-70 capitalize"
            >
              Total Quotations{" "}
              {data?.request && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.request?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col gap-y-4  bg-white p-3">
            <div className="flex shrink-0 flex-col justify-end gap-2 flex-wrap lg:flex-row pl-3">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
            <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-3 ">
              <div className="w-full">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>

              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
        
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="bg-white w-full min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "Quotation Number",
                      "Sub Class",
                      "Proposer",
                      "Vehicle Reg. No",
                      "Agent or broker",
                      "Type of Cover",
                      "channel",
                      "Vehicle and Accessories",
                      "TPPD",
                      "P.A Benefits",
                      "Own Damage Excess",
                      "No Claim Discount",
                      "Duration",
                      "Start Date",
                      "end date",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item: Request, index: number) => {
                    const isLast = index === paginatedData.length - 1
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedRow(item)
                          // setModalOpen(true);
                        }}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.request_id}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.sub_class?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Name:{" "}
                              <span className="font-normal">
                                {item?.proposer
                                  ? `${item?.proposer?.first_name} ${item?.proposer?.last_name}`
                                  : ""}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone No.:{" "}
                              <span className="font-normal">
                                {item?.proposer?.phone_number}
                              </span>
                            </Typography>
                          </div>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.vehicle_details?.registration_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? `${item?.company_staff?.name}`
                                  : `${item?.sub_agent?.name} `}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Email:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? `${item?.company_staff?.email}`
                                  : `${item?.sub_agent?.email} `}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? item?.company_staff?.phone_number
                                  : item?.sub_agent?.phone_number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.cover_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.payment_channel}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Vehicle Make:{" "}
                              <span className="font-normal">
                                {
                                  item?.vehicle_details_name
                                    ?.split(",")[1]
                                    .split(" ")[2]
                                }
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Vehicle Model:{" "}
                              <span className="font-normal">
                                {
                                  item?.vehicle_details_name
                                    ?.split(",")[0]
                                    .split(" ")[1]
                                }
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Yr. of Manufacture:{" "}
                              <span className="font-normal">
                                {item?.vehicle_details?.year_of_manufacture}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize"
                            >
                              Vehicle & Acces..:{" "}
                              <span className="font-normal">
                                GHS{" "}
                                {parseFloat(
                                  (
                                    ((item?.vehicle_details
                                      ?.vehicle_value as any) ?? 0) +
                                    ((item?.vehicle_details
                                      ?.accessories_value as any) ?? 0)
                                  ).toString()
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </Typography>
                            {/* <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize "
                          >
                            Reg. Number:{" "}
                            <span className="font-normal">
                              {item?.vehicle_details?.registration_number}
                            </span>
                          </Typography> */}
                            {/* <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize "
                          >
                            No of seats:{" "}
                            <span className="font-normal">
                              {item?.vehicle_details?.number_of_seats}
                            </span>
                          </Typography>

                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize "
                          >
                            Chasis:{" "}
                            <span className="font-normal">
                              {item?.vehicle_details?.chassis_number
                                ? item?.vehicle_details?.chassis_number
                                : "N/A"}
                            </span>
                          </Typography> */}
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Color:{" "}
                              <span className="font-normal">
                                {item?.vehicle_details?.color}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className="classes">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize"
                          >
                            <span className="font-normal">
                              GHS{" "}
                              {parseFloat(
                                (
                                  ((item?.benefit_added_details
                                    ?.extra_tppdl as any) ?? 0) + 6000
                                ).toString()
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize"
                          >
                            <span className="font-normal">
                              GHS{" "}
                              {parseFloat(
                                (
                                  ((item?.benefit_added_details
                                    ?.extra_pa as any) ?? 0) + 7000
                                ).toString()
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.amended_rated_details.own_damage_rate} %
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.amended_rated_details.no_claim_discount} %
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.duration}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.start_date).format("LL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.end_date).format("LL")}
                          </Typography>
                        </td>
                        {/* 
                    <td className={classes}>
                      <Tooltip content="Change agent">
                        <Button
                          color="green"
                          size="sm"
                          disabled={item?.user_type === "broker"}
                          className="text-xs font-normal capitalize"
                          onClick={() => {
                            setSelectedRow(item)
                            setUpdateModal(true)
                          }}
                          variant="filled"
                        >
                          Change Agent
                        </Button>
                      </Tooltip>
                    </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedData && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && paginatedData?.length !== 0 && !loading && (
          // <GeneralPagination
          //   totalItems={totalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />
          <PerPageAndPagination
          total={totalItems?.length!}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={itemsPerPage}
          setPerPage={setItemsPerPage}
        />
        )}
      </div>
    </>
  )
}

export default QuotationView
