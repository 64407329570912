import ReactPaginate from "react-paginate"
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io"
import { FiArrowRightCircle, FiArrowLeftCircle } from "react-icons/fi"

import React, { useState } from "react"
const WhitePaginateComponent = ({
  totalPages,
  currentPage,
  setCurrentPage,
  perPage,
}: {
  totalPages: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  perPage: number
}) => {
  const [check, setCheck] = useState(1)

  const pageCount = Math.ceil(totalPages / perPage)

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % totalPages
    // console.log(
    //   `User requested page number ${
    //     event.selected + 1
    //   }, which is offset ${newOffset}`
    // )
    setCheck(event.selected + 1)
    setCurrentPage(newOffset)
  }
  // console.log(currentPage, pageCount)
  // console.log(itemOffset)

  // const handlePageClick = async ({ selected }: { selected: number }) => {

  //   setCurrentPage(selected + 1)

  // }
  return (
    <div>
      <ReactPaginate
        breakLabel={<span className=" mr-3 text-black">...</span>}
        nextLabel={
          <span>
            <FiArrowRightCircle
            
              // size={20}
              className={` text-[35px] ${
                check === pageCount ? "text-gray-500" : "text-black"
              }  `}
            />
          </span>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={4}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        previousLabel={
          <span>
            <FiArrowLeftCircle
              className={` text-[35px] ${
                currentPage === 0 ? "text-gray-500" : "text-black"
              }  `}
            />
          </span>
        }
        renderOnZeroPageCount={null}
        containerClassName=" flex items-center justify-center mt-4 mb-4"
        pageClassName=" block text-[25px]  w-8 h-8 flex justify-center items-center rounded-md hover:bg-gray-300 mr-1 font-varela text-[0.75rem] text-black"
        activeClassName="bg-gray-400 text-black"
        activeLinkClassName="text-black flex items-center justify-center w-full h-full"
        nextClassName="ml-2"
        previousClassName=" mr-2"
      />
    </div>
  )
}

export default WhitePaginateComponent
