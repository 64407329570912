import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CoverTypes } from "../../../types/query";
import { GETBUSINESSCLASSES, GETCOVERTYPES } from "../../../GraphQl/queries";
import { BusinessClassType } from "../../../types/class";
import { toast } from "react-toastify";
import { ADDCOVERTYPE, DELETECOVERTYPE } from "../../../GraphQl/mutations";
import { DEFAULTPRIMARYCOLOR } from "../../../util";
import { UserType } from "../../../types/user";

type FormType = {
  class: string;
  cover: string;
  description: string;
};

const CoverTypeView = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[];
  }>(GETBUSINESSCLASSES);

  const { data, loading, refetch } = useQuery<{
    covertypes: CoverTypes[];
  }>(GETCOVERTYPES);

  const [CreateCoverType, { loading: RequestLoading }] = useMutation(
    ADDCOVERTYPE,
    {
      onCompleted: (data) => {
        toast.success("Cover type Successfully added");
        reset();
        refetch();
        refreshClass();
      },
      onError: (error) =>
        toast.error(error?.message ?? "Error creating cover type"),
    }
  );

  const [DeleteCoverType] = useMutation(DELETECOVERTYPE, {
    onCompleted: (data) => {
      toast.success("Cover type deleted successfully");
      refetch();
      refreshClass();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting cover type"),
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: CoverTypes) => {
    const isTextMatch =
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = data?.covertypes
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.covertypes?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = (data) => {
    try {
      CreateCoverType({
        variables: {
          class_id: data?.class,
          description: data?.description,
          name: data?.cover,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating exclusion");
    }
  };

  const deleteCoverType = (id: string) => {
    try {
      DeleteCoverType({
        variables: {
          id,
        },
      });
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting cover type");
    }
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Types of covers" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="p-3"
          >
            <Typography className="text-xl text-white">
              Create Type of Cover
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Type of cover
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("cover", { required: true })}
                    />
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Select Class
                    </Typography>
                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                    >
                      <option>
                        {loadingClasses ? "Loading..." : "Select class"}
                      </option>
                      {classes?.business_classes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="capitalize flex flex-row items-center font-normal"
              >
                <BiPlus size={20} />
                {isSubmitting || RequestLoading
                  ? "Creating..."
                  : "Create cover type"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">List of Cover Types</Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["no", "Class", "Type of Cover", "", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    let _class = classes?.business_classes?.find(
                      (data) => data?.id === item?.class_id
                    );

                    const className = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {_class?.name}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>

                        <td className={className}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {" "}
                          </Typography>
                        </td>
                        <td className={className}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "covertype",
                                  JSON.stringify({
                                    className: _class?.name,
                                    id: item?.id,
                                    name: item?.name,
                                    description: item?.description,
                                  })
                                );
                                history.push(
                                  "/dashboard/setup/manage-peril-exclusion"
                                );
                                
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage perils and exclusions
                            </Button>
                            <IconButton
                              onClick={() => deleteCoverType(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (data?.covertypes?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (data?.covertypes?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default CoverTypeView;
