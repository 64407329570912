import React from "react";
import { ActivationType } from "../../types/campaign";
import { Spinner, Typography } from "@material-tailwind/react";
import moment from "moment";
import { IntermediariesAgentType } from "../../types/query";
import { ResponseType } from "../../pages/dashboard/agent";
import { GETTAXPAYABLE } from "../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { CommissionType } from "../../pages/dashboard/financial-details/commissions";

const CommissionDetail = ({
  content,
}: {
  content: CommissionType | undefined;
}) => {
  const { data, loading } = useQuery<{
    tax_payable: {
      amount: number;
      id: string;
      rate: string;
      tax: {
        id: string;
        name: string;
      };
    }[];
  }>(GETTAXPAYABLE, {
    variables: {
      id: content?.payable_commission_id,
    },
  });

  return (
    <div className="overflow-scroll scrolls h-full">
      {/* <div className="border border-gray-200 rounded-md py-2 px-4 mb-3">
        <Typography className="text-sm text-brand-gray" color="blue-gray">
          ID
        </Typography>
      </div> */}
      {/* <Typography
        className="text-sm font-semibold  mt-3 text-brand-blue bg-blue-100 p-2 rounded-lg"
        color="blue-gray"
      >
        {content?.id}
      </Typography>*/}

      <div className="flex flex-col justify-between mb-2 gap-3 mt-4">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Business Class
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.business_class_name}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Sub class
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.risk_or_sub_class_name}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Payee
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {`${
              content?.agent_first_name !== null
                ? content?.agent_first_name
                : ""
            } ${
              content?.agent_last_name !== null ? content?.agent_last_name : ""
            }`}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Commision Rate
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            {content?.rate}%
          </Typography>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Gross Commission amount
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(content?.gross_amount ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Total Tax deducted
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(
              parseFloat(content?.total_tax_deductions as any) ?? 0
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Net Commission amount
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            GH₵{" "}
            {(parseFloat(content?.net_amount as any) ?? 0).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col justify-between mb-2 gap-3">
        <div>
          <Typography className="text-sm text-brand-gray " color="blue-gray">
            Bank details
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Name: {content?.account_details?.bank_name}
          </Typography>
          <Typography
            className="text-sm font-semibold  capitalize text-black"
            color="blue-gray"
          >
            Branch: {content?.account_details?.branch}
          </Typography>
        </div>
      </div>

      <Typography
        variant="h6"
        color="black"
        className="pt-10 pb-3  font-bold text-black leading-none capitalize"
      >
        tax payable
      </Typography>
      <table className="w-full bg-white min-w-max table-auto">
        <thead className="">
          <tr>
            {["Name", "rate", "Amount"].map((head) => (
              <th
                key={head}
                className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
              >
                <Typography
                  color="black"
                  className="font-normal text-sm text-black leading-none"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.tax_payable?.map((item, index: number) => {
            const isLast = index === data?.tax_payable?.length - 1;

            const className = isLast
              ? "px-2 py-3  text-center"
              : "px-2 py-3 border-b-2 border-blue-gray-50 text-center";

            return (
              <tr
                key={index.toString()}
                // onClick={() => handleRowClick(item)}
                className="hover:bg-gray-100 transition-all hover:cursor-pointer"
              >
                <td className={className}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.tax?.name}
                  </Typography>
                </td>
                <td className={className}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    {item?.rate}%
                  </Typography>
                </td>
                <td className={className}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize"
                  >
                    GH₵{" "}
                    {(item?.amount ?? 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {loading && (
        <div className="mt-10 flex items-center justify-center">
          <Spinner className="w-1o h-10" />
        </div>
      )}

      {data?.tax_payable?.length!! < 1 && !loading && (
        <div className="mt-10 flex items-center justify-center">
          <Typography color="white" variant="h6">
            No data found
          </Typography>
        </div>
      )}

      <div className="h-20" />
    </div>
  );
};

export default CommissionDetail;
